import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import { useQuery } from '../../../../__data__/hooks'
import { getSettingsBuildingsPagination } from '../../actions/staff-actions'
import { makeCheckedBuildings } from '../../../../__data__/selectors/common'
import { makeBuildingsObject } from '../../selectors/buildings-selectors'

import ObjectCard from './components/object-table/object-card/object-card'
import ObjectTable from './components/object-table/object-table'
import style from './style.css'

function Component(props) {
    const { getSettingsBuildingsPagination, buildingsObject, availableBuildingsList } = props

    let query = useQuery()

    const history = useHistory()
    const queryId = parseInt(query.get('id'))

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        getSettingsBuildingsPagination()
    }, [])

    useEffect(() => {
        if (queryId) {
            setSelected(queryId)
        }
        else {
            setSelected(null)
        }
    }, [queryId])

    const clearQuery = () => {
        history.push({search: ''})
    }

    const handleObjectClick = (event, objectId) => {
        if (queryId !== objectId) {
            history.push({search: `?id=${objectId}`})
        } else {
            clearQuery()
        }
    }

    const getOrganization = (row) => {
        return row?.subsidiary ? row.subsidiary.label : row?.tenant?.label
    }

    const getBuildingAddress = (building) => {
        return building.street + " " +  building.number
    }
    const activeObject = _.find(buildingsObject.results, i => i.id === selected)

    return (
        <div className={style.container}>
            {!_.isEmpty(buildingsObject) && (
                <ObjectTable
                    buildingsObject={buildingsObject}
                    handleClick={handleObjectClick}
                    selected={selected}
                    getBuildingAddress={getBuildingAddress}
                    getOrganization={getOrganization}
                    availableBuildingsList={availableBuildingsList}
                />
            )}
            {activeObject && (
                <ObjectCard
                    closeCard={clearQuery}
                    id={selected}
                />
            )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    buildingsObject: makeBuildingsObject(),
})

const mapDispatchToProps = {
    getSettingsBuildingsPagination,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
