import axios from 'axios'

import * as types from '../actions-types'
import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchGetMetersList = () => ({ type: types.GET_METERS_LIST })
export const fetchGetMetersListSuccess = (data) => ({ type: types.GET_METERS_LIST_SUCCESS, data })
export const fetchGetMetersListFail = (error) => ({ type: types.GET_METERS_LIST_FAIL, error })

export const fetchGetMetersLastMonth = () => ({ type: types.GET_METERS_LAST_MONTH })
export const fetchGetMetersLastMonthSuccess = (data) => ({ type: types.GET_METERS_LAST_MONTH_SUCCESS, data })
export const fetchGetMetersLastMonthFail = (error) => ({ type: types.GET_METERS_LAST_MONTH_FAIL, error })

export const fetchSendMeters = () => ({ type: types.SEND_METERS })
export const fetchSendMetersSuccess = (data) => ({ type: types.SEND_METERS_SUCCESS, data })
export const fetchSendMetersFail = (error) => ({ type: types.SEND_METERS_FAIL, error })

export const fetchGetBuildingSettings = () => ({ type: types.GET_BUILDING_SETTINGS })
export const fetchGetBuildingSettingsSuccess = (data) => ({ type: types.GET_BUILDING_SETTINGS_SUCCESS, data })
export const fetchGetBuildingSettingsFail = (error) => ({ type: types.GET_BUILDING_SETTINGS_FAIL, error })

export const fetchGetBuildingsSettings = () => ({ type: types.GET_BUILDINGS_SETTINGS })
export const fetchGetBuildingsSettingsSuccess = (data) => ({ type: types.GET_BUILDINGS_SETTINGS_SUCCESS, data })
export const fetchGetBuildingsSettingsFail = (error) => ({ type: types.GET_BUILDINGS_SETTINGS_FAIL, error })

export const getMetersListPagination = (buildingsId, date, page=1, countPerPage=10) => dispatch => {
    dispatch(fetchGetMetersList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/meters/?buildings_id=${buildingsId}&date=${date}&page=${page}&page_size=${countPerPage}`,
    })
        .then(response => {
            dispatch(fetchGetMetersListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetMetersListFail(error))
        })
}

export const getMetersLastMonth = (unitId) => dispatch => {
    dispatch(fetchGetMetersLastMonth())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/meters/${unitId}/`,
    })
        .then(response => {
            dispatch(fetchGetMetersLastMonthSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetMetersLastMonthFail(error))
        })
}

export const sendMeters = data => dispatch => {
    dispatch(fetchSendMeters())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data,
        url: '/api/crm/meters/',
    })
        .then(response => {
            dispatch(fetchSendMetersSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchSendMetersFail(error))
        })
}

// METERS FORM

export const getBuildingSettings = id => dispatch => {
    dispatch(fetchGetBuildingSettings())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/settings/building/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetBuildingSettingsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingSettingsFail(error))
        })
}


// METERS LIST

export const getBuildingsSettings = buildings_id => dispatch => {
    dispatch(fetchGetBuildingsSettings())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/scenarios/reading-list-data/?buildings_id=${buildings_id}`,
    })
        .then(response => {
            dispatch(fetchGetBuildingsSettingsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingsSettingsFail(error))
        })
}
