import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { makeCheckedBuildings } from '../../../../__data__/selectors/common'
import { makeSettingsReadings } from '../../../../__data__/selectors'
import { getSettingsReadings } from '../../actions/actions'

import Card from './card'
import List from './list'
import style from './style.css'

function Component(props) {
    const { getSettingsReadings, checkedBuildings, settingsReadings } = props

    useEffect(() => {
        getSettingsReadings(checkedBuildings)
    }, [checkedBuildings])

    const [selected, setSelected] = useState(null)

    const handleClick = (event, flatId) => {
        if (selected !== flatId) {
            setSelected(flatId)
        } else {
            setSelected(null)
        }
    }

    const activeItem = _.find(settingsReadings, i => i.id === selected)

    return (
        <div className={style.container}>
            { settingsReadings && <List rows={settingsReadings} handleClick={handleClick} selected={selected} />}
            { activeItem && <Card { ...activeItem } handleClick={setSelected} id={selected} /> }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    settingsReadings: makeSettingsReadings(),
})

const mapDispatchToProps = {
    getSettingsReadings,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
