import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Uploader} from './uploader';
import SubmitButtonIcon from '../../assets/submit-button-icon.svg'

import style from './style.css';

export const Submit = ({tinode, selectedTopic}) => {
    const [message, setMessage] = useState('');

    const sendMessage = useCallback((message, promise, uploader) => {
        const topic = tinode.getTopic(selectedTopic);
        // The uploader is used to show progress.
        message = topic.createMessage(message, false);
        message._uploader = uploader;
        if (!topic.isSubscribed()) {
            if (!promise) {
                promise = Promise.resolve();
            }
            promise = promise.then(() => { return topic.subscribe(); });
        }

        if (promise) {
            promise = promise.catch((err) => {
                console.error(err.message, 'err');
            });
        }

        topic
            .publishDraft(message, promise)
            .then((ctrl) => {
                if (topic.isArchived()) {
                    return topic.archive(false);
                }
            })
            .catch((err) => {
                console.error(err.message, 'err');
            });
        setMessage('');
    }, [selectedTopic, tinode])

    const submit = useCallback(() => {
        sendMessage(message);
    }, [message, sendMessage])

    const onEnterSubmit = useCallback((e) => {
        if (e.key === 'Enter') {
            submit();
        }
    }, [submit])

    useEffect(() => {
        window.addEventListener('keyup', onEnterSubmit);
        return () => window.removeEventListener('keyup', onEnterSubmit);
    }, [onEnterSubmit])

    const handleMessageTyping = (event) => {
        setMessage(event.target.value);
    }

    return (
        <div className={style.container}>
            <div className={style.inner}>
                <Uploader
                    tinode={tinode}
                    sendMessage={sendMessage}
                />
                <textarea
                    id="sendMessage"
                    placeholder='Напишите сообщение'
                    value={message}
                    className={style.textarea}
                    onChange={handleMessageTyping}
                />
                {
                    message.length !== 0
                    && <button
                        type="button"
                        className={style.button}
                        onClick={submit}
                    >
                        <img src={SubmitButtonIcon} alt="submit-button-icon"/>
                    </button>
                }
            </div>
        </div>
    )
}

Submit.propTypes = {
    tinode: PropTypes.object.isRequired,
    selectedTopic: PropTypes.string.isRequired
}
