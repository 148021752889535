import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeStories = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'storiList')
        return data

    })

export const makeStoriesFull = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'storiList')

        const stories = data?.map((stori) => {
            return {
                defaultInterval: stori.duration,
                textBtn: stori.title,
                id: stori.id,
                cover: stori.cover.large.file,
                stories: stori?.frames?.map((frame, index) => {
                    let duration = frame.duration;
                    if(index === 0){
                        duration = duration + 1000
                    }
                    return {
                        duration: duration,
                        header: frame.title,
                        text: frame.description ,
                        url: frame['background_content']['large']['file'],
                        type:  frame['background_content']['type'] === 'video' ? 'video' : 'image',
                    }
                })
            }


        })

        return stories

    })

export const makeCurrentStoriIndex = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'currentStori')
        const allData = _.get(slice, 'storiList')
        const selectedItem = allData?.map(function(e) { return e?.id; })?.indexOf(data?.id);

        return selectedItem
    })
