import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeNotificationList = () =>
    createSelector(app, slice => _.get(slice, 'notifications'))

export const makeNotificationGrowIndex = () =>
    createSelector(app, slice => _.get(slice, 'notificationGrowIndex'))
