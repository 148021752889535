import _ from 'lodash'

import { createCamera }  from '../../__data__/actions/cameras'

function submit(values, dispatch) {
    const name = values.name
    const link = values.link
    const buildings = _.map(values.buildings, i => i.id)
    const data = {name, link, buildings}
    dispatch(createCamera(data))
}

export default submit
