import React from 'react'

import style from './style.css'

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched

    if (!isInvalid) return null

    return <div className={style.errorMessage}>{props.meta.error}</div>
}

export default Component;
