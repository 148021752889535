import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import Scroll from 'react-scroll'
import classNames from 'classnames'
import _ from 'lodash'

import style from './action.css'

function Component(props) {
    const { formName, dispatch, syncFieldErrors, fieldErrors, isValid, buttonText, isFormChanged } = props

    const scrollTo = () => Scroll.scroller.scrollTo('fieldErrorScrollTarget', {
        duration: 500,
        delay: 50,
        smooth: true,
        offset: -200,
    })

    const handleClick = () => {
        dispatch(submit(formName))
        setTimeout( scrollTo, 100 )
    }

    const fieldErrorsKeys = _.keys(syncFieldErrors)
    const fieldErrorsText = _.map(fieldErrorsKeys, i => _.get(fieldErrors, i))
    const message = _.join(fieldErrorsText, ', ')

    return (
        <div>
            <button
                className={classNames(style.container, isValid ? style.primary : style.secondary)}
                type='button'
                onClick={handleClick}
                disabled={isValid && !isFormChanged}
            >
                { isValid ? buttonText : "Заполните обязательные поля" }
            </button>
            { !isValid && <div className={style.warningMessage}>{message}</div> }
        </div>
    )
}

export default connect()(Component)
