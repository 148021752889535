import React from 'react'
import _ from 'lodash'

import style from './style.css'
import Group from './group'

function Component (props) {
    const { formValues, formName } = props

    if (!formValues.groupedBuildings) return null
    return (
        <div className={style.container}>
            {_.map(formValues.groupedBuildings, (group, groupName) => (
                <Group groupName={groupName} buildings={group} formValues={formValues} formName={formName} />
            ))}
        </div>
    )
}

export default Component
