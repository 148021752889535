import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classNames from 'classnames'

import { makeIsHomeSelectActive } from '../../__data__/selectors/buildings'
import { ReactComponent as Logo}  from '../../theme/logo.svg'
import { fetchSetHomeSelect } from '../../__data__/actions/buildings'

import { HomeSelectInput, Menu } from './components'
import style from './style.css'

function Component(props) {
    const { buildingsList, pageTitle, pageKey, children, isHomeSelectActive, setHomeSelect } = props

    const handleDisabledClick = useCallback(() => {
        setHomeSelect(false)
    }, [])
    const isPageWithoutHomeSelectInput = ['profile', 'settings', 'residents'].includes(props.pageKey)

    return (
        <div className={style.container}>
            <div className={style.header}>
                <div className={style.logoWrapper}>
                    <Link to='/' className={style.logoContainer}>
                        <Logo />
                    </Link>
                    <h1 className={style.pageTitle}>{pageTitle}</h1>
                </div>
                {buildingsList && <HomeSelectInput buildingsList={buildingsList} disabled={isPageWithoutHomeSelectInput}/>}
            </div>
            <div className={style.content}>
                <div className={classNames(style.disabled, isHomeSelectActive && style.active)} onClick={handleDisabledClick} />
                <Menu pageKey={pageKey}/>
                <div className={classNames(style.children, isHomeSelectActive && style.disabledChildren)}>{children}</div>
            </div>
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({
    isHomeSelectActive: makeIsHomeSelectActive(),
})

const mapDispatchToProps = {
    setHomeSelect: fetchSetHomeSelect,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
