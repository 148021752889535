import axios from 'axios'

import * as types from '../../../__data__/actions-types'
import {MODERATOR_STORAGE_NAME} from '../../../__data__/constants'
import {getToken} from '../../../__data__/utils'

export const fetchDeleteEmployee = () => ({type: types.DELETE_RESIDENT})
export const fetchDeleteEmployeeSuccess = (data) => ({type: types.DELETE_RESIDENT_SUCCESS, data})
export const fetchDeleteEmployeeFail = (error) => ({type: types.DELETE_RESIDENT_FAIL, error})

export const fetchBlockEmployee = () => ({type: types.BLOCK_RESIDENT})
export const fetchBlockEmployeeSuccess = (data) => ({type: types.BLOCK_RESIDENT_SUCCESS, data})
export const fetchBlockEmployeeFail = (error) => ({type: types.BLOCK_RESIDENT_FAIL, error})

export const deleteResident = (userID) => dispatch => {
    dispatch(fetchDeleteEmployee())

    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/residents/${userID}/`
    })
        .then(response => {
            dispatch(fetchDeleteEmployeeSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchDeleteEmployeeFail(error))
        })
}

export const blockResident = (userID, block) => dispatch => {
    dispatch(fetchBlockEmployee())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/residents/${userID}/suspend/`,
        data: {flag: block}
    })
        .then(response => {
            dispatch(fetchBlockEmployeeSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchBlockEmployeeFail(error))
        })
}
