import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getCompanyInformation } from '../../actions/staff-actions'
import { makeCheckedBuildings } from '../../../../__data__/selectors/common'

import style from './style.css'
import {makeCompanyInformation} from "../../selectors/company-selectors";
import { MainCompanyDetails } from './components'

function Component(props) {
    const { companyInformation, getCompanyInformation } = props

    useEffect(() => {
        getCompanyInformation()
    }, [])

    return (
        <div className={style.container}>
            <div className={style.detailsWrapper}>
                <MainCompanyDetails companyInfo={companyInformation} />
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    companyInformation: makeCompanyInformation(),
})

const mapDispatchToProps = {
    getCompanyInformation,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
