import { SubmissionError } from 'redux-form'
import { passwordRepeatValidationMessages } from '../../../../components/form/validation'
import {importResidents} from "../../../../__data__/actions/residents";

function onSubmitForm(correctResidents, dispatch) {
    return dispatch(importResidents(correctResidents))
        .catch((error) => {
        throw new SubmissionError(passwordRepeatValidationMessages[error?.message] || ' ')
    })
}

export default onSubmitForm
