import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { getProfile } from '../../__data__/actions/profile'
import { makeProfile } from '../../__data__/selectors/profile'

import { MainDetails, Buildings } from './components'
import style from './style.css'

function ProfilePage(props) {
    const { profileData, getProfile } = props

    const {
        avatar,
        firstName,
        lastName,
        phoneNumber,
        date,
        subsidiary,
        position,
        accesses,
        buildingList,
    } = profileData

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <div className={style.container}>
            <div className={style.detailsWrapper}>
                <MainDetails
                    avatar={avatar}
                    firstName={firstName}
                    lastName={lastName}
                    phoneNumber={phoneNumber}
                    date={date}
                    subsidiary={subsidiary}
                    position={position}
                    accesses={accesses}
                />
                <Buildings buildings={buildingList} />
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    profileData: makeProfile(),
})

const mapDispatchToProps = {
    getProfile,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(ProfilePage)
