import React from 'react'
import moment from 'moment-with-locales-es6'
import _ from 'lodash'

import {formatDate, formatPhoneNumber} from '../../../../../../__data__/utils'

import style from './style.css'

function Component(props) {
    const { phones, license_on, avatar, name_full, name_short } = props.companyInfo

    return (
        <div className={style.container}>
            <div className={style.personalInfo}>
                <div className={style.avatar}>
                     <img
                        className={style.img}
                        src={avatar}
                        alt="avatar"
                    />

                </div>
                <div className={style.mainComapanyDetailsInfo}>
                    <div className={style.mainComapanyDetailsInfoName}>{name_full}</div>
                    <div className={style.mainComapanyDetailsInfoPosition}>Управлющая компания</div>
                    <div className={style.mainComapanyDetailsInfoOrganization}>{name_short}</div>
                </div>
            </div>
            <div className={style.details}>
                <div className={style.detail}>
                    <div className={style.detailLabel}>Тел.</div>
                    { !_.isEmpty(phones) && <div className={style.detailValue}>{formatPhoneNumber(phones[0].number)}</div> }
                </div>
                <div className={style.detail}>
                    <div className={style.detailLabel}>Дата рег.</div>
                    { license_on && <div className={style.detailValue}>{license_on}</div> }
                </div>
            </div>
        </div>
    )
}

export default Component
