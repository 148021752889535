import React from 'react'
import _ from 'lodash'

import style from './style.css'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";

const getLabel = (label, errors) => {
    if (_.includes(errors, 'EMPTY_FIELD_UNIT')) {
        return <span className={style.errorItem}>Нет</span>
    }
    if (_.includes(errors, 'UNAVAILABLE_UNIT')) {
        return <span className={style.errorItem}>{label}</span>
    }
    return <span>{label}</span>
}

const getName = (first_name, last_name, errors) => {
    if (_.includes(errors, 'EMPTY_FIELD_FIRST_NAME') && _.includes(errors, 'EMPTY_FIELD_FIRST_NAME')) {
        return <span className={style.errorItem}>Без фамилии и имени</span>
    }
    if (_.includes(errors, 'EMPTY_FIELD_LAST_NAME')) {
        return <span>
            <span className={style.errorItem}>Без фамилии </span>
            <span>{first_name}</span>
        </span>
    }
    if (_.includes(errors, 'EMPTY_FIELD_FIRST_NAME')) {
        return <span>
            <span>{last_name}</span>
            <span className={style.errorItem}>> Без имени</span>
        </span>
    }

    return <span>{`${last_name} ${first_name}`}</span>
}

const getPhone = (phone, errors) => {
    if (_.includes(errors, 'EMPTY_FIELD_PHONE_NUMBER')) {
        return <span className={style.errorItem}>Отсутствует</span>
    }
    if (_.includes(errors, 'ALREADY_EXIST_PHONE')) {
        return <span className={style.errorItem}>{`${phone} Уже существует`}</span>
    }
    if (_.includes(errors, 'INCORRECT_FORMAT_PHONE')) {
        return <span className={style.errorItem}>{`${phone} Неверный формат`}</span>
    }
    return <span>{phone}</span>
}

const useStyles = makeStyles((theme) => ({
    mainTr: {
        backgroundColor: '#f7f8fa !important'
    }
}))

export let Component = (props) => {
    const { list } = props
    const classes = useStyles()

    return (
        <>
            {_.map(list, (i, key) => (
                <>
                    <TableRow key={`${i.id}-${key}`}
                              className={classes.mainTr}
                              tabIndex={-1}>
                        <TableCell>{getLabel(i.label, i.errors)}</TableCell>
                        <TableCell>{getName(i.first_name, i.last_name, i.errors)}</TableCell>
                        <TableCell>{getPhone(i.phone_number, i.errors)}</TableCell>
                    </TableRow>
                </>
            ))}
        </>
    )
}

export default Component

// UNAVAILABLE_UNIT
// EMPTY_FIELD_UNIT
// EMPTY_FIELD_FIRST_NAME
// EMPTY_FIELD_LAST_NAME
// EMPTY_FIELD_PHONE_NUMBER
// ALREADY_EXIST_PHONE
// INCORRECT_FORMAT_PHONE
