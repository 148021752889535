import axios from 'axios'

import { MODERATOR_STORAGE_NAME } from '../constants'
import * as types from '../actions-types'
import { getToken } from '../utils'

const cancelTokenMapTasks = new Map(); // Хранилище токенов отмены
const cancelTokenMapTasksCount = new Map(); // Хранилище токенов отмены

export const fetchGetTasks = (data) => ({ type: types.GET_TASKS, data })
export const fetchGetTasksSuccess = (data) => ({ type: types.GET_TASKS_SUCCESS, data })
export const fetchGetTasksFail = (data) => ({ type: types.GET_TASKS_FAIL, data })

export const fetchGetNextTasks = (data) => ({ type: types.GET_NEXT_TASKS, data })
export const fetchGetNextTasksSuccess = (data) => ({ type: types.GET_NEXT_TASKS_SUCCESS, data })
export const fetchGetNextTasksFail = (data) => ({ type: types.GET_NEXT_TASKS_FAIL, data })

export const fetchGetServices = () => ({ type: types.GET_SERVICES })
export const fetchGetServicesSuccess = (data) => ({ type: types.GET_SERVICES_SUCCESS, data })
export const fetchGetServicesFail = (error) => ({ type: types.GET_SERVICES_FAIL, error })

export const fetchGetExecutors = () => ({ type: types.GET_EXECUTORS })
export const fetchGetExecutorsSuccess = (data) => ({ type: types.GET_EXECUTORS_SUCCESS, data })
export const fetchGetExecutorsFail = (error) => ({ type: types.GET_EXECUTORS_FAIL, error })

export const fetchCreateTask = () => ({ type: types.CREATE_TASK })
export const fetchCreateTaskSuccess = (data) => ({ type: types.CREATE_TASK_SUCCESS, data })
export const fetchCreateTaskFail = (error) => ({ type: types.CREATE_TASK_FAIL, error })

export const fetchGetTaskDetails = (data) => ({ type: types.GET_TASK_DETAILS, data })
export const fetchGetTaskDetailsSuccess = (data) => ({ type: types.GET_TASK_DETAILS_SUCCESS, data })
export const fetchGetTaskDetailsFail = (data) => ({ type: types.GET_TASK_DETAILS_FAIL, data })

export const fetchPerformerFormTaskDetails = (data) => ({ type: types.GET_PERFORMER_FORM_TASK_DETAILS, data })
export const fetchPerformerFormTaskDetailsSuccess = (data) => ({ type: types.GET_PERFORMER_FORM_TASK_DETAILS_SUCCESS, data })
export const fetchPerformerFormTaskDetailsFail = (data) => ({ type: types.GET_PERFORMER_FORM_TASK_DETAILS_FAIL, data })

export const fetchGetTasksCount = (data) => ({ type: types.GET_TASKS_COUNT, data })
export const fetchGetTasksCountSuccess = (data, stage) => ({ type: types.GET_TASKS_COUNT_SUCCESS, data, stage })
export const fetchGetTasksCountFail = (data) => ({ type: types.GET_TASKS_COUNT_FAIL, data })

export const fetchSaveTasksToStoreList = (data) => ({ type: types.SAVE_TASKS_TO_STORE_LIST, data })

export const fetchPatchTask = (data) => ({ type: types.PATCH_TASK, data })
export const fetchPatchTaskSuccess = (data, stage) => ({ type: types.PATCH_TASK_SUCCESS, data, stage })
export const fetchPatchTaskFail = (data) => ({ type: types.PATCH_TASK_FAIL, data })

export const fetchSetTasksFiltersDate = (data) => ({ type: types.SET_TASKS_FILTERS_DATE, data })
export const fetchSetTasksFiltersType = (data) => ({ type: types.SET_TASKS_FILTERS_TYPE, data })

export const fetchClearTaskPatchError = () => ({ type: types.TASK_PATCH_ERROR_CLEAR })

export const getTasks = (buildingsId, stage, date, type) => dispatch => {
    // Получаем токен отмены для текущего набора параметров
    const key = `${stage}`;
    const tokenInfo = cancelTokenMapTasks.get(key) || {};
    const prevToken = tokenInfo.token;

    // Отменяем предыдущий запрос с таким же набором параметров, если он есть
    if (prevToken) {
        prevToken.cancel("Operation canceled due to new request.");
    }

    // Создаем новый токен отмены для текущего запроса
    const newToken = axios.CancelToken.source();
    tokenInfo.token = newToken;
    cancelTokenMapTasks.set(key, tokenInfo);

    dispatch(fetchGetTasks({stage}))
    return axios({
        method: 'GET',
        cancelToken: newToken.token,
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/?buildings_id=${buildingsId}&stage=${stage}&count=${30}&date=${date}${type ? `&services_id=${type}` : ''}`,
    })
        .then(response => {
            // Удаляем токен отмены из хранилища после успешного выполнения запроса
            tokenInfo.token = null;
            cancelTokenMapTasks.set(key, tokenInfo);
            dispatch(fetchGetTasksSuccess({...response, stage}))
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                // Удаляем токен отмены из хранилища после неуспешного выполнения запроса
                tokenInfo.token = null;
                cancelTokenMapTasks.set(key, tokenInfo);
                dispatch(fetchGetTasksFail( {error, stage}))
            }
        });
}

export const getNextTasks = (buildingsId, stage, date, type, lastItemOrder) => dispatch => {
    dispatch(fetchGetNextTasks({stage}))
    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/?buildings_id=${buildingsId}&stage=${stage}&count=${10}&from_order=${lastItemOrder}&date=${date}${type ? `&services_id=${type}` : ''}`,
    })
        .then(response => {
            dispatch(fetchGetNextTasksSuccess({...response, stage}))
            return 'success'
        })
        .catch(error => {
            dispatch(fetchGetNextTasksFail( {error, stage}))
        })
}

export const getTasksCount = (buildingsId, stage, date, type) => dispatch => {
    // Получаем токен отмены для текущего набора параметров
    const key = `${stage}`;
    const tokenInfo = cancelTokenMapTasksCount.get(key) || {};
    const prevToken = tokenInfo.token;

    // Отменяем предыдущий запрос с таким же набором параметров, если он есть
    if (prevToken) {
        prevToken.cancel("Operation canceled due to new request.");
    }

    // Создаем новый токен отмены для текущего запроса
    const newToken = axios.CancelToken.source();
    tokenInfo.token = newToken;
    cancelTokenMapTasksCount.set(key, tokenInfo);

    dispatch(fetchGetTasksCount())
    return axios({
        method: 'GET',
        cancelToken: newToken.token,
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/count/?buildings_id=${buildingsId}&stage=${stage}&date=${date}${type ? `&services_id=${type}` : ''}`,
    })
        .then(response => {
            // Удаляем токен отмены из хранилища после успешного выполнения запроса
            // tokenInfo.token = null;
            newToken.cancel();
            cancelTokenMapTasksCount.set(key, tokenInfo);
            dispatch(fetchGetTasksCountSuccess(response.data, stage))
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                // Удаляем токен отмены из хранилища после неуспешного выполнения запроса
                // tokenInfo.token = null;
                newToken.cancel();
                cancelTokenMapTasksCount.set(key, tokenInfo);
                dispatch(fetchGetTasksCountFail())
            }
        });
}

export const getServices = buildingId => dispatch => {
    dispatch(fetchGetServices())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/services/?buildings_id=${buildingId}`,
    })
        .then(response => {
            dispatch(fetchGetServicesSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetServicesFail(error))
        })
}

export const getExecutors = (buildingId, serviceId) => dispatch => {
    dispatch(fetchGetExecutors())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/performers/?building=${buildingId}&service=${serviceId}`,
    })
        .then(response => {
            dispatch(fetchGetExecutorsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetExecutorsFail(error))
        })
}

export const createTask = (data) => dispatch => {
    dispatch(fetchCreateTask())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/tasks/',
        ...data,
    })
        .then(response => {
            dispatch(fetchCreateTaskSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchCreateTaskFail(error))
        })
}

export const getTaskDetails = id => dispatch => {
    dispatch(fetchGetTaskDetails())
    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetTaskDetailsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetTaskDetailsFail())
        })
}

export const getPerformerFormTaskDetails = id => dispatch => {
    dispatch(fetchPerformerFormTaskDetails())
    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/${id}/`,
    })
        .then(response => {
            dispatch(fetchPerformerFormTaskDetailsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchPerformerFormTaskDetailsFail())
        })
}

export const saveTasksToStoreList = tasks => dispatch => {
    dispatch(fetchSaveTasksToStoreList(tasks))
}

export const patchTask = (taskId, data) => dispatch => {
    dispatch(fetchPatchTask())
    return axios({
        method: 'PATCH',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/tasks/${taskId}/`,
        data
    })
        .then(response => {
            dispatch(fetchPatchTaskSuccess())
        })
        .catch(error => {
            dispatch(fetchPatchTaskFail())
        })
}

export const clearTaskPatchError = tasks => dispatch => {
    dispatch(fetchClearTaskPatchError(tasks))
}
