import {Tinode} from 'tinode-sdk';

// Given message's received status, return name and color of a delivery indicator icon.
export function deliveryMarker(received) {
    switch (received) {
        case Tinode.MESSAGE_STATUS_SENDING:
            return 'access_time'; // watch face
        case Tinode.MESSAGE_STATUS_FAILED:
            return 'warning'; // yellow icon /!\
        case Tinode.MESSAGE_STATUS_SENT:
            return 'done'; // checkmark
        case Tinode.MESSAGE_STATUS_RECEIVED:
            return 'done_all'; // double checkmark
        case Tinode.MESSAGE_STATUS_READ:
            return 'done_all'; // blue double checkmark
    }
    return null;
}

// Convert seconds to minutes:seconds, i.e. 156 sec -> 2:36.
export function secondsToTime(seconds) {
    const min = Math.floor(seconds / 60);
    let sec = seconds % 60;
    sec = sec < 10 ? `0${sec}` : sec;
    return `${min}:${sec}`;
}
