import _ from 'lodash'

import { editNews }  from '../../__data__/actions/news'

const getData = (values) => {
    const files = _.filter(values.files, i => _.get(i, 'status') === 'done')
    const attachments = _.map(files, i => (i.id))

    return {
        title: _.get(values, 'newsTitle'),
        description: _.get(values, 'newsDescription'),
        type: _.get(values, 'newsType'),
        id: _.get(values, 'id'),
        attachments,
    }
}

function submit(values, dispatch) {
    const data = getData(values)

    dispatch(editNews(data, data.id))
}

export default submit
