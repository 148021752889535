import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from "reselect"
import { change } from "redux-form"

import { makeBuildingList } from "../../../../__data__/selectors/buildings"
import { HomeSelectInput } from '../../../../components/form'

function Component({ input, meta, buildingsList, defaultOpen, formName, changeField, label, unit }) {
    const handleChange = useCallback((e) => {
        changeField(formName, `${unit}.flatNumber`, null)
    }, [])

    return (
        <HomeSelectInput
            input={input}
            meta={meta}
            list={buildingsList}
            handleChange={handleChange}
            label={label}
            defaultOpen={defaultOpen}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    buildingsList: makeBuildingList(),
})

const mapDispatchToProps = {
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
