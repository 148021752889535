import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import AccessDenied from  '../../pages/access-denied'
import { makeIsAccessDenied } from '../../__data__/selectors/common'

function Component(props) {
    const { children, isAccessDenied } = props

    if (isAccessDenied) return <AccessDenied />

    return children
}

const mapStateToProps = createStructuredSelector({
    isAccessDenied: makeIsAccessDenied(),
})

const mapDispatchToProps = {}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
