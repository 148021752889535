import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { getFilteredEntry, getUnits } from '../../../../__data__/actions/residents'
import { makeFilteredEntry, makeFilteredEntryStatus } from '../../../../__data__/selectors/residents'

import MultiSelect from './multiselect'
import style from './style.css'

function Component(props) {
    const { getFilteredEntry, filteredEntry, filteredStatus, getUnits } = props

    const [isSearchEmpty, setSearchEmpty] = useState(true)
    const [value, setValue] = useState([])

    const handleSearch = useCallback(event => {
        if (event.target.value.length === 0) {
            setSearchEmpty(true)
        } else {
            const context = _.map(value, i => {
                const object_type = i.objectType
                const search_type = i.searchType
                const id = search_type === 'object' ? i.uid : i.searchString
                return ({object_type, search_type, id})
            })

            getFilteredEntry({search: event.target.value, context})
            setSearchEmpty(false)
        }
    }, [_.size(value)])

    useEffect(() => {
        if (!_.isEmpty(value)) {
            const params = []
            _.forEach(value, i => {
                if (i.searchType === 'object') {
                    if (i.objectType === 'complex') {
                        params.push(`complexes_id=${i.uid}`)
                    }
                    if (i.objectType === 'building') {
                        params.push(`buildings_id=${i.uid}`)
                    }
                    if (i.objectType === 'resident') {
                        params.push(`residents_id=${i.uid}`)
                    }
                }
                else {
                    if (i.objectType === 'complex') {
                        params.push(`complex_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'building') {
                        params.push(`building_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'resident') {
                        params.push(`resident_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'porch') {
                        params.push(`porch_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'unit') {
                        params.push(`unit_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'pipe') {
                        params.push(`pipe_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'floor') {
                        params.push(`floor_entry=${i.searchString}`)
                    }
                    if (i.objectType === 'phone') {
                        params.push(`phone_entry=${i.searchString}`)
                    }
                }
            })

            getUnits(params.join("&"))
        }

    }, [_.size(value)])

    return (
        <div className={style.container}>
            <MultiSelect
                onSearch={handleSearch}
                data={filteredEntry}
                setValue={setValue}
                value={value}
                isSearchEmpty={isSearchEmpty}
                isLoading={filteredStatus?.isLoading}
                isError={filteredStatus?.isError}
            />
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    filteredEntry: makeFilteredEntry(),
    filteredStatus: makeFilteredEntryStatus(),
})

const mapDispatchToProps = {
    getFilteredEntry,
    getUnits,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
