import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { deleteAvatar } from '../../../../../__data__/actions/profile'

function Component(props) {
    const { children, deleteAvatar } = props

    let buttonPressTimer

    const handleButtonPress = e => {
        e.preventDefault()
        buttonPressTimer = setTimeout(() => deleteAvatar(), 1500)
    }

    const handleButtonRelease = e => {
        e.preventDefault()
        clearTimeout(buttonPressTimer)
    }

    return (
        <div
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
        >
            {children}
        </div>
    )
}

const mapDispatchToProps = {
    deleteAvatar,
}

const withConnect = connect(
    null,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
