import _ from 'lodash'

import { createTask }  from '../../__data__/actions/tasks'
import { TASK_PORCHES_ITEMS_ID, TASK_FLOORS_ITEMS_ID } from '../../__data__/constants'

const getTargetData = (values) => {
    const objectType = _.get(values, 'homeNumber.type')
    if (objectType === 'porch') {
        const porch = _.get(values, 'homeNumber.value')
        const floorsToggle = _.get(values, 'floorsToggle')

        const isPorchesTarget = floorsToggle === TASK_FLOORS_ITEMS_ID.all
        const isPorchesAndFloors = floorsToggle === TASK_FLOORS_ITEMS_ID.some

        const floors = _.reduce(values.floors, (result, value, key) => {
            if (value) {
                result.push(key)
            }
            return result
        }, [])

        let target_type
        if (isPorchesTarget && !isPorchesAndFloors) target_type = 'porch'
        else if (isPorchesAndFloors) target_type = 'porch_floor'

        const target = {porches: [porch]}
        if (isPorchesAndFloors) target['floors'] = floors

        return {
            target_type,
            target,
        }
    } else if (objectType === 'building') {
        const building = _.get(values, 'homeNumber.value')
        const porchesToggle = _.get(values, 'porchesToggle')
        const floorsToggle = _.get(values, 'floorsToggle')

        const isBuildingTarget = porchesToggle === TASK_PORCHES_ITEMS_ID.home
        const isPorchesTarget = porchesToggle === TASK_PORCHES_ITEMS_ID.porches
        const isPorchesAndFloors = floorsToggle === TASK_FLOORS_ITEMS_ID.some

        const porches = _.reduce(values.porches, (result, value, key) => {
            if (value) {
                result.push(key)
            }
            return result
        }, [])

        const floors = _.reduce(values.floors, (result, value, key) => {
            if (value) {
                result.push(key)
            }
            return result
        }, [])

        let target_type
        if (isBuildingTarget) target_type = 'building'
        else if (isPorchesTarget && !isPorchesAndFloors) target_type = 'porch'
        else if (isPorchesAndFloors) target_type = 'porch_floor'

        const target = {}
        if (isBuildingTarget) target['building'] = building
        if (isPorchesTarget) target['porches'] = porches
        if (isPorchesAndFloors) target['floors'] = floors

        return {
            target_type,
            target,
        }
    }

    return {}
}

const getData = (values) => {
    const service = _.get(values, 'taskType.value')
    const description = _.get(values, 'description')
    const files = _.filter(values.files, i => _.get(i, 'status') === 'done')
    const attachments = _.map(files, i => (i.id))
    const performer = _.get(values, 'performer.value')
    const targetData = getTargetData(values)

    const data = {
        service,
        attachments,
        information: { description },
        ...targetData,
    }

    if (performer) data['performer'] = performer
    return { data }
}

function submit(values, dispatch) {
    const data = getData(values)
    dispatch(createTask(data))
}

export default submit
