import React from 'react'
import {Tinode} from 'tinode-sdk';
import {Contact} from '../../components/contact';
import {Header} from '../../components/header';
import {SearchField} from '../../components/search-field';

import style from './style.css';

export const ContactsView = ({usersList, onClick, tinode, onSearch, ...rest}) => (
    <div className={style.wrapper}>
        <Header>
            <SearchField onSearch={onSearch} {...rest} />
        </Header>
        <ul className={style.contacts}>
            {
                usersList && usersList.map((user) => (
                    <Contact
                        isOnline={tinode.isTopicOnline(user.user)}
                        isGroup={Tinode.isGroupTopicName(user.user)}
                        user={user}
                        key={`${user.user}_${user.topic}`}
                        onClick={onClick}
                    />
                ))
            }
        </ul>
    </div>
)
