import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

import Label from '../label'
import { FieldErrorMessage } from '../'

import style from './style.css'

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched

    return (
        <div>
            <div className={style.container}>
                <InputMask
                    {...props.input}
                    mask={props.mask}
                    placeholder={props.placeholder}
                    type={props.type || 'text'}
                    className={classNames(
                        style.field,
                        style[`size-${props.size}`],
                        isInvalid && style.invalid
                    )}
                />
                <Label>{props.label}</Label>
            </div>
            <FieldErrorMessage meta={props.meta} />
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
}

Component.defaultProps = {
    size: 'auto',
}

export default Component
