import React from 'react'
import { Provider } from 'react-redux'

import { default as store } from './store'
import styles from './App.css'
import { default as Routes } from './routes'

function App() {
  return (
    <Provider store={store} >
        <div className={styles.container}>
            <Routes />
        </div>
    </Provider>
  )
}

export default App
