import React, { useEffect, useCallback } from "react"
import { Carousel } from 'react-responsive-carousel'
import {createStructuredSelector} from "reselect"
import {connect} from "react-redux"
import {compose} from "redux"
import _ from 'lodash'
import FocusLock from 'react-focus-lock'

import { makeCarousel } from '../../__data__/selectors/news'
import { setCarousel } from "../../__data__/actions/news"

import { default as Item } from './item'
import style from './style.css'

function Component (props) {
    const { data } = props

    const images = _.get(data, 'imageNames')
    const avatar = _.get(data, 'avatar')

    const escFunction = useCallback(event => {
        if(event.keyCode === 27) {
            props.setCarousel([])
        }
    }, [])

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false)

        if (!_.isEmpty(images)) {
            document.body.style.overflow = "hidden"
            document.body.style.height = "100vh"
        } else {
            document.body.style.overflow = "unset"
            document.body.style.height = "auto"
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false)
        }
    }, [images])

    const handleCloseClick = useCallback(event => {
        props.setCarousel([])
    }, [])

    if(_.isEmpty(images)) return null

    const selectedItem = _.indexOf(images, data.triggerImage)

    return (
        <div className={style.container}>
            <div className={style.bg} />
            <div className={style.wrapper}>
                <div className={style.header}>
                    <h2 className={style.title}>{_.get(data, 'title')}</h2>
                    <button className={style.closeButton} onClick={handleCloseClick} />
                </div>
                <FocusLock>
                    <Carousel showArrows={true} useKeyboardArrows={true} infiniteLoop={true} selectedItem={selectedItem} showThumbs={false}>
                        {_.map(images, image => <Item imageUrl={image} key={image} />)}
                    </Carousel>
                </FocusLock>
                <div className={style.footer}>
                    <div className={style.footerLeft}>
                        {avatar && <img width="40px" height="40px" src={avatar} alt="Author's avatar"/>}
                        {!avatar &&
                            <svg width="40px" height="40px" viewBox="0 0 60 60" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                <title>DISPLAY/avatar</title>
                                <defs>
                                    <circle id="path-1" cx="30" cy="30" r="30"></circle>
                                    <linearGradient x1="50%" y1="1.15175769%" x2="50%" y2="100%" id="linearGradient-3">
                                        <stop stopColor="#FFFFFF" offset="0%"></stop>
                                        <stop stopColor="#EAEAEA" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <g id="SCREENS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="_ASSETS" transform="translate(-810.000000, -112.000000)">
                                        <g id="DISPLAY/avatar" transform="translate(810.000000, 112.000000)">
                                            <mask id="mask-2" fill="white">
                                                <use href="#path-1"></use>
                                            </mask>
                                            <use id="MASK" fill="#FFFFFF" fillRule="evenodd" href="#path-1"></use>
                                            <g id="XTRAZ/avatars/_person" mask="url(#mask-2)" fillRule="evenodd">
                                                <rect id="Rectangle" fill="url(#linearGradient-3)" x="0" y="0"
                                                      width="60" height="60"></rect>
                                                <path
                                                    d="M37.5,21.25 C37.5,17.244375 34.255625,14 30.25,14 C26.244375,14 23,17.244375 23,21.25 C23,25.255625 26.244375,28.5 30.25,28.5 C34.255625,28.5 37.5,25.255625 37.5,21.25 Z M30.0498176,31 C34.0953955,31 41.6664583,33.9087309 43.5,39.6882178 C40.1959067,43.55356 35.3070398,46 29.8518504,46 C24.5572284,46 19.7960815,43.695457 16.5,40.0263081 C18.1397135,34.0220554 25.9261847,31 30.0498176,31 Z"
                                                    id="Shape" fill="#B7BABF"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        }
                        <div className={style.resedentData}>
                            <div className={style.target}>{_.get(data, 'target')}</div>
                            <div className={style.date}>{_.get(data, 'date')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = () => createStructuredSelector({
    data: makeCarousel(),
})

const mapDispatchToProps = {
    setCarousel,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
