import React from 'react'
import {Field, getFormSubmitErrors, isValid, reduxForm} from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

import { CHAT_TOKEN, FORM_NAMES, MODERATOR_STORAGE_NAME, URL_LIST } from '../../../__data__/constants'
import { TextInput } from '../../../components/form'
import { required, passwordCreateValidate } from '../../../components/form/validation'
import { makeIsPasswordChanged } from '../../../__data__/selectors/login'
import { clearIsPasswordChanged } from '../../../__data__/actions/login'
import { getToken } from '../../../__data__/utils'
import { default as Action } from './action'
import style from '../style.css'

import onSubmitForm from './submit'

function Component({
       handleSubmit,
       isFormValid,
       fieldErrors,
       dispatch,
       isPasswordChanged,
       clearIsPasswordChanged,
       formSubmitErrors,
       transportToken,
   }) {
    const token = getToken(MODERATOR_STORAGE_NAME)

    if (!token) return <Redirect to={URL_LIST.loginForm} />

    const onSubmit = (values) => {
        return onSubmitForm(values, transportToken, dispatch)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)
        }
    }

    if (isPasswordChanged) {
        clearIsPasswordChanged()
        localStorage.removeItem(MODERATOR_STORAGE_NAME)
        localStorage.removeItem(CHAT_TOKEN)
        return <Redirect to={URL_LIST.loginForm} />
    }

    return (
        <form id={FORM_NAMES.passwordChangeLoggedForm} onSubmit={handleSubmit(onSubmit)} onKeyPress={onKeyPress}>
            <h2>Восстановление доступа</h2>
            <div className={style.fieldsSection}>
                <Field
                    name="password"
                    component={TextInput}
                    type="password"
                    label="Задайте новый пароль"
                    validate={[ passwordCreateValidate ]}
                    size='auto'
                />
            </div>
            <div className={style.fieldsSection}>
                <Field
                    name="passwordConfirm"
                    component={TextInput}
                    type="password"
                    label="И подтвердите"
                    validate={[ required ]}
                    size='auto'
                />
            </div>
            <div className={style.submitError}>
                {formSubmitErrors?.error}
            </div>
            <div>
                <Action
                    isValid={isFormValid}
                    type="submit"
                    handleSubmit={handleSubmit}
                    formName={FORM_NAMES.passwordChangeLoggedForm}
                    fieldErrors={fieldErrors}
                />
            </div>
        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.passwordChangeLoggedForm),
    formSubmitErrors: getFormSubmitErrors(FORM_NAMES.passwordChangeLoggedForm),
    isPasswordChanged: makeIsPasswordChanged(),
})

const mapDispatchToProps = {
    clearIsPasswordChanged,
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.passwordChangeLoggedForm,
    //onSubmit: onSubmitForm
}) (Component)

export default compose(withConnect)(Component)
