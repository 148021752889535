import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeCameraFormInit = () =>
    createSelector(app, slice => {
        const accessBuildings = _.get(slice, 'buildings')
        const camera = _.get(slice, 'camera')

        const checkedBuildings = _.get(camera, 'buildings')

        const accessibleBuildings = _.filter(checkedBuildings, i => {
            const building = _.find(accessBuildings, p => p.id === i.id)
            if (building && building.is_all_porches) return true
        })

        const disabledBuildings = _.filter(checkedBuildings, i => {
            const building = _.find(accessBuildings, p => p.id === i.id)
            if (!building || !building.is_all_porches) return true
        })

        return {
            name: camera?.name,
            link: camera?.link,
            id: camera?.id,
            accessibleBuildings: _.map(accessibleBuildings, i =>({ id: i.id, label: `${i.street}, ${i.number}`})),
            disabledBuildings: _.map(disabledBuildings, i =>({ id: i.id, label: `${i.street} ${i.number}`})),
        }
    })
