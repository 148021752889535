import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, getFormValues } from 'redux-form'
import _ from 'lodash'

import { Checkbox, RadioGroupMenu } from '../../../../components/form'
import formStyle from '../../../../theme/form.css'
import {
    makeBuildingPorches,
    makeBuildingFloorList,
} from '../../../../__data__/selectors/building'
import { TASK_FLOORS_MENU_ITEMS } from '../../../../__data__/constants'

function Component(props) {
    const { formValues, buildingFloorList } = props

    const floorsToggleValue = _.get(formValues, 'floorsToggle')
    const floors = _.get(formValues, 'floors')
    const isFloorsValid = !_.isEmpty(floors) && _.includes(floors, true)
    const isOneOfFloors = () => isFloorsValid ? undefined : 'Выберите хотя бы один этаж'

    return (
        <>
            <RadioGroupMenu name='floorsToggle' items={TASK_FLOORS_MENU_ITEMS} />
            { floorsToggleValue === 'some' &&
            <div className={formStyle.checkboxGroup}>
                {_.map(buildingFloorList, i => (
                    <Field
                        name={`floors.${i.id}`}
                        component={Checkbox}
                        type="checkbox"
                        label={`№ ${i.number}`}
                        value=''
                        id={`floors.${i.id}`}
                        validate={[ isOneOfFloors ]}
                    />
                ))}
            </div>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    buildingPorches: makeBuildingPorches(),
    buildingFloorList: makeBuildingFloorList(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
