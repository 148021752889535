import React from 'react'
import {COMPLAINT_TYPE} from "../../../../../constants/chat";
import {ComplaintType} from "../complaint-type";
import style from './style.css';

export const ComplaintsTypeList = ({previewComplaints, selectedType, onClick}) => (
    <div className={style.wrapper}>
        <ul className={style.contacts}>
            {
                COMPLAINT_TYPE.map((complaintType) => (
                    <ComplaintType
                        key={complaintType.value}
                        complaintType={complaintType}
                        previewComplaints={previewComplaints}
                        selectedType={selectedType}
                        onClick={onClick}
                    />
                ))
            }
        </ul>
        </div>
)