import React from 'react'
import { Field, isValid, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { FORM_NAMES } from '../../../../__data__/constants'
import {MaskedInput, PhoneInput} from '../../../../components/form'
import { maskedPhoneValidate } from '../../../../components/form/validation'
import { makePasswordGeneration } from '../../../../__data__/selectors/login'

import { default as Action } from './action'
import style from './style.css'
import onSubmitForm from './submit'
import Timer from "../../../password-change-logged-form/confirmation-form/components/timer";

function Component({ handleSubmit, isFormValid, fieldErrors, dispatch, passwordGeneration }) {
    const { requestCooldown } = passwordGeneration
    const onSubmit = (values) => {
        return onSubmitForm(values, dispatch)
    }

    return (
        <form id={FORM_NAMES.confirmationPhoneNumberForm} onSubmit={handleSubmit(onSubmit)}>
            <h2>Восстановление доступа</h2>
            {passwordGeneration?.isError &&
                <div className={style.errorMessage}>
                    {passwordGeneration.errorCode === 403 && <div>Превышен лимит попыток, необходимо подождать</div>}
                    {passwordGeneration.errorCode === 404 && <div>Пользователя с таким номером телефона нет в системе</div>}
                </div>
            }
            <div className={style.fieldsSection}>
                <Field
                    name="username"
                    component={MaskedInput}
                    type="text"
                    label="Для пользователя с номером"
                    mask="+7 (999) 999-99-99"
                    placeholder="+7 (999) 999-99-99"
                    validate={[ maskedPhoneValidate ]}
                    size='auto'
                />
            </div>
            <div>
                <Action
                    isValid={isFormValid}
                    type="submit"
                    handleSubmit={handleSubmit}
                    formName={FORM_NAMES.confirmationPhoneNumberForm}
                    fieldErrors={fieldErrors}
                />
            </div>
            { requestCooldown ? <Timer interval={requestCooldown} onFinish={() => true} /> : null }
        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.confirmationPhoneNumberForm),
    passwordGeneration: makePasswordGeneration(),
})

const withConnect = connect(
    mapStateToProps, null
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.confirmationPhoneNumberForm,
    // onSubmit: onSubmitForm,
}) (Component)

export default compose(withConnect)(Component)
