import React, {useCallback} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash'

import style from './style.css';
import {COMPLAINT_TYPE, TABS} from "../../../../constants/chat";

export const TabCell = ({onClick, title, value, previewComplaints, selectedTab}) => {
    const onHandleClick = useCallback(() => onClick({title, value}), [title, value, onClick]);
    let unreadComplaintsCount = 0
    if (!_.isEmpty(previewComplaints) && title === TABS[3].title) {
        COMPLAINT_TYPE.map((complaintType) => (
            unreadComplaintsCount += previewComplaints[complaintType.value]['unread_count']
        ))
    }

        return (
            <li
                className={style.tab}
                key={value}
                onClick={onHandleClick}
            >
                <div
                    className={classnames(style.inner, {[style.selected]: value === selectedTab})}>
                <span
                    className={style.content}
                >
                    {title}
                {
                    title === TABS[3].title && unreadComplaintsCount !== 0 &&
                        <div className={classnames(style.tag, {[style.selected]: value === selectedTab})}>
                            {unreadComplaintsCount !== 0 && unreadComplaintsCount}
                        </div>
                }

                </span>
                </div>
            </li>
        )
}

TabCell.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selectedTab: PropTypes.string.isRequired
}
