import React, { useEffect } from 'react'
import {Field, getFormSubmitErrors, isValid, reduxForm} from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

import { CHAT_TOKEN, FORM_NAMES, MODERATOR_STORAGE_NAME, URL_LIST } from '../../__data__/constants'
import { TextInput } from '../../components/form'
import { required, passwordCreateValidate } from '../../components/form/validation'
import { makeIsPasswordChanged } from '../../__data__/selectors/login'
import { clearIsPasswordChanged } from '../../__data__/actions/login'
import { getProfile } from '../../__data__/actions/profile'
import { makeProfile } from '../../__data__/selectors/profile'

import { default as Action } from './action'
import style from './style.css'
import onSubmitForm from './submit'

function Component({
                       handleSubmit,
                       isFormValid,
                       fieldErrors,
                       dispatch,
                       isPasswordChanged,
                       clearIsPasswordChanged,
                       formSubmitErrors,
                       getProfile,
                       profile,
}) {
    const queryParams = queryString.parse(window.location.search)
    const queryToken = _.get(queryParams, 'token')

    // Разлогиниваем
    useEffect(() => {
        if (queryToken) {
            localStorage.removeItem(MODERATOR_STORAGE_NAME)
            localStorage.removeItem(CHAT_TOKEN)
        }
    }, [queryToken])

    useEffect(() => {
        if (queryToken) {
            getProfile(queryToken)
        }
    }, [queryToken])

    if (!queryToken) return <Redirect to={URL_LIST.loginForm} />

    const onSubmit = (values) => {
        return onSubmitForm(values, queryToken, dispatch)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)
        }
    }

    if (isPasswordChanged) {
        clearIsPasswordChanged()
        return <Redirect to={URL_LIST.loginForm} />
    }

    if (profile?.isTokenError) {
        return (
            <div className={style.wrapper}>
                <div className={style.container}>
                    <h2>Не смогли получить сотрудника с таким токеном</h2>
                </div>
            </div>
        )
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <form id={FORM_NAMES.passwordCreatingForm} onSubmit={handleSubmit(onSubmit)} onKeyPress={onKeyPress}>
                    <div className={style.mainLogo}>
                        <img src="/logo192.png" alt="logo"/>
                        <h2>Получение доступа</h2>
                    </div>
                    <div className={style.fieldsSection}>
                        <Field
                            name="password"
                            component={TextInput}
                            type="password"
                            label="Задайте пароль"
                            validate={[ passwordCreateValidate ]}
                            size='auto'
                        />
                    </div>
                    <div className={style.fieldsSection}>
                        <Field
                            name="passwordConfirm"
                            component={TextInput}
                            type="password"
                            label="Повторите"
                            validate={[ required ]}
                            size='auto'
                        />
                    </div>
                    <div className={style.submitError}>
                        {formSubmitErrors?.error}
                    </div>
                    <div>
                        <Action
                            isValid={isFormValid}
                            type="submit"
                            handleSubmit={handleSubmit}
                            formName={FORM_NAMES.passwordCreatingForm}
                            fieldErrors={fieldErrors}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.passwordCreatingForm),
    formSubmitErrors: getFormSubmitErrors(FORM_NAMES.passwordCreatingForm),
    isPasswordChanged: makeIsPasswordChanged(),
    profile: makeProfile(),
})

const mapDispatchToProps = {
    clearIsPasswordChanged,
    getProfile,
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.passwordCreatingForm,
    //onSubmit: onSubmitForm
}) (Component)

export default compose(withConnect)(Component)
