import React from 'react'
import classNames from 'classnames'
import InputMask from 'react-input-mask'
import { change } from 'redux-form'
import { connect } from "react-redux"
import { compose } from "redux"

import style from './style.css'
import { FieldErrorMessage } from "../../../../../components/form"

const maxDay = 28

const formatValue = (value) => {
    const numberValue = value.replace(/[^+\d]/g, '')
    return numberValue.padStart(2, '0')
}

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched
    const fieldName = props.input.name
    const depFieldValue = props.depFieldValue

    const handleBlur = (e) => {
        props.input.onBlur(e)

        if (e.target.value) {
            // Добавляем нули в начало значения
            let preparedValue = formatValue(e.target.value)
            if (preparedValue > maxDay) {
                preparedValue = maxDay
            }
            props.change(props.formName, props.input.name, preparedValue)

            // Меняем значение в зависимом поле
            if (fieldName === 'start_date' && depFieldValue < preparedValue) {
                props.change(props.formName, 'end_date', preparedValue)
            }
            if (fieldName === 'end_date' && preparedValue < depFieldValue) {
                props.change(props.formName, 'start_date', preparedValue)
            }
        }
        else {
            props.change(props.formName, props.input.name, '')
        }
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <InputMask
                    {...props.input}
                    onBlur={handleBlur}
                    mask="9 9"
                    placeholder="_ _"
                    className={classNames(
                    style.field,
                    isInvalid && style.invalid
                )} />
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    change
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(Component)
