import React, { useState } from 'react'
import { getFormSubmitErrors, isValid, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

import { FORM_NAMES, MODERATOR_STORAGE_NAME, URL_LIST } from '../../__data__/constants'
import { makeIsPasswordChanged } from '../../__data__/selectors/login'
import { clearIsPasswordChanged } from '../../__data__/actions/login'
import { getToken } from '../../__data__/utils'

import style from './style.css'

import PasswordForm from './password-form'
import ConfirmationForm from './confirmation-form'

function Component() {
    const token = getToken(MODERATOR_STORAGE_NAME)

    const [transportToken, setTransportToken] = useState(null)

    if (!token) return <Redirect to={URL_LIST.loginForm} />

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                { !transportToken ?
                    <ConfirmationForm setTransportToken={setTransportToken} /> :
                    <PasswordForm transportToken={transportToken} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.passwordChangeLoggedForm),
    formSubmitErrors: getFormSubmitErrors(FORM_NAMES.passwordChangeLoggedForm),
    isPasswordChanged: makeIsPasswordChanged(),
})

const mapDispatchToProps = {
    clearIsPasswordChanged,
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.passwordChangeLoggedForm,
}) (Component)

export default compose(withConnect)(Component)
