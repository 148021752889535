import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Link } from 'react-scroll'
import ReactTooltip from 'react-tooltip'

import style from './tabs.css'

function Component(props) {
    const { items, activeItem, handleClick, useScroll } = props
    let tabs

    // При дефолтном spy={true} был косяк скрола из-за близко расположенных элементов друг к другу.
    // Поэтому отключил spy, создал свой handleScroll там, где используется ScreenTabs
    if (useScroll) {
        tabs = _.map(items, i => (
            <div key={i.value} className={!i.enabled ? classNames(style.wrapperItemDisable) : '' }  data-tip={i.textBlock}  data-for={i.value} >
                {!i.enabled ? <ReactTooltip place='bottom' id={i.value} backgroundColor='#2d313a'/> : null}
                <Link to={i.value} activeClass={classNames(style.active2)} spy={false} smooth={true} duration={800} offset={-173} onSetActive={() => useScroll && i.enabled ? handleClick(i.value) : null}>
                    <div key={i.value} className={classNames(style.item, i.value === activeItem && style.active, !i.enabled ? classNames(style.itemDisable) : '')} onClick={!useScroll &&  i.enabled ? () => handleClick(i.value) : null} data-value={i.value}>
                        {i.title}
                    </div>
                </Link>
            </div>
        ))
    }
    else {
        tabs = _.map(items, i => (
            <div key={i.value} className={!i.enabled ? classNames(style.wrapperItemDisable) : '' }  data-tip={i.textBlock}  data-for={i.value} >
                {!i.enabled && <ReactTooltip place='bottom' id={i.value} backgroundColor='#2d313a' />}
                <div key={i.value} className={classNames(style.item, i.value === activeItem && style.active, !i.enabled ? classNames(style.itemDisable) : '')} onClick={i.enabled ? () => handleClick(i.value) : null} data-value={i.value}>
                    {i.title}
                </div>
            </div>
        ))
    }
    return (
        <ul className={style.container}>
            {tabs}
        </ul>
    )
}

export default Component
