import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import classNames from 'classnames'
import { compose } from 'redux'

import { makeCamerasFields } from '../../../selectors/staff-selectors'
import { BUILDING_TYPES } from '../../../../../__data__/constants'

import Field from './field'
import style from './style.css'

function Component(props) {
    const { formValues } = props
    const buildingType = formValues ? BUILDING_TYPES[formValues?.buildingType] : ''

    return (
        <>
            <div className={classNames(style.fieldsSection)}>
                <div className={style.inline}>
                    <Field label='Полный адрес' value={formValues?.address} />
                    <Field label='Тип объекта' value={buildingType} />
                </div>
                <div className={style.delimiter} />
                <div className={style.inline}>
                    <Field label='Входов' value={formValues?.porchesCount} />
                    <Field label='Этажей' value={formValues?.floorCount} />
                    <Field label='Помещений' value={formValues?.flatsCount} />
                </div>
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field label='В управлении' value={formValues?.organisationName} />
            </div>
        </>
    )
}

const mapStateToProps = () => createStructuredSelector({
    camerasFields: makeCamerasFields(),
})

const withConnect = connect(
    mapStateToProps,
    null,
)

export default compose(withConnect)(Component)
