import React from 'react'
import _ from 'lodash'

import style from './style.css'
import Attachment from './attachment'

function Images(props) {
    const { imageNames, carouselData } = props

    const imagesCount = imageNames.length

    if (_.isEmpty(imageNames)) return null

    const moreImageCount = imagesCount - 4

    return (
        <div className={style.container}>
            { imagesCount === 1 && (
                <Attachment
                    imageName={imageNames[0]}
                    size='xxl'
                    imageNames={imageNames}
                    carouselData={carouselData}
                />
            )}
            { imagesCount === 2 && (
                <div className={style.twoImages}>
                    <Attachment
                        imageName={imageNames[0]}
                        size='xl'
                        imageNames={imageNames}
                        carouselData={carouselData}
                    />
                    <div className={style.rightImages}>
                        <Attachment
                            imageName={imageNames[1]}
                            size='l'
                            imageNames={imageNames}
                            carouselData={carouselData}
                        />
                    </div>
                </div>
            )}
            { imagesCount === 3 && (
                <div className={style.threeImages}>
                    <Attachment
                        imageName={imageNames[0]}
                        size='xl'
                        imageNames={imageNames}
                        carouselData={carouselData}
                    />
                    <div className={style.rightImages}>
                        <div className={style.topImages}>
                            <Attachment
                                imageName={imageNames[2]}
                                size='md'
                                imageNames={imageNames}
                                carouselData={carouselData}
                            />
                        </div>
                        <Attachment
                            imageName={imageNames[1]}
                            size='sl'
                            imageNames={imageNames}
                            carouselData={carouselData}
                        />
                    </div>
                </div>
            )}
            { imagesCount === 4 && (
                <div className={style.fourImages}>
                    <Attachment
                        imageName={imageNames[0]}
                        size='xl'
                        imageNames={imageNames}
                        carouselData={carouselData}
                    />
                    <div className={style.rightImages}>
                        <div className={style.topImages}>
                            <Attachment
                                imageName={imageNames[3]}
                                size='sm'
                                imageNames={imageNames}
                                carouselData={carouselData}
                            />
                            <div className={style.rightTopImages}>
                                <Attachment
                                    imageName={imageNames[2]}
                                    size='xs'
                                    imageNames={imageNames}
                                    carouselData={carouselData}
                                />
                            </div>
                        </div>
                        <Attachment
                            imageName={imageNames[1]}
                            size='sl'
                            imageNames={imageNames}
                            carouselData={carouselData}
                        />
                    </div>
                </div>
            )}
            { imagesCount > 4 && (
                <div className={style.fourImages}>
                    <Attachment
                        imageName={imageNames[0]}
                        size='xl'
                        imageNames={imageNames}
                        carouselData={carouselData}
                    />
                    <div className={style.rightImages}>
                        <div className={style.topImages}>
                            <Attachment
                                imageName={imageNames[3]}
                                size='sm'
                                imageNames={imageNames}
                                carouselData={carouselData}
                            />
                            <div className={style.rightTopImages}>
                                <Attachment
                                    imageName={imageNames[2]}
                                    size='xs'
                                    imageNames={imageNames}
                                    carouselData={carouselData}
                                    moreImageCount={moreImageCount}
                                />
                            </div>
                        </div>
                        <Attachment
                            imageName={imageNames[1]}
                            size='sl'
                            imageNames={imageNames}
                            carouselData={carouselData}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Images
