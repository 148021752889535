import classNames from "classnames";
import React, { useEffect, useCallback } from "react"

import {createStructuredSelector} from "reselect"
import {connect} from "react-redux"
import {compose} from "redux"
import _ from 'lodash'
import FocusLock from 'react-focus-lock'

import { getStoriFull, setStori } from "../../__data__/actions/news"

import Stories from 'react-insta-stories';
import { makeCurrentStoriIndex, makeStories, makeStoriesFull } from "../../__data__/selectors/stori";
import style from './style.css'

function Component (props) {
    const { stories, currentStoriIndex, storiListFull } = props
    const [paused, setPaused] = React.useState(false)
    const [curretStoriIndexLocal, setCurretStoriIndexLocal] = React.useState(0)
    const [curretFrameIndexLocal, setFrameStoriIndexLocal] = React.useState(0)

    useEffect(() => {
        if((currentStoriIndex === 0 || currentStoriIndex) && currentStoriIndex != -1){
            setCurretStoriIndexLocal(currentStoriIndex)
            _.forEach(stories, i => {
                props.getStoriFull(i.id)
            })
        }
    }, [stories, currentStoriIndex])

    const escFunction = useCallback(event => {
        if(event.keyCode === 27) {
            setCurretStoriIndexLocal(0)
            setFrameStoriIndexLocal(0)
            props.setStori([])
        }
    }, [])


    useEffect(() => {
        document.addEventListener("keydown", escFunction, false)

        if (currentStoriIndex !== -1) {
            document.body.style.overflow = "hidden"
            document.body.style.height = "100vh"
        } else {
            document.body.style.overflow = "unset"
            document.body.style.height = "auto"
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false)
        }
    }, [currentStoriIndex])


    const handleCloseClick = useCallback(event => {
        setCurretStoriIndexLocal(0)
        setFrameStoriIndexLocal(0)
        props.setStori([])
    }, [])




    if(currentStoriIndex !== 0 && !currentStoriIndex || currentStoriIndex ===-1 ) {
        return null
    }

    const header = (title) => {
        return (<div className={style.headerStory}>{title}</div>)
    }

    const onStoryStart = (currIndex) => {
        setPaused(true)
        setFrameStoriIndexLocal(currIndex)
        setPaused(false)
    }

    const onAllStoriesEnd = () => {
        setPaused(true)
        setFrameStoriIndexLocal(0)
        setPaused(false)
        handleRightClick()
    }

    const handleRightClick= () => {
        setPaused(true)
        const newIndex = curretStoriIndexLocal + 1
        if(newIndex >= stories.length)
            setCurretStoriIndexLocal(0)
        else{
            setCurretStoriIndexLocal(newIndex)
        }
        setFrameStoriIndexLocal(0)
        setPaused(false)
    }

    const handleLeftClick= () => {
        setPaused(true)
        const newIndex = curretStoriIndexLocal - 1
        if(newIndex < 0){
            setCurretStoriIndexLocal(stories.length - 1)
        } else{
            setCurretStoriIndexLocal(newIndex)
        }
        setFrameStoriIndexLocal(0)
        setPaused(false)
    }

    const handleframeRightClick= () => {
        if(curretFrameIndexLocal + 1 >= storiListFull[curretStoriIndexLocal].stories.length){
            handleRightClick()
        }else{
            setFrameStoriIndexLocal(curretFrameIndexLocal + 1)
        }
    }

    const handleframeLeftClick= () => {
        if(curretFrameIndexLocal - 1 < 0){
            handleLeftClick()
        }else{
            setFrameStoriIndexLocal(curretFrameIndexLocal - 1)
        }
    }

    const prevIndex = curretStoriIndexLocal === 0 ? storiListFull.length - 1 : curretStoriIndexLocal - 1
    const nextIndex = curretStoriIndexLocal === storiListFull.length - 1 ? 0 : curretStoriIndexLocal + 1

    return (
        <div className={style.container}>
            <div className={style.bg} />
            <div className={style.wrapper}>
                <div className={style.header}>
                    <button className={style.closeButton} onClick={handleCloseClick} />
                </div>
                <FocusLock className={classNames(style.storyBlock)}>
                    <div>
                        <div  className={classNames(style.storiArrow)} onClick={handleLeftClick}>
                            <img className={classNames(style.storiArrowCover)} src={storiListFull[prevIndex]?.cover} />
                        </div>
                    </div>
                    <button  className={classNames(style.arrow, style.clickLeft)} onClick={handleframeLeftClick} />
                    {storiListFull.length && storiListFull[curretStoriIndexLocal]?.stories &&
                        <div  key={storiListFull[curretStoriIndexLocal].id}
                              className={classNames(style.storyBlockWrapper)}><Stories
                            stories={storiListFull[curretStoriIndexLocal]?.stories}
                            defaultInterval={1500}
                            width={460}
                            height={'90vh'}
                            header={header}
                            onStoryStart={onStoryStart}
                            onAllStoriesEnd={onAllStoriesEnd}
                            isPaused={paused}
                            key={storiListFull[curretStoriIndexLocal].id}
                            currentIndex={curretFrameIndexLocal}
                    />

                        </div>}
                    <button  onClick={handleframeRightClick} className={classNames(style.arrow, style.clickRight)} />
                    <div>
                        <div  onClick={handleRightClick} className={classNames(style.storiArrow)}>
                            <img  className={classNames(style.storiArrowCover)} src={storiListFull[nextIndex]?.cover} />
                        </div>
                    </div>
                </FocusLock>
            </div>
        </div>
    )
}


const mapStateToProps = () => createStructuredSelector({
    stories: makeStories(),
    storiListFull: makeStoriesFull(),
    currentStoriIndex: makeCurrentStoriIndex(),
})

const mapDispatchToProps = {
    getStoriFull,
    setStori,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
