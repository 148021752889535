import React from 'react'

import style from './style.css'

function Component() {
    return (
        <div className={style.container}>
            <div>
                <div className={style.imgWrapper}>
                    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M58.7003 0C42.2639 5.34188 12.2073 26.6193 0.0129458 44.1931L0.0125202 44.1934C-0.4184 61.6052 10.3358 97.5502 22.7517 114.964C38.6759 121.138 77.7857 122.024 98.0268 115.441C108.264 101.414 120.29 64.6943 119.995 43.2473C109.732 29.2405 79.1049 6.05156 58.7003 0ZM59.0479 9.08086C37.2217 18.9259 19.8084 33.4228 9.06849 46.9964L9.06827 46.9963C11.0697 71.0261 19.4168 92.6202 28.5701 107.329C51.8645 112.771 75.7069 111.293 92.395 107.013C104.393 86.1548 110.468 63.4947 111.312 46.1499C95.0981 28.4271 75.2296 15.0189 59.0479 9.08086Z" fill="#FEEA99"/>
                        <path d="M9.06858 46.9964C19.8085 33.4228 37.2218 18.9259 59.048 9.08086C75.2297 15.0189 95.0982 28.4271 111.312 46.1499C110.468 63.4947 104.393 86.1548 92.3951 107.013C75.707 111.293 51.8646 112.771 28.5702 107.329C19.4169 92.6202 11.0698 71.0261 9.06836 46.9963L9.06858 46.9964Z" fill="#FBBF20"/>
                        <path d="M38.4844 75H44.7344V71.6562H47.4219V66.5781H44.7344V52.4531H35.5781C31.6562 58.4375 29.4062 62.2344 27.3906 66.1719V71.6562H38.4844V75ZM32.8906 66.6562C34.75 62.7188 36.25 60.3281 38.5469 56.7969H38.6719V66.8438H32.8906V66.6562Z" fill="black"/>
                        <path d="M59.4219 75.625C65.5156 75.625 69.3438 71.0938 69.3438 63.6562C69.3438 56.1562 65.4688 51.8125 59.4219 51.8125C53.375 51.8125 49.4844 56.1719 49.4844 63.6719C49.4844 71.125 53.3281 75.625 59.4219 75.625ZM59.4219 70.5312C57.5781 70.5312 56.2344 68.3125 56.2344 63.6719C56.2344 59.0156 57.5781 56.9062 59.4219 56.9062C61.2656 56.9062 62.5938 59.0156 62.5938 63.6719C62.5938 68.3125 61.2656 70.5312 59.4219 70.5312Z" fill="black"/>
                        <path d="M82.6719 75H88.9219V71.6562H91.6094V66.5781H88.9219V52.4531H79.7656C75.8438 58.4375 73.5938 62.2344 71.5781 66.1719V71.6562H82.6719V75ZM77.0781 66.6562C78.9375 62.7188 80.4375 60.3281 82.7344 56.7969H82.8594V66.8438H77.0781V66.6562Z" fill="black"/>
                    </svg>
                </div>
                <div className={style.text}>{
                    `Вы зашли
                    в тупик`
                }</div>
            </div>
        </div>
    )
}

export default Component
