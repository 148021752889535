import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ReactTooltip from "react-tooltip"
import _ from 'lodash'

import activeIcon from '../../../../icon/actvie'
import { getComparator, stableSort } from '../../../../../../components/table/utils'
import blockIcon from '../../../../icon/block'
import createdIcon from '../../../../icon/created'
import Paginator from '../../../../../../components/paginator'
import { default as EnhancedTableHead } from './head'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginLeft: '32px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    thWrapper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    nameValue: {
        fontWeight: '600',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '6px 0 6px 16px',
    },
}))

export default function StaffTable(props) {
    const {handleClick, selected, staffObject, getListBuildingsAddress, getSettingsStaffPagination} = props

    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')


    const statusList = {
        active:  activeIcon(),
        created: createdIcon(),
        suspended: blockIcon(),
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }


    const getRights = (a) => {
        const size = _.size(a)
        const accessible = _.size(_.filter(a, i => i !== 'denied'))

        if (accessible === size) {
            return 'Все'
        }
        return `${accessible}/${size}`
    }

    const isSelected = (userId) => userId === selected

    const getDataPagination = (page, countPerPage) => {
        getSettingsStaffPagination(page, countPerPage)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody className={classes.tbody}>
                            {stableSort(staffObject.list, getComparator(order, orderBy))
                                .map((row) => {
                                    const isItemSelected = isSelected(row.user)
                                    const rights = getRights(row.sections)
                                    const objectsArr = getListBuildingsAddress(row.buildings_list,  row.porchesList)
                                    const textTooltip = objectsArr.join('</br>')
                                    let objects = objectsArr.length

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.user)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.user}
                                            selected={isItemSelected}
                                            className={classes.tr}
                                        >
                                            <TableCell className={classNames(classes.value, classes.nameValue)}
                                                       align="left">
                                                <div
                                                    className={classes.thWrapper}>{row.name} </div>
                                            </TableCell>
                                            <TableCell className={classes.value}>
                                                <div className={classes.thWrapper}>{row.position}</div>
                                            </TableCell>
                                            <TableCell className={classes.value}>
                                                <div className={classes.thWrapper}>{row.company.label}</div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="left" data-tip={textTooltip}  data-for={row.user +'tooltip'} >
                                                <ReactTooltip place='bottom' id={row.user +'tooltip'} backgroundColor='#2d313a' html={true}/>
                                                 <div className={classes.thWrapper} >
                                                    {objects}</div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="left">
                                                <div className={classes.thWrapper}>{rights}</div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="left">
                                                <div className={classes.thWrapper}>{statusList[row.status]}</div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paginator tableObject={staffObject} getDataPagination={getDataPagination} />
            </Paper>
        </div>
    )
}

