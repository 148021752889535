import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import _ from 'lodash'

import { getSettingsWaterText, getSettingsElecText, getSettingsGasText, getSettingsHeatingText } from '../../../../../__data__/utils'

import { default as EnhancedTableHead } from './head'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    });
    return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginLeft: '32px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    thWrapper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    bold: {
        fontWeight: '600',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '6px 0 6px 16px',
    },
    paginator: {
        position: 'fixed',
        left: '60px',
        bottom: 0,
        width: 'calc(100% - 60px)',
        backgroundColor: '#f7f8fa',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 0 16px -8px rgba(0,0,0,0.25)',
        paddingLeft: '60px',
        paddingRight: '60px',
        zIndex: '1',
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
        '& .Mui-disabled': {
            opacity: 0,
        },
        '& .MuiSvgIcon-root': {
            color: '#757678',
        },
        '& .MuiSvgIcon-root:hover': {
            color: '#1e1f21',
        },
        '& .MuiTablePagination-caption': {
            fontFamily: 'Open Sans',
        },
        '& .MuiTablePagination-toolbar': {
            width: '953px',
            padding: 0,
        },
    }
}))

const labelDisplayedRows = ({ count, from, page, to }) =>  `${from}-${to} из ${count}`

export default function EnhancedTable(props) {
    const { rows, handleClick, selected } = props

    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(50)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const isSelected = (id) => id === selected

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody className={classes.tbody}>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const isItemSelected = isSelected(row.id)

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            className={classes.tr}
                                        >
                                            <TableCell className={classNames(classes.value, classes.bold)} align="left">
                                                <div className={classes.thWrapper}>{`${row.building.street} ${row.building.number}`}</div>
                                            </TableCell>
                                            <TableCell className={classes.value}>
                                                <div className={classes.thWrapper}>{`${row.submit_start_day} - ${row.submit_end_day}`}</div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="left">
                                                <div className={classes.thWrapper}>
                                                    {getSettingsWaterText(_.includes(row.reading_types, 'water_cold'), _.includes(row.reading_types, 'water_hot'))}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="right">
                                                <div className={classes.thWrapper}>
                                                    {getSettingsElecText(_.includes(row.reading_types, 'elec_night'), _.includes(row.reading_types, 'elec_day'))}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="right">
                                                <div className={classes.thWrapper}>
                                                    {getSettingsGasText(_.includes(row.reading_types, 'gas'))}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="right">
                                                <div className={classes.thWrapper}>
                                                    {getSettingsHeatingText(_.includes(row.reading_types, 'heating'))}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className={classes.paginator}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage=''
                    labelDisplayedRows={labelDisplayedRows}
                />
            </Paper>
        </div>
    )
}
