import React from 'react'
import _ from 'lodash'

import { formatDate } from '../../../../../__data__/utils'
import Images from '../image-attachments'
import FileAttachments from '../file-attachments'

import style from './initialized.css'

const Component = props => {
    const { files, images, user, message, date } = props

    const d = formatDate(date, 'D MMMM, в h:mm')

    return (
        <div className={style.container}>
            <div className={style.body}>
                <span className={style.description}>{message}</span>
                {!_.isEmpty(images) &&
                    <div className={style.images}>
                        <Images
                            imageNames={images}
                        />
                    </div>
                }
                {!_.isEmpty(files) &&
                    <div className={style.files}>
                        <FileAttachments
                            fileNameList={files}
                        />
                    </div>
                }
            </div>
            <div className={style.footer}>
                <div className={style.userName}>{`${user.last_name} ${user.first_name}`}</div>
                <div className={style.date}>{d}</div>
            </div>
        </div>
    )
}

export default Component
