import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { isValid, isDirty, getFormSyncErrors, getFormValues, getFormInitialValues } from 'redux-form'
import Scroll from 'react-scroll'
import _ from 'lodash'

import { FormHeader, FormWrapper } from './components'
import Action from './action'
import style from './style.css'

function Component(props) {
    const {
        pageTitle,
        goBackLinkText,
        goBackLinkUrl,
        children,
        formName,
        columnCount,
        buttonText,
        // Сюда передать объект из констант 'имя поля': 'дейбл поля'. Типо CONTACT_FORM_FIELDS_ERRORS.
        fieldErrors,
        isFormValid,
        syncFieldErrors,
        formValues,
        formInitialValues,
        contentTop
    } = props

    const isFormChanged = !_.isEqual(formValues, formInitialValues)

    return (
        <FormWrapper>
            <FormHeader
                isFormChanged={isFormChanged}
                goBackLinkText={goBackLinkText}
                goBackLinkUrl={goBackLinkUrl}
                pageTitle={pageTitle}
            />
            <div className={!contentTop ? style.content : style.contentTop}>
                { !isFormValid && <Scroll.Element name="fieldErrorScrollTarget" /> }
                <form className={style[`col${columnCount}`]}>
                    {children}
                </form>
            </div>
            <div className={style.actions}>
                <Action
                    isValid={isFormValid}
                    formName={formName}
                    syncFieldErrors={syncFieldErrors}
                    fieldErrors={fieldErrors}
                    buttonText={buttonText}
                    isFormChanged={isFormChanged}
                />
            </div>
        </FormWrapper>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    isFormDirty: isDirty(ownProps.formName),
    isFormValid: isValid(ownProps.formName),
    syncFieldErrors: getFormSyncErrors(ownProps.formName),
    formValues: getFormValues(ownProps.formName),
    formInitialValues: getFormInitialValues(ownProps.formName),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
