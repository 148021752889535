import _ from 'lodash'

const getWaterType = (waterCold, waterHot) => {
    if (waterCold && waterHot) return 'water_hot_and_cold'
    if (waterCold || waterHot) return 'water_common'
    return null
}

const getElecType = (elecDay, elecNight) => {
    if (elecDay && elecNight) return 'elec_day_and_night'
    if (elecDay || elecNight ) return 'elec_common'
    return null
}

export const getInitialValues = (data) => {
    if (_.isEmpty(data)) return {}
    const startDay = _.get(data, 'submit_start_day')
    const endDay = _.get(data, 'submit_end_day')
    const readingTypes = _.get(data, 'reading_types')

    const waterCold = _.includes(readingTypes, 'water_cold')
    const waterHot = _.includes(readingTypes, 'water_hot')
    const elecDay = _.includes(readingTypes, 'elec_day')
    const elecNight = _.includes(readingTypes, 'elec_night')
    const gas = _.includes(readingTypes, 'gas')
    const heating = _.includes(readingTypes, 'heating')

    const isWater = !!waterHot || !!waterCold
    const isElec = !!elecDay || !!elecNight

    return ({
        start_date: startDay || '',
        end_date: endDay || '',
        is_water: isWater,
        water_type: getWaterType(waterCold, waterHot),
        is_elec: isElec,
        elec_type: getElecType(elecDay, elecNight),
        is_gas: gas,
        is_heating: heating,
    })
}
