import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { getRepresentableTarget, formatDate } from '../../../../__data__/utils'

import style from './style.css'
import Images from './components/image-attachments'
import FileAttachments from './components/file-attachments'
import Menu from './components/context-menu'

const types = { info: "ИНФО", accident: "АВАРИЯ", report:"ОТЧЕТ", advert:"РЕКЛАМА", warn:"ВАЖНО", alert:"ВАЖНО", adv:"РЕКЛАМА" }

function Component(props) {
    // TODO: add the "avatar" field (base64) from props.author
    let author = _.get(props.author, 'name')
    const avatar = _.get(props.author, 'avatar')
    if (author === 'me') {
        author = "Вы"
    }
    const target = getRepresentableTarget(props.target)
    const targetStr = author + ' → ' + target
    const date = formatDate(props.date_edit, 'D MMMM, в h:mm')

    return (
        <div className={style.container} id={props.id}>
            <div className={style.header}>
                <div className={style.title}>{props.title}</div>
                <div className={classNames(style.newsType, style[props.type])}>{types[props.type]}</div>
            </div>
            <div className={style.description}>{props.description}</div>
            <div className={classNames(!_.isEmpty((props.file_attachments) || !_.isEmpty(props.image_attachments)) && style.attachments)}>
                {!_.isEmpty(props.file_attachments) &&
                    <div className={style.files}>
                        <FileAttachments
                            fileNameList={props.file_attachments}
                        />
                    </div>
                }
                {!_.isEmpty(props.image_attachments) &&
                    <div className={style.images}>
                        <Images
                            imageNames={props.image_attachments}
                            carouselData={{
                                title: props.title,
                                target: targetStr,
                                date: date,
                                avatar: avatar,
                            }}
                        />
                    </div>
                }
            </div>
            <div className={style.footer}>
                <div className={style.target}>{targetStr}</div>
                <div className={style.date}>{date}</div>
                <Menu newsId={props.id} checkedBuildings={props.checkedBuildings} />
            </div>
        </div>
    )
}

export default Component
