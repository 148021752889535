import React from 'react'
import { Field, isValid, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {FORM_NAMES, URL_LIST} from '../../../../__data__/constants'
import {MaskedInput, PhoneInput, TextInput} from '../../../../components/form'
import { useChatLogin } from '../../../../components/custom-hooks/use-chat-login'
import {maskedPhoneValidate, required} from '../../../../components/form/validation'

import { default as Action } from './action'

import style from './style.css'
import onSubmitForm from './submit'
import {Link} from "react-router-dom";

function Component({ handleSubmit, isFormValid, fieldErrors, dispatch }) {
    const { handleLoginRequest } = useChatLogin()

    const onSubmit = (values) => {
        handleLoginRequest(values)
        return onSubmitForm(values, dispatch, handleLoginRequest)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)
        }
    }

    return (
        <form id={FORM_NAMES.loginForm} onSubmit={handleSubmit(onSubmit)} onKeyPress={onKeyPress}>
            <div className={style.mainLogo}>
                <img src="/logo192.png" alt="logo"/>
                <h1>Кто там?</h1>
            </div>

            <div className={style.fieldsSection}>
                <Field
                    name="name"
                    component={MaskedInput}
                    type="text"
                    label="Телефон"
                    mask="+7 (999) 999-99-99"
                    placeholder="+7 (999) 999-99-99"
                    validate={[ required, maskedPhoneValidate ]}
                    size='auto'
                />
            </div>
            <div className={style.fieldsSection}>
                <Field
                    name="password"
                    component={TextInput}
                    type="password"
                    label="Пароль"
                    validate={[ required ]}
                />
            </div>
            <div>
                <Action
                    isValid={isFormValid}
                    type="submit"
                    handleSubmit={handleSubmit}
                    formName={FORM_NAMES.loginForm}
                    fieldErrors={fieldErrors}
                />
            </div>
            <Link to={URL_LIST.confirmationForm} style={{textDecoration: 'none'}} className={style.resetPassLink}>Восстановить доступ</Link>
        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.loginForm),
})

const withConnect = connect(
    mapStateToProps, null
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.loginForm,
    //onSubmit: onSubmitForm
}) (Component)

export default compose(withConnect)(Component)
