import React, { useEffect, useState } from 'react'
import { Field, isValid, reduxForm, getFormSubmitErrors } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

import { CHAT_TOKEN, FORM_NAMES, MODERATOR_STORAGE_NAME, URL_LIST } from '../../__data__/constants'
import { TextInput } from '../../components/form'
import {passwordCreateValidate, required} from '../../components/form/validation'
import { makeIsPasswordChanged } from '../../__data__/selectors/login'
import { makeProfile } from '../../__data__/selectors/profile'
import { clearIsPasswordChanged } from '../../__data__/actions/login'
import { getProfile } from '../../__data__/actions/profile'

import { default as Action } from './action'
import style from './style.css'
import onSubmitForm from './submit'
import Status from './components/status'

function Component({
                       handleSubmit,
                       isFormValid,
                       formSubmitErrors,
                       fieldErrors,
                       dispatch,
                       isPasswordChanged,
                       clearIsPasswordChanged,
                       getProfile,
                       profile,
}) {
    const queryParams = queryString.parse(window.location.search)
    const queryToken = _.get(queryParams, 'token')

    // Разлогиниваем
    useEffect(() => {
        if (queryToken) {
            localStorage.removeItem(MODERATOR_STORAGE_NAME)
            localStorage.removeItem(CHAT_TOKEN)
        }
    }, [queryToken])

    useEffect(() => {
        if (queryToken) {
            getProfile(queryToken)
        }
    }, [queryToken])

    const [isSuccessStatus, setSuccessStatus] = useState(false)

    useEffect(() => {
        if (isPasswordChanged) {
            clearIsPasswordChanged()
            setSuccessStatus(true)
        }
    }, [isPasswordChanged])

    if (!queryToken) return <Redirect to={URL_LIST.profile} />

    const onSubmit = (values) => {
        return onSubmitForm(values, queryToken, dispatch)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)
        }
    }

    if (profile?.isTokenError) {
        return (
            <div className={style.wrapper}>
                <div className={style.container}>
                    <h2>Не смогли получить сотрудника с таким токеном</h2>
                </div>
            </div>
        )
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                {!isSuccessStatus ? (
                    <form id={FORM_NAMES.registrationOfferForm} onSubmit={handleSubmit(onSubmit)} onKeyPress={onKeyPress}>
                        {profile?.firstName && (
                            <>
                                <h2>{`${profile?.firstName} ${profile?.lastName}, приглашаем тебя в ${profile?.company?.label}`}</h2>
                                <div>{`Твой номер телефона - ${profile?.phoneNumber}`}</div>
                            </>
                        )}

                        <div className={style.fieldsSection}>
                            <Field
                                name="password"
                                component={TextInput}
                                type="password"
                                label="Задайте пароль"
                                validate={[ passwordCreateValidate ]}
                                size='auto'
                            />
                        </div>
                        <div className={style.fieldsSection}>
                            <Field
                                name="passwordConfirm"
                                component={TextInput}
                                type="password"
                                label="Повторите"
                                validate={[ required ]}
                                size='auto'
                            />
                        </div>
                        <div className={style.submitError}>
                            {formSubmitErrors?.error}
                        </div>
                        <div>
                            <Action
                                isValid={isFormValid}
                                type="submit"
                                handleSubmit={handleSubmit}
                                formName={FORM_NAMES.registrationOfferForm}
                                fieldErrors={fieldErrors}
                            />
                        </div>
                    </form> ) :
                    <Status {...profile} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.registrationOfferForm),
    formSubmitErrors: getFormSubmitErrors(FORM_NAMES.registrationOfferForm),
    isPasswordChanged: makeIsPasswordChanged(),
    profile: makeProfile(),
})

const mapDispatchToProps = {
    clearIsPasswordChanged,
    getProfile,
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.registrationOfferForm,
    //onSubmit: onSubmitForm
}) (Component)

export default compose(withConnect)(Component)
