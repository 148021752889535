import moment from 'moment-with-locales-es6'

export const formatDate = (stringDate) => {
    const date = moment(stringDate)
    if (date.isBefore(moment().subtract(0, 'days'))) {
        return date.format('Вчера, h:mm')
    }
    if (date.isBefore(moment().subtract(1, 'days'))) {
        return date.format('D MMMM, h:mm')
    }
    return date.format('h:mm')
}

export const getOrder =  (columns, source, destination) => {
    const isColumnChanged = source.droppableId !== destination.droppableId
    if (isColumnChanged) {
        const beforeIndex = destination.index - 1

        const beforeOrder = columns[destination.droppableId]['items'][beforeIndex]?.content.props.order
        const afterOrder = columns[destination.droppableId]['items'][destination.index]?.content.props.order

        // Если единственнный в столбце
        if (!beforeOrder && !afterOrder) {
            return 1
        }
        // Если первый
        if (!beforeOrder) {
            const result = afterOrder - 0.001
            return result
        }
        // Если последний
        if (!afterOrder) return beforeOrder + 0.001

        return beforeOrder + (afterOrder - beforeOrder)/2
    } else {
        let beforeIndex
        let afterIndex
        // Если элемент поднялся вверх
        if (source.index > destination.index) {
            beforeIndex = destination.index - 1
            afterIndex = destination.index
        } else {
            beforeIndex = destination.index
            afterIndex = destination.index + 1
        }

        const beforeOrder = columns[destination.droppableId]['items'][beforeIndex]?.content.props.order
        const afterOrder = columns[destination.droppableId]['items'][afterIndex]?.content.props.order

        // Если первый
        if (!beforeOrder) {
            return afterOrder - 0.001
        }
        // Если последний
        if (!afterOrder) {
            return beforeOrder + 0.001
        }

        return beforeOrder + (afterOrder - beforeOrder)/2
    }
}
