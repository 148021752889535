import React from 'react';
import style from './style.css';
import classnames from 'classnames';
import {Field} from 'redux-form';
import HomeSelectInput from '../components/home-select-input';
import {required} from '../../../components/form/validation';
import FlatSelectInput from '../components/flat-select-input';
import { dictionary } from './dictionary';

export const renderUnits = ({ fields, formName }) => {
    return (
        <ul className={style.container}>
            {fields.map((unit, index) => {
                const props = fields.get(index);
                return (
                    <li key={index} className={style.inner} >
                        {fields.length > 1
                        && <button
                            type="button"
                            className={style.removeButton}
                            onClick={() => fields.remove(index)}>
                            x
                        </button>}
                        <div className={style.fields}>
                            <div className={classnames(style.fullWidth, style.field)}>
                                <Field
                                    unit={unit}
                                    name={`${unit}.homeNumber`}
                                    component={HomeSelectInput}
                                    formName={formName}
                                    label={`${dictionary[index+1]} объект`}
                                    validate={[required]}
                                    type="text"
                                />
                            </div>
                            <Field
                                name={`${unit}.flatNumber`}
                                component={FlatSelectInput}
                                disabled={!(props && props.homeNumber.id)}
                                homeNumber={props && props.homeNumber.id}
                                validate={[required]}
                                label="Квартира"
                                type="text"
                            />
                        </div>
                    </li>
                )
            })}
            {fields.length < 10 && <li>
                <button
                    className={style.addButton}
                    type="button"
                    onClick={() => fields.push()}
                >
                    + Добавить еще один
                </button>
            </li>}
        </ul>
    )}
