import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Field } from 'redux-form'
import classNames from 'classnames'
import _ from 'lodash'
import queryString from 'query-string'
import { compose } from 'redux'

import formStyle from '../../../../../components/form/style.css'
import { buildingSettingsDayRequired } from '../../../../../components/form/validation'
import { SETTINGS_WATER_LIST, SETTINGS_ELEC_LIST } from '../../../../../__data__/constants'
import { RadioGroupMenu, CheckboxToggle } from '../../../../../components/form'
import { makeBuildingName } from '../../../../../__data__/selectors/buildings'

import DateInterval from './date-interval'
import style from './style.css'

function Component(props) {
    const { buildingName, formValues } = props

    const isWater = _.get(formValues, 'isWater')
    const isElec = _.get(formValues, 'isElec')

    return (
        <Fragment>
            <div className={classNames(style.fieldsSection)}>
                <div className={formStyle.label}>В доме по адресу</div>
                <div className={style.homeNumber}>{buildingName}</div>

                <div className={style.delimiter} />
                <div className={style.datesInterval}>
                    <Field
                        name='startDate'
                        component={DateInterval}
                        validate={[ buildingSettingsDayRequired ]}
                        formName={props.formName}
                        depFieldValue={_.get(props, 'formValues.end_date')}
                    />
                    <div className={style.datesDelimiter}>–</div>
                    <Field
                        name='endDate'
                        component={DateInterval}
                        validate={[ buildingSettingsDayRequired ]}
                        formName={props.formName}
                        depFieldValue={_.get(props, 'formValues.start_date')}
                    />
                    <div className={style.datesLabel}>число каждого месяца</div>
                </div>
            </div>

            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isWater'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isWater'
                    label='Вода'
                />
                { isWater && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='waterType' items={SETTINGS_WATER_LIST} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isElec'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isElec'
                    label='Электроэнергия'
                />
                { isElec && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='elecType' items={SETTINGS_ELEC_LIST} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isGas'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isGas'
                    label='Газ'
                />
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isHeating'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isHeating'
                    label='Отопление'
                />
            </div>
        </Fragment>
    )
}

const mapStateToProps = () => {
    const queryParams = queryString.parse(window.location.search)
    const id = _.get(queryParams, 'id')

    return createStructuredSelector({
        buildingName: makeBuildingName(id),
    })
}

const withConnect = connect(
    mapStateToProps,
    null,
)

export default compose(withConnect)(Component)
