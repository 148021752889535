import { createSelector } from 'reselect'
import _ from 'lodash'

import { getNavigationBuildingString } from '../utils'

const app = state => _.get(state, 'app', {})

export const makeNavigationBuildingsList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')
        if (!buildings) return null

        return _.reduce(buildings, (result, item) => {
            if (!item.complex) {
                if (!result['noComplexId']) {
                    result['noComplexId'] = {
                        name: 'Без комплекса',
                        items: [],
                    }
                }
            } else if (!result[`${item.complex.id}`]) {
                result[`${item.complex.id}`] = {
                    name: item.complex.name,
                    items: [],
                }
            }

            if (!item.complex) { result['noComplexId']['items'].push({ value: `${item.id}`, label: getNavigationBuildingString(item) }) }
            else result[`${item.complex.id}`]['items'].push({ value: `${item.id}`, label: getNavigationBuildingString(item) })

            return result
        }, {})
    })
