import React, { Fragment } from 'react'
import { Field } from 'redux-form'

import formStyle from '../../../../../../theme/form.css'
import { PhoneInput, RadioGroupMenu, TextInput } from '../../../../../../components/form'
import {
    nameValidate,
    phoneValidate,
    positionValidate,
    surnameValidate,
    sexValidation,
    organisationValidation,
} from '../../../../../../components/form/validation'
import { SEX_ITEMS } from '../../../../../../__data__/constants'
import { SelectInput } from '../../../../../../components/form'

function Component (props) {
    const { organisationList } = props

    return (
        <Fragment>
            <div className={formStyle.groupSection}>
                <Field
                    name="firstName"
                    component={TextInput}
                    type="text"
                    label="Имя"
                    value=""
                    placeholder=""
                    validate={[nameValidate]}
                />
                <Field
                    name="lastName"
                    component={TextInput}
                    type="text"
                    label="Фамилия"
                    value=""
                    placeholder=""
                    validate={[surnameValidate]}
                />
                <RadioGroupMenu name='gender' items={SEX_ITEMS} validate={[sexValidation]} />
            </div>
            <div className={formStyle.groupSection}>
                <Field
                    name="position"
                    component={TextInput}
                    type="text"
                    label="Должность"
                    value=""
                    placeholder=""
                    validate={[positionValidate]}
                />
                <Field
                    name="company"
                    component={SelectInput}
                    label="Организация"
                    size="lg"
                    list={organisationList}
                    validate={[organisationValidation]}
                />
            </div>
            <div className={formStyle.groupSection}>
                <Field
                    name="phoneNumber"
                    component={PhoneInput}
                    type="text"
                    label="Телефон"
                    value=""
                    placeholder="+7 ()"
                    validate={[phoneValidate]}
                />
            </div>
        </Fragment>
    )
}

export default Component
