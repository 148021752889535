import _ from 'lodash'

import { editCamera }  from '../../__data__/actions/cameras'

function submit(values, dispatch) {
    const id = values.id
    const name = values.name
    const link = values.link
    const buildings = _.map(values.accessibleBuildings, i => i.id)
    const data = {name, link, buildings}
    dispatch(editCamera(id, data))
}

export default submit
