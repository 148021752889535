import React from 'react'

import style from './style.css'

function Component() {
    return (
        <div className={style.container}>
            <div>
                <div className={style.imgWrapper}>
                    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M58.7003 0C42.2639 5.34188 12.2073 26.6193 0.0129458 44.1931L0.0125202 44.1934C-0.4184 61.6052 10.3358 97.5502 22.7517 114.964C38.6759 121.138 77.7857 122.024 98.0268 115.441C108.264 101.414 120.29 64.6943 119.995 43.2473C109.732 29.2405 79.1049 6.05156 58.7003 0ZM59.0479 9.08086C37.2217 18.9259 19.8084 33.4228 9.06849 46.9964L9.06827 46.9963C11.0697 71.0261 19.4168 92.6202 28.5701 107.329C51.8645 112.771 75.7069 111.293 92.395 107.013C104.393 86.1548 110.468 63.4947 111.312 46.1499C95.0981 28.4271 75.2296 15.0189 59.0479 9.08086Z" fill="#FEEA99"/>
                        <path d="M9.06858 46.9965C19.8085 33.4228 37.2218 18.9259 59.048 9.08087C75.2297 15.0189 95.0982 28.4271 111.312 46.1499C110.468 63.4947 104.393 86.1548 92.3951 107.013C75.707 111.293 51.8646 112.771 28.5702 107.329C19.4169 92.6202 11.0698 71.0261 9.06836 46.9963L9.06858 46.9965Z" fill="#FBBF20"/>
                        <path d="M41.2656 75H47.5156V71.6562H50.2031V66.5781H47.5156V52.4531H38.3594C34.4375 58.4375 32.1875 62.2344 30.1719 66.1719V71.6562H41.2656V75ZM35.6719 66.6562C37.5312 62.7188 39.0312 60.3281 41.3281 56.7969H41.4531V66.8438H35.6719V66.6562Z" fill="black"/>
                        <path d="M62.2031 75.625C68.2969 75.625 72.125 71.0938 72.125 63.6562C72.125 56.1562 68.25 51.8125 62.2031 51.8125C56.1562 51.8125 52.2656 56.1719 52.2656 63.6719C52.2656 71.125 56.1094 75.625 62.2031 75.625ZM62.2031 70.5312C60.3594 70.5312 59.0156 68.3125 59.0156 63.6719C59.0156 59.0156 60.3594 56.9062 62.2031 56.9062C64.0469 56.9062 65.375 59.0156 65.375 63.6719C65.375 68.3125 64.0469 70.5312 62.2031 70.5312Z" fill="black"/>
                        <path d="M80.2031 75H86.8281V52.4531H80.2031L74.5469 56.2344V61.7812L80.0781 58.125H80.2031V75Z" fill="black"/>
                    </svg>
                </div>
                <div className={style.text}>{'Для вас доступ закрыт'}</div>
            </div>
        </div>
    )
}

export default Component
