import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, reduxForm, getFormValues, isValid, getFormSyncErrors } from 'redux-form'
import _ from 'lodash'

import { getToken } from '../../__data__/utils'
import formStyle from '../../theme/form.css'
import {
    homeNumberRequired,
    flatNumberRequired,
} from '../../components/form/validation'
import {
    MODERATOR_STORAGE_NAME,
    FORM_NAMES,
    URL_LIST, METERS_FORM_FIELDS_ERRORS,
} from '../../__data__/constants'
import { FormConstructor } from '../../components/form'

import HomeSelectInput from './home-select-input'
import FlatSelectInput from './flat-select-input'
import MetersFields from './meters-fields'
import submit from './submit'
import {getMetersLastMonth} from "../../__data__/actions/readings";

function Component(props) {
    const token = getToken(MODERATOR_STORAGE_NAME)
    const homeNumber = _.get(props, 'formValues.home_number.id')
    const getMetersLastMonth = _.get(props, 'getMetersLastMonth')
    const flatId = _.get(props, 'formValues.flat_number.id')
    const flatNumber = _.get(props, 'formValues.flat_number.label')

    useEffect(() => {
        props.initialize({})
    }, [token])

    useEffect(() => {
        if (flatNumber) {
            getMetersLastMonth(flatId)
        }
    }, [flatId])


    return (
        <FormConstructor
            pageTitle='Для какого объекта передаёте показания?'
            goBackLinkText='Вернуться к показаниям'
            goBackLinkUrl={URL_LIST.meters}
            columnCount='4'
            buttonText='Внести показания'
            formName={props.formName}
            onSubmit={submit}
            fieldErrors={METERS_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    <Field
                        name='home_number'
                        component={HomeSelectInput}
                        label='Объект'
                        validate={[ homeNumberRequired ]}
                        defaultOpen={true}
                        formName={props.formName}
                    />
                    { homeNumber &&
                        <Field
                            name='flat_number'
                            homeNumber={homeNumber}
                            component={FlatSelectInput}
                            label='Квартира'
                            validate={[flatNumberRequired]}
                            formName={props.formName}
                        />
                    }
                </div>
                { flatNumber && <MetersFields homeNumber={homeNumber} formName={props.formName} /> }
            </Fragment>
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    isFormValid: isValid(ownProps.formName),
    fieldErrors: getFormSyncErrors(ownProps.formName),
})

const mapDispatchToProps = {
    getMetersLastMonth,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

Component = reduxForm({
    form: FORM_NAMES.createMeters,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
