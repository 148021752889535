import React from 'react'

export default function Gas() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6172 20.2109C15.8203 20.2109 18.6328 17.375 18.6328 13.1094C18.6328 6.04688 12.5938 3.09375 8.42188 3.09375C7.625 3.09375 7.10156 3.39062 7.10156 3.96094C7.10156 4.17969 7.20312 4.41406 7.375 4.61719C8.33594 5.77344 9.23438 7.03906 9.24219 8.53906C9.24219 8.69531 9.23438 8.83594 9.20312 8.99219C8.74219 8.03125 7.97656 7.46875 7.28125 7.46875C6.91406 7.46875 6.66406 7.72656 6.66406 8.13281C6.66406 8.36719 6.71875 8.80469 6.71875 9.1875C6.71875 10.9766 5.35938 11.9062 5.35938 14.7812C5.35938 18.0391 7.85156 20.2109 11.6172 20.2109ZM11.7031 18.8984C8.69531 18.8984 6.67969 17.2422 6.67969 14.7812C6.67969 12.3125 8.02344 11.5234 8.01562 9.3125C8.01562 9.25 8.01562 9.19531 8.00781 9.13281C8.42188 9.59375 8.71875 10.2344 8.89844 11.0391C8.9375 11.2578 9.05469 11.3594 9.22656 11.3594C9.9375 11.3594 10.4141 9.74219 10.4141 8.625C10.4141 7.04688 9.8125 5.51562 8.8125 4.375C13.9609 4.79688 17.2578 8.34375 17.2578 13.0625C17.2578 16.5547 15.0312 18.8984 11.7031 18.8984ZM11.8125 17.8047C13.6172 17.8047 14.5391 16.5 14.5391 14.9531C14.5391 13.3984 13.6484 11.7422 11.9688 10.9688C11.875 10.9297 11.7969 10.9844 11.8203 11.0938C11.9531 12.3047 11.8047 13.4219 11.3984 14.0234C11.2188 13.5547 10.9922 13.1641 10.6484 12.8594C10.5703 12.7891 10.4922 12.8281 10.4844 12.9219C10.3594 13.8438 9.41406 14.3047 9.41406 15.6484C9.41406 16.9375 10.3672 17.8047 11.8125 17.8047Z" fill="#4BD368"/>
    </svg>
}



