import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { VALUE_SETTING_NEWS, VALUE_SETTING_TASKS, VALUE_SETTING_CHATS, VALUE_SETTING_RESIDENTS, VALUE_SETTING_READINGS } from '../../constants'
import Menu from '../context-menu'
import {PROFILE_USER_ID, URL_LIST} from '../../../../../../__data__/constants'
import { declOfNum } from '../../../../../../__data__/utils'

import style from './style.css'
import {
    checkPermissionForSection
} from "../../../../../../__data__/actions/permissions";
import LocalStorageUtil from "../../../../../chat-new/utils/local-storage";

// TODO: Исправить орфографию

export default function StaffCard(props) {
    const {
        name,
        position,
        listBuldingsAddres,
        phone,
        handleClick,
        sections,
        avatar,
        userChatId
    } = props

    const handleClose = e => {
        e.preventDefault()
        handleClick(null)
    }

    const isSticky = useScrollYPosition() > 85

    const lengthBuldings = listBuldingsAddres.length

    const txtAddres = (lengthBuldings + ' ' + declOfNum(lengthBuldings, ['объект', 'объекта', 'объектов']))
        + (lengthBuldings > 0 ? declOfNum(lengthBuldings, [' по адресу:', ' по адресам:', ' по адресам:']) : '.')

    return (
        <div className={classNames(style.container, isSticky && style.containerSticky)}>
            <div className={style.header}>
                <div className={style.cardNameWrapper}>
                    <div className={style.avatar}>
                        <img
                            className={style.img}
                            src={avatar}
                            alt="avatar"
                        />
                    </div>
                    <div className={style.nameWrapper}>
                        <div className={style.firstNameWrapper}>
                            <div className={style.firstName}>{name}</div>
                        </div>
                        <div className={style.position}>{position}</div>
                    </div>

                    <a href='/' onClick={handleClose} className={style.closeButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                            <g fill="none" fillRule="evenodd">
                                <path fill="#F7F8FA" d="M0 0H1440V900H0z" transform="translate(-897 -143)"/>
                                <g>
                                    <path fill="#D8D8D8" fillOpacity="0" d="M0 0H46V46H0z"
                                          transform="translate(-897 -143) translate(880 126)"/>
                                    <g>
                                        <path d="M0 0L24 0 24 24 0 24z"
                                              transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path d="M0 0L24 0 24 24 0 24z"
                                              transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path fill="#7D8B8D"
                                              d="M12 13.414l-3.542 3.542c-.387.387-1.015.387-1.408-.006-.39-.39-.388-1.026-.006-1.408L10.586 12 7.044 8.458c-.387-.387-.387-1.015.006-1.408.39-.39 1.026-.388 1.408-.006L12 10.586l3.542-3.542c.387-.387 1.015-.387 1.408.006.39.39.388 1.026.006 1.408L13.414 12l3.542 3.542c.387.387.387 1.015-.006 1.408-.39.39-1.026.388-1.408.006L12 13.414z"
                                              transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path d="M0 0L24 0 24 24 0 24z"
                                              transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <div className={style.editLinkWrapper}>
                    <Link className={classNames(style.editLink, (!checkPermissionForSection('Общение') || LocalStorageUtil.getObject(PROFILE_USER_ID) === props.user) && style.disabled)}
                          to={`${URL_LIST.chats}?id=${userChatId}`}>Написать сообщение</Link>
                    <Menu status={props.status} user={props.user} checkedBuildings={props.checkedBuildings} />
                </div>
            </div>

            <div className={style.fields}>
                <div className={style.horizontalOffsets}>
                    <div className={style.field}>
                        <div className={style.fieldLabel}>Тел.</div>
                        <div className={style.fieldValue}>{phone}</div>
                    </div>
                </div>
                <div className={style.fieldDelimiter}/>
                <div className={style.fieldRowHeader}>
                    {txtAddres}
                </div>
                <div className={style.fieldRowValue}>{listBuldingsAddres.join(', ')}</div>
                <div className={style.fieldDelimiter}/>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Новости</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_NEWS.filter(right => right.value === sections?.news)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Заявки</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_TASKS.filter(right => right.value === sections?.tasks)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Общение</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_CHATS.filter(right => right.value === sections?.chats)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Жильцы</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_RESIDENTS.filter(right => right.value === sections?.residents)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Показания</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_READINGS.filter(right => right.value === sections?.readings)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Вокруг</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_READINGS.filter(right => right.value === sections?.cameras)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Сотрудники</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_READINGS.filter(right => right.value === sections?.employees)[0]?.label}</div>
                </div>
                <div className={style.fieldRow}>
                    <div className={style.fieldRowLabel}>Объекты</div>
                    <div className={style.fieldRowValue}>{VALUE_SETTING_READINGS.filter(right => right.value === sections?.buildings)[0]?.label}</div>
                </div>
            </div>
        </div>
    )
}
