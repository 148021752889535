import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import style from './style.css'
import { checkPermissionForSideMenu } from "../../../../../../__data__/actions/permissions";

function Component(props) {
    const { url, children, dataTip, dataFor, active } = props
    const isAvailable = checkPermissionForSideMenu(url.replace('/', ''))

    if (isAvailable) {
        return (
            <Link
                className={classNames(style.item, active && style.active)}
                to={url}
                data-tip={dataTip}
                data-for={dataFor}
            >
                {children}
            </Link>
        )
    } else {
        return null
    }
}

export default Component
