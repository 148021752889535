import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import queryString from 'query-string'

import { getBuildingPassport } from '../../actions/actions'
import { makeCheckedBuildings } from '../../../../__data__/selectors/common'
import { makeCompanyInformation } from '../../selectors/company-selectors'

import style from './style.css'

function Component(props) {
    const { getBuildingPassport } = props

    const queryParams = queryString.parse(window.location.search)
    const id = _.get(queryParams, 'id')

    useEffect(() => {
        id && getBuildingPassport(id)
    }, [id])

    return (
        <div className={style.container}>
            Passport
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    companyInformation: makeCompanyInformation(),
})

const mapDispatchToProps = {
    getBuildingPassport,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
