// Ensure URL is suitable for <img src="url"> field: the URL must be a relative URL or
// have http:, https:, or data: scheme. In case of data: scheme, the URL must start with
// a 'data:image/XXXX;base64,'.
export function sanitizeUrl(url, allowedSchemes) {
    if (!url) {
        return null;
    }

    // Strip control characters and whitespace. They are not valid URL characters anyway.
    url = url.replace(/[^\x20-\x7E]/gmi, '').trim();

    // Relative URLs are safe.
    // Relative URL does not start with ':', abcd123: or '//'.
    if (!/^([a-z][a-z0-9+.-]*:|\/\/)/i.test(url)) {
        return url;
    }

    // Blob URLs are safe.
    if (/^blob:http/.test(url)) {
        return url;
    }

    // Absolute URL. Accept only safe schemes, or no scheme.
    const schemes = Array.isArray(allowedSchemes) ? allowedSchemes.join('|') : 'http|https';
    const re = new RegExp('^((' + schemes + '):|//)', 'i');
    if (!re.test(url)) {
        return null;
    }

    return url;
}

export function sanitizeImageUrl(url) {
    if (!url) {
        return null;
    }

    const sanitizedUrl = sanitizeUrl(url);
    if (sanitizedUrl) {
        return sanitizedUrl;
    }

    // Is this a data: URL of an image?
    if (/data:image\/[a-z0-9.-]+;base64,/i.test(url.trim())) {
        return url;
    }

    return null;
}
