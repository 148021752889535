import axios from 'axios'

import * as types from '../actions-types'
import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchGetResidentsList = () => ({ type: types.GET_RESIDENTS_LIST })
export const fetchGetResidentsListSuccess = (data) => ({ type: types.GET_RESIDENTS_LIST_SUCCESS, data })
export const fetchGetResidentsListFail = (error) => ({ type: types.GET_RESIDENTS_LIST_FAIL, error })

export const fetchCreateResident = () => ({ type: types.CREATE_RESIDENT })
export const fetchCreateResidentSuccess = (data) => ({ type: types.CREATE_RESIDENT_SUCCESS, data })
export const fetchCreateResidentFail = (error) => ({ type: types.CREATE_RESIDENT_FAIL, error })

export const fetchEditResident = () => ({ type: types.EDIT_RESIDENT })
export const fetchEditResidentSuccess = (data) => ({ type: types.EDIT_RESIDENT_SUCCESS, data })
export const fetchEditResidentFail = (error) => ({ type: types.EDIT_RESIDENT_FAIL, error })

export const fetchGetResident = () => ({ type: types.GET_RESIDENT })
export const fetchGetResidentSuccess = (data) => ({ type: types.GET_RESIDENT_SUCCESS, data })
export const fetchGetResidentFail = (error) => ({ type: types.GET_RESIDENT_FAIL, error })

export const fetchsetResidentsFilters = (data) => ({ type: types.SET_RESIDENTS_FILTER, data })

export const fetchGetFilteredEntry = () => ({ type: types.GET_FILTERED_ENTRY })
export const fetchGetFilteredEntrySuccess = (data) => ({ type: types.GET_FILTERED_ENTRY_SUCCESS, data })
export const fetchGetFilteredEntryFail = (error) => ({ type: types.GET_FILTERED_ENTRY_FAIL, error })

export const fetchGetUnits = () => ({ type: types.GET_UNITS })
export const fetchGetUnitsSuccess = (data) => ({ type: types.GET_UNITS_SUCCESS, data })
export const fetchGetUnitsFail = (error) => ({ type: types.GET_UNITS_FAIL, error })

export const fetchConvertResidents = () => ({ type: types.CONVERT_RESIDENTS })
export const fetchConvertResidentsSuccess = (data) => ({ type: types.CONVERT_RESIDENTS_SUCCESS, data })
export const fetchConvertResidentsFail = (error) => ({ type: types.CONVERT_RESIDENTS_FAIL, error })

export const fetchImportResidents = () => ({ type: types.IMPORT_RESIDENTS })
export const fetchImportResidentsSuccess = (data) => ({ type: types.IMPORT_RESIDENTS_SUCCESS, data })
export const fetchImportResidentsFail = (error) => ({ type: types.IMPORT_RESIDENTS_FAIL, error })

let cancelToken

export const getResidentsList = (
    filters,
    page=1,
    countPerPage=10,
) => dispatch => {
    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    dispatch(fetchGetResidentsList())
    const { buildingsId, searchString, floors, porches, pipes } = filters

    let params = `page=${page}&page_size=${countPerPage}`
    params = buildingsId ? `${params}&buildings_id=${buildingsId}` : params
    params = searchString ? `${params}&search=${searchString}` : params
    params = floors ? `${params}&floors_id=${floors}` : params
    params = porches ? `${params}&porches_id=${porches}` : params
    params = pipes ? `${params}&pipes_id=${pipes}` : params
    return axios({
        method: 'GET',
        cancelToken: cancelToken.token,
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/residents/?${params}`,
    })
        .then(response => {
            dispatch(fetchGetResidentsListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetResidentsListFail(error))
        })
}

export const createResident = data => dispatch => {
    dispatch(fetchCreateResident())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data,
        url: '/api/crm/residents/',
    })
        .then(response => {
            dispatch(fetchCreateResidentSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchCreateResidentFail(error))
        })
}

export const editResident = data => dispatch => {
    dispatch(fetchEditResident())

    return axios({
        method: 'PUT',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data: data.rsData,
        url: `/api/crm/residents/${data.id}/`,
    })
        .then(response => {
            dispatch(fetchEditResidentSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchEditResidentFail(error))
        })
}

export const getResident = id => dispatch => {
    dispatch(fetchGetResident())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/residents/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetResidentSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetResidentFail(error))
        })
}

export const setResidentsFilters = data => dispatch => {
    dispatch(fetchsetResidentsFilters(data))
}

export const getFilteredEntry = data => dispatch => {
    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    dispatch(fetchGetFilteredEntry())

    return axios({
        method: 'POST',
        cancelToken: cancelToken.token,
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/crm/search/smart_search/`,
    })
        .then(response => {
            dispatch(fetchGetFilteredEntrySuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetFilteredEntryFail(error))
        })
}

export const getUnits = params => dispatch => {
    dispatch(fetchGetUnits())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/search/smart_search_by_tags/?${params}&page_size=100`,
    })
        .then(response => {
            dispatch(fetchGetUnitsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetUnitsFail(error))
        })
}

export const convertResidents = data => dispatch => {
    dispatch(fetchConvertResidents())
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('building', data.building)

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
        url: `/api/crm/import/convert_residents/`,
    })
        .then(response => {
            dispatch(fetchConvertResidentsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchConvertResidentsFail(error))
        })
}

export const importResidents = data => dispatch => {
    dispatch(fetchImportResidents())
    const formData = new FormData()
    formData.append('convert_residents_result', JSON.stringify(data))

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
        url: `/api/crm/import/residents/`,
    })
        .then(response => {
            dispatch(fetchImportResidentsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchImportResidentsFail(error))
        })
}
