import React from 'react'

export default function Rectangle() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.8916 20.4268H17.5352C19.4424 20.4268 20.4268 19.4424 20.4268 17.5615V6.86523C20.4268 4.98438 19.4424 4 17.5352 4H6.8916C4.99316 4 4 4.97559 4 6.86523V17.5615C4 19.4424 4.99316 20.4268 6.8916 20.4268ZM7.00586 18.6777C6.19727 18.6777 5.74902 18.2559 5.74902 17.4033V7.02344C5.74902 6.1709 6.19727 5.74902 7.00586 5.74902H17.4209C18.2207 5.74902 18.6777 6.1709 18.6777 7.02344V17.4033C18.6777 18.2559 18.2207 18.6777 17.4209 18.6777H7.00586Z" fill="#8A9CAD"/>
    </svg>

}



