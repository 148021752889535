import React from 'react'
import { connect } from 'react-redux'

import style from './style.css'

function Component(props) {
    if (!props.isValid) {
        return (
            <div className={style.invalidAction}>Введите телефон</div>
        )
    }

    return (
        <div className={style.wrapper}>
            <button
                className={style.primary}
                type={props.type}
                disabled={!props.isValid}
            >Отправить код подтверждения</button>
        </div>
    )
}

export default connect()(Component)
