import React from 'react'

import style from './style.css'
import Image from './loader.svg'

const Component = (props) => (
    <div className={style.container}>
        <div className={style.loaderWrapper}>
            <img className={style.image} src={Image} alt="" />
            <div className={style.text}>{props.text}</div>
        </div>
    </div>
)

export default Component
