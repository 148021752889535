import React from 'react'
import { Link } from 'react-router-dom'

import style from './item.css'

function Component(props) {
    const { children, href } = props

    return (
        <Link to={href} className={style.container}>{children}</Link>
    )
}

export default Component
