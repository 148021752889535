import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { getComparator, stableSort } from '../../../../../../components/table/utils'

import { default as EnhancedTableHead } from './head'
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginLeft: '32px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    thWrapper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    disabledTr: {
        pointerEvents: 'none',
        opacity: '0.5',
    },
    nameValue: {
        fontWeight: '600',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '6px 0 6px 16px',
    },
    paginator: {
        position: 'fixed',
        left: '60px',
        bottom: 0,
        width: 'calc(100% - 60px)',
        backgroundColor: '#f7f8fa',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 0 16px -8px rgba(0,0,0,0.25)',
        paddingLeft: '60px',
        paddingRight: '60px',
        zIndex: '1',
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
        '& .Mui-disabled': {
            opacity: 0,
        },
        '& .MuiSvgIcon-root': {
            color: '#757678',
        },
        '& .MuiSvgIcon-root:hover': {
            color: '#1e1f21',
        },
        '& .MuiTablePagination-caption': {
            fontFamily: 'Open Sans',
        },
        '& .MuiTablePagination-toolbar': {
            width: '953px',
            padding: 0,
        },
    }
}))

const labelDisplayedRows = ({count, from, page, to}) => `${from}-${to} из ${count}`

function hasBuildingIdInAvailableList(availableBuildingsList, buildingId) {
    return Object.values(availableBuildingsList).some(item => {
        if (item.items && item.items.length > 0) {
            return item.items.some(subItem => String(subItem.value) === String(buildingId));
        }
        return false;
    });
}

export default function ObjectTable(props) {
    const { handleClick, selected, isAllBuildings, buildingsObject, getBuildingAddress, availableBuildingsList } = props

    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(50)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const getAllRowObjects = () => {
        let arrRowAll = []

            const newArr = stableSort(buildingsObject.results, getComparator(order, orderBy))
                .map((row) => {
                    const isItemSelected = isSelected(row.id)
                    return (
                        <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className={classNames(classes.tr, !hasBuildingIdInAvailableList(availableBuildingsList, row.id) && classes.disabledTr)}
                        >
                            <TableCell className={classes.value}>
                                <div className={classes.thWrapper}>{getBuildingAddress({street: row.street, number: row.number})}</div>
                            </TableCell>
                            <TableCell className={classes.value}>
                                <div className={classes.thWrapper}>{row.complex?.name || 'Без комплекса'}</div>
                            </TableCell>
                            <TableCell className={classes.value}>
                                <div className={classes.thWrapper}>{row.tenant.label}</div>
                            </TableCell>
                            <TableCell className={classes.value}>
                                <div className={classes.thWrapper}>{"МКД"}</div>
                            </TableCell>
                        </TableRow>
                    )
                })

            arrRowAll = arrRowAll.concat(newArr)
        return arrRowAll
    }

    const isSelected = (id) => id === selected

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            isAllBuildings={isAllBuildings}
                        />
                        <TableBody className={classes.tbody}>
                            {getAllRowObjects()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className={classes.paginator}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={buildingsObject.results.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage=''
                    labelDisplayedRows={labelDisplayedRows}
                />
            </Paper>
        </div>
    )
}
