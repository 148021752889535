import React from 'react'
import _ from 'lodash'

import style from './style.css'
import RadioButton from './radio-button'

function Component(props) {

    return (
        <div className={style.container}>
            {_.map(props.items, i => (
                <RadioButton
                    key={i.label + "_" + i.value}
                    name={props.name}
                    label={i.label}
                    value={i.value}
                    status={i.status}
                    checked={i.checked}
                    changePage={props.changePage}
                />
            ))}
        </div>
    )
}

export default Component
