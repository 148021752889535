import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeCameraList = () =>
    createSelector(app, slice => _.get(slice, 'cameraList'))


export const makeIsCameraListChanged = () =>
    createSelector(app, slice => _.get(slice, 'isCameraListChanged'))
