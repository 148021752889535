import { SubmissionError } from 'redux-form'
import { sendAccountConfirmationCode }  from '../../../__data__/actions/login'
import { passwordConfirmValidationMessages } from '../../../components/form/validation'

function onSubmitForm(values, username, dispatch) {
    return dispatch(sendAccountConfirmationCode({...values, username}))
        .catch((error) => {
        throw new SubmissionError(passwordConfirmValidationMessages[error?.message] || ' ')
    })
}

export default onSubmitForm
