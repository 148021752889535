import React from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { deleteResident } from "../../actions/resident-actions";

function Component(props) {
    const { children, userID, deleteEmployee } = props

    let buttonPressTimer

    const handleButtonPress = e => {
        e.preventDefault()
        buttonPressTimer = setTimeout(() => deleteEmployee(userID), 1500)
    }

    const handleButtonRelease = e => {
        e.preventDefault()
        clearTimeout(buttonPressTimer)
    }

    return (
        <div
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
        >
            {children}
        </div>
    )
}

const mapDispatchToProps = {
    deleteEmployee: deleteResident,
}

const withConnect = connect(
    null,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)

