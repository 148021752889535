import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import style from './style.css'
import { Link } from 'react-router-dom'
import { checkPermissionForSection } from "../../__data__/actions/permissions";

function Component(props) {
    const { items } = props

    const pathName = window.location.pathname

    return (
        <ul className={style.container}>
            {_.map(items, i => (
                checkPermissionForSection(i.title) ? (
                    <li key={i.id} className={classNames(style.item, pathName.indexOf(i.url) === 0 && style.active)}
                        data-value={i.url}>
                        <Link className={style.link} to={i.url}>{i.title}</Link>
                    </li>
                ) : <li key={i.id} className={classNames(style.item)}
                        data-value={i.url}>
                    <Link to={undefined} className={style.disabledLink}>{i.title}</Link>
                </li>
            ))}
        </ul>
    )
}

export default Component
