import React from 'react'
import classNames  from 'classnames'
import PropTypes from "prop-types"
import DropdownList from 'react-widgets/lib/DropdownList'

import { FieldErrorMessage } from '../../../components/form'
import Label from "../../../components/form/label"

import style from './style.css'

const messages = {
    noneSelected: 'Нет результатов',
    emptyFilter: 'Нет результатов',
    emptyList: 'Нет результатов'
}

const ListItem = ({ item }) => (
    <div className={style.item}>
        <div className={style.itemPrev}>F</div>
        <div className={style.itemText}>{item.label}</div>
    </div>
)

const inputProps = {spellcheck: "false"}

let ValueInput = ({ item }) => item ? (
    <div className={style.item}>
        <div className={style.itemPrev}>F</div>
        <div className={style.itemText}>{item.label}</div>
    </div>
) : ''

function  Component(props) {
    const { input, meta, flatsList, size, disabled } = props
    const isInvalid = (meta.invalid && meta.visited && !meta.active) || (meta.invalid && meta.touched)

    return (
        <div>
            <div className={style.container}>
                <DropdownList
                    {...input}
                    textField='label'
                    itemComponent={ListItem}
                    data={flatsList}
                    disabled={disabled}
                    className={classNames(
                        style[`size-${size}`],
                        isInvalid && style.invalid,
                    )}
                    inputProps={inputProps}
                    minLength={1}
                    filter='contains'
                    placeholder="Выбрать"
                    messages={messages}
                    valueComponent={ValueInput}
                />
                <Label>{props.label}</Label>
            </div>
            <FieldErrorMessage meta={props.meta} />
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
}

Component.defaultProps = {
    size: 'md',
    disabled: false
}

export default Component
