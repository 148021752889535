import _ from 'lodash'

import { editEmployee } from '../../../actions/staff-actions'
import { isUserAdmin } from '../../../../../__data__/utils'

const getData = (values) => {
    // TODO: Думаю, стоит переделать на reduce. Но лень
    const porches = []
    _.forEach(values.sortedPorches, building => {
        if (building.hasAccess) {
            _.forEach(building.porches, (porchValue, id) => {
                if (porchValue) porches.push(+id)
            })
        }
    })

    const services = _.map(_.get(values, 'services'), i => i.id)

    const data = {
        first_name: _.get(values, 'firstName'),
        last_name: _.get(values, 'lastName'),
        gender: _.get(values, 'gender'),
        position: _.get(values, 'position'),
        company: _.get(values, 'company.id'),
        phone_number: _.get(values, 'phoneNumber'),
        sections: {
            news: _.get(values, 'news'),
            tasks: _.get(values, 'tasks'),
            chats: _.get(values, 'chats'),
            residents: _.get(values, 'residents'),
            readings: _.get(values, 'readings'),
            cameras: _.get(values, 'cameras'),
            employees: _.get(values, 'employees'),
            buildings: _.get(values, 'buildingsSettings'),
        },
        porches,
    }

    if (isUserAdmin) data['services'] = services

    return data
}

function submit(values, dispatch) {
    const data = getData(values)
    dispatch(editEmployee(data, values.user))
}

export default submit
