import React, { useState, useEffect } from 'react'
import { Field, isValid, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { FORM_NAMES } from '../../../__data__/constants'
import { MaskedInput } from '../../../components/form'
import { sendLoggedSms, setLoggedConfirmationCode } from '../../../__data__/actions/profile'
import { makePasswordGeneration, makeFillSmsPasswordGeneration } from '../../../__data__/selectors/login'

import Timer from './components/timer'
import { default as Action } from './action'
import style from './style.css'
import onSubmitForm from './submit'

function Component({
   sendSmsPassGeneration,
   fillSmsPasswordGeneration,
   handleSubmit,
   fieldErrors,
   dispatch,
   sendLoggedSms,
   setLoggedConfirmationCode,
   setTransportToken,
}) {
    const { requestCooldown, userName } = sendSmsPassGeneration

    useEffect(() => {
        sendLoggedSms()
    }, [])

    useEffect(() => {
        if (fillSmsPasswordGeneration.isRSFinished && !fillSmsPasswordGeneration.isError) {
            setTransportToken(fillSmsPasswordGeneration.token)
        }
    }, [fillSmsPasswordGeneration?.isRSFinished, fillSmsPasswordGeneration?.isError])

    const onSubmit = (values) => {
        return onSubmitForm(values, userName, dispatch)
    }

    const handleChange = (input) => {
        const value = input.currentTarget.value.replaceAll('_', '').replace('-', '')

        if (value.length === 6) {
            setLoggedConfirmationCode({confirm_code: value})
        }
    }

    const [isEnabled, setEnabled] = useState(false)

    const handleTimerFinish = () => {
        setEnabled(true)
    }

    return (
        <form id={FORM_NAMES.confirmationCodeForm} onSubmit={handleSubmit(onSubmit)}>
            <h2>Восстановление доступа</h2>
            {sendSmsPassGeneration?.isError &&
                <div className={style.errorMessage}>
                    {sendSmsPassGeneration.errorCode === 403 && <div>Превышен лимит попыток, необходимо подождать</div>}
                    {sendSmsPassGeneration.errorCode === 404 && <div>Неверный код смс</div>}
                </div>
            }
            {fillSmsPasswordGeneration?.isError &&
                <div className={style.errorMessage}>
                    {fillSmsPasswordGeneration.errorCode === 403 && <div>Превышен лимит попыток, необходимо подождать</div>}
                    {fillSmsPasswordGeneration.errorCode === 404 && <div>Введён неверный код из СМС</div>}
                </div>
            }
            <div className={style.fieldsSection}>
                <Field
                    name="confirm_code"
                    component={MaskedInput}
                    type="text"
                    label="Введите код подтверждения"
                    size='auto'
                    onChange={handleChange}
                    mask="999-999"
                    placeholder="___-___"
                />
            </div>
            <div>
                <Action
                    isValid={isEnabled}
                    type="submit"
                    // handleSubmit={handleSubmit}
                    formName={FORM_NAMES.confirmationCodeForm}
                    fieldErrors={fieldErrors}
                    isDisabled={!isEnabled}
                />
            </div>
            { requestCooldown ? <Timer interval={requestCooldown} onFinish={handleTimerFinish} /> : null }
        </form>
    )
}

const mapStateToProps = createStructuredSelector({
    isFormValid: isValid(FORM_NAMES.confirmationCodeForm),
    sendSmsPassGeneration: makePasswordGeneration(),
    fillSmsPasswordGeneration: makeFillSmsPasswordGeneration(),
})

const mapDispatchToProps = {
    sendLoggedSms,
    setLoggedConfirmationCode,
}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

// eslint-disable-next-line no-func-assign
Component = reduxForm({
    form: FORM_NAMES.confirmationCodeForm,
    //onSubmit: onSubmitForm
}) (Component)

export default compose(withConnect)(Component)
