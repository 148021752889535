import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeIsLoading = () =>
    createSelector(app, slice => _.get(slice, 'isLoading'))

export const makeIsRequestError = () =>
    createSelector(app, slice => _.get(slice, 'isRequestError'))

export const makeIsAccessDenied = () =>
    createSelector(app, slice => _.get(slice, 'isAccessDenied'))

export const makeCheckedBuildings = () =>
    createSelector(app, slice => _.get(slice, 'checkedBuildings'))

export const makeRedirectUrl = () =>
    createSelector(app, slice =>  _.get(slice, 'redirectUrl'))

