import React from 'react'
import ReactTooltip from 'react-tooltip'

import style from './style.css'

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched
    const tipId = `tip-${props.id}`

    return (
        <div>
            <div className={style.container} data-tip={props.dataTip} data-for={tipId}>
                <ReactTooltip id={tipId} className={style.tooltip} />
                <input {...props.input} id={props.id} type='checkbox' className={style.field} />
                <label htmlFor={props.id} className={style.label}>{props.label}</label>
            </div>
            {isInvalid && <div className={style.errorBackground} />}
        </div>
    )
}

export default Component
