import React from 'react'
import _ from 'lodash'

import Initialized from './initialized'
import SelectedPerformer from './selected-performer'
import CanceledPerformer from './canceled-performer'
import Completed from './completed'
import Declined from './declined'
import style from './style.css'

const Component = props => {
    const { history } = props

    return (
        <div className={style.container}>
            {_.map(history, i => {
                console.log('i', i)
                if (i.type === 'dialog') {
                    return (
                        <Initialized
                            key={i.id}
                            user={i.action.user}
                            images={i.action.image_attachments}
                            files={i.action.file_attachments}
                            message={i.action.message}
                            date={i.date}
                        />
                    )
                }
                if (i.type === 'performer_change') {
                    const firstName = _.get(i, 'action.new_performer.first_name')
                    if (firstName) {
                        return <SelectedPerformer key={i.id} performer={i.action.new_performer} user={i.action.user} date={i.date} />
                    }
                    return <CanceledPerformer key={i.id} user={i.action.user} date={i.date} />
                }
                if (i.type === 'field_change' && _.get(i, 'action.field_name') === 'resolution') {
                    if (i.action.new_value === "resolved") {
                        return <Completed key={i.id} user={i.action.user} date={i.date} />
                    }
                    if (i.action.new_value === "canceled") {
                        return <Declined key={i.id} description={i.action.description} user={i.action.user} date={i.date} />
                    }
                }
                return null
            })}
        </div>
    )
}

export default Component
