import _ from 'lodash'

const icons = {
    water: 'W',
    elec: 'E',
    gas: 'G',
    heating: 'h',
}

export const getIcon = type => _.get(icons, type)
