import React from 'react'
import PropTypes from 'prop-types'
import {Avatar} from "../avatar";
import style from "./style.css";

export const ContactInfo = ({photo, title, subtitle, children, isOnline, number, isAvatar}) => (
    <>
        {isAvatar && <Avatar photo={photo} isOnline={isOnline}/>}
        <div className={style.description}>
            <div className={style.title}>
                {title}
            </div>
            {subtitle && <div className={style.subtitle}>{subtitle} {number ? `(п. ${number})` : ''}</div>}
            {children}
        </div>
    </>
)

ContactInfo.propTypes = {
    photo: PropTypes.shape({
        value: PropTypes.string,
        type: PropTypes.string
    }),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    number: PropTypes.string,
    isAvatar: PropTypes.bool
}

ContactInfo.defaultProps = {
    isAvatar: true
}
