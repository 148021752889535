import * as types from '../actions-types'
import axios from 'axios'

import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchCreateCamera = (data) => ({ type: types.CREATE_CAMERA, data })
export const fetchCreateCameraSuccess = (data) => ({ type: types.CREATE_CAMERA_SUCCESS, data })
export const fetchCreateCameraFail = (data) => ({ type: types.CREATE_CAMERA_FAIL, data })

export const fetchGetCamera = (data) => ({ type: types.GET_CAMERA, data })
export const fetchGetCameraSuccess = (data) => ({ type: types.GET_CAMERA_SUCCESS, data })
export const fetchGetCameraFail = (data) => ({ type: types.GET_CAMERA_FAIL, data })

export const fetchEditCamera = (data) => ({ type: types.EDIT_CAMERA, data })
export const fetchEditCameraSuccess = (data) => ({ type: types.EDIT_CAMERA_SUCCESS, data })
export const fetchEditCameraFail = (data) => ({ type: types.EDIT_CAMERA_FAIL, data })

export const fetchDeleteCamera = (data) => ({ type: types.DELETE_CAMERA, data })
export const fetchDeleteCameraSuccess = (data) => ({ type: types.DELETE_CAMERA_SUCCESS, data })
export const fetchDeleteCameraFail = (data) => ({ type: types.DELETE_CAMERA_FAIL, data })

export const createCamera = (data) => dispatch => {
    dispatch(fetchCreateCamera())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/cameras/',
        data,
    })
        .then(response => {
            dispatch(fetchCreateCameraSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchCreateCameraFail(error))
        })
}

export const getCamera = id => dispatch => {
    dispatch(fetchGetCamera())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/cameras/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetCameraSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetCameraFail(error))
        })
}

export const editCamera = (id, data) => dispatch => {
    dispatch(fetchEditCamera())

    return axios({
        method: 'PUT',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/cameras/${id}/`,
        data,
    })
        .then(response => {
            dispatch(fetchEditCameraSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchEditCameraFail(error))
        })
}

export const deleteCamera = (id) => dispatch => {
    dispatch(fetchDeleteCamera())

    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/cameras/${id}/`,
    })
        .then(response => {
            dispatch(fetchDeleteCameraSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchDeleteCameraFail(error))
        })
}
