import React from 'react'
import classNames  from 'classnames'
import MultiSelect from 'react-widgets/lib/Multiselect'
import PropTypes from 'prop-types'

import Label from '../label'

import style from './style.css'

const messages = {
    noneSelected: 'Нет результатов',
    emptyFilter: 'Нет совпадений',
    emptyList: 'Нет элементов для выбора',
}

function Component(props) {
    const { input, meta, options, size, label, disabled } = props
    const isInvalid = meta.invalid && meta.touched

    const handleBlur = () => {
        input.onBlur()
    }

    return (
        <div className={style.container}>
            <div className={style.container}>
                <MultiSelect
                    onBlur={handleBlur}
                    onFocus={input.onFocus}
                    onChange={input.onChange}
                    value={input.value}
                    data={options}
                    filter
                    textField='label'
                    valueField='value'
                    className={classNames(
                        style[`size-${size}`],
                        isInvalid && style.invalid,
                    )}
                    messages={messages}
                    disabled={disabled}
                />
                <Label>{label}</Label>
            </div>
            {isInvalid && <div className={style.errorMessage}>{meta.error}</div>}
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
}

Component.defaultProps = {
    size: 'auto',
}

export default Component
