import React, {useCallback} from 'react'
import style from "./style.css";
import classnames from "classnames";
import {ContactInfo} from "../../contact/contact-info";
import {formatDate} from "../../../../../__data__/utils";

export const ComplaintType = ({complaintType, previewComplaints, selectedType, onClick}) => {
    const isSelected = selectedType === complaintType.value
    const complaint = previewComplaints[complaintType.value]
    const onHandleClick = useCallback(() => {
        onClick(complaintType.value)
    }, [complaintType.value, onClick])
    return (
        <li
            onClick={onHandleClick}
            className={style.wrapper}
        >
            <div className={classnames(style.contact, isSelected && style.selected)}>
                <ContactInfo
                    isOnline={false}
                    photo={complaintType.photo}
                    title={complaintType.title}
                    subtitle={complaint ? complaint['last_complaint']['description'] : '[Здесь пока ничего нет]'}
                />
                <div className={style.info}>
                    {
                        complaint && complaint['unread_count'] !== 0 ?
                            <div className={classnames(style.tag, isSelected && style.selected)}>
                                {complaint['unread_count']}
                            </div>
                            :
                            <div></div>
                    }
                    {
                        <span className={style.date}>
                            {complaint ? formatDate(complaint['last_complaint']['creation_date']) : ''}
                        </span>
                    }
                </div>
            </div>
        </li>
    )
}