import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makePasswordGeneration = () =>
    createSelector(app, slice => _.get(slice, 'sendSmsPassGeneration', {}))

export const makeFillSmsPasswordGeneration = () =>
    createSelector(app, slice => _.get(slice, 'fillSmsPassGeneration', {}))

export const makeIsPasswordChanged = () =>
    createSelector(app, slice => _.get(slice, 'isPasswordChanged'))
