import React from 'react'
import PhoneInput from 'react-phone-number-input'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Label from '../label'
import { FieldErrorMessage } from '../'

import style from './style.css'

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched

    return (
        <div>
            <div className={classNames(
                    style.container,
                    isInvalid && style.invalid,
                    style[`size-${props.size}`],
                 )}
            >
                {props.description && <span className={style.description}>{props.description}</span>}
                <PhoneInput
                    {...props.input}
                    country="RU"
                    countries={['RU']}
                    international={false}
                    showCountrySelect={false}
                    placeholder="+7 (999) 999-99-99"
                />
                <Label>{props.label}</Label>
            </div>
            <FieldErrorMessage meta={props.meta} />
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.oneOf(['auto', 'md']),
}

Component.defaultProps = {
    size: 'md',
}

export default Component
