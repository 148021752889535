import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeTasksCreateServiceList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'services')

        return _.map(buildings, item => ({ value: item.id, label: item.name }))
    })

export const makeTasksServiceList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'services')
        const allBuildingsKey = _.reduce(buildings, (result, item) => {
            if (!result) {
                return item.id
            }
            else {
                return `${result},${item.id}`
            }
        }, '')

        return [{value: allBuildingsKey, label: 'Все виды услуг'}, ..._.map(buildings, item => ({ value: item.id, label: item.name }))]
    })

export const makeTasksExecutorsList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'serviceExecutors')
        return _.map(buildings, item => ({ value: item.user, label: `${item.last_name} ${item.first_name} (${item.position})` }))
    })
