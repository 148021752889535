import React from 'react'
import { Field } from 'redux-form'

import { MultiSelectInput } from '../../../../../../components/form'
import { servicesRequired } from '../../../../../../components/form/validation'

import style from './style.css'

function Component (props) {
    const { serviceList } = props

    return (
        <div className={style.container}>
            <Field
                name="services"
                component={MultiSelectInput}
                label="Типы работ"
                options={serviceList}
                validate={[ servicesRequired ]}
            />
        </div>
    )
}

export default Component
