import React  from 'react'
import classNames from 'classnames'

import loader from '../../../../../../theme/blinking_loader.svg'

import style from './style.css'

function Attachment(props) {
    const { url } = props
    if(!url) return <img alt='loader' src={loader} />

    return (
        <div className={classNames(style.container)}>
            <a href={url.large.file} target="_blank">
                <img alt='img' src={url.large.file} className={style.image} />
            </a>
        </div>
    )
}

export default Attachment
