import React from 'react'
import classNames from 'classnames'
import InputMask from 'react-input-mask'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { FieldErrorMessage } from '../../../components/form'

import style from './style.css'
import ReactTooltip from "react-tooltip";

const formatValue = (value) => {
    const numberValue = value.replace(/[^+\d]/g, '')
    return numberValue.padStart(6, '0')
}

function Component(props) {
    const tipId = `tip-lastMonthMeters`
    const isInvalid = props.meta.invalid && props.meta.touched

    const handleBlur = (e) => {
        props.input.onBlur(e)
        if (e.target.value) {
            const preparedValue = formatValue(e.target.value)
            props.change(props.formName, props.input.name, preparedValue)
        }
        else {
            props.change(props.formName, props.input.name, '')
        }
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container} data-tip={props.tooltip} data-for={tipId}>
                {props.tooltip && <ReactTooltip id={tipId} className={style.tooltip} />}
                <InputMask
                    {...props.input}
                    onBlur={handleBlur}
                    mask="9 9 9  9 9 9"
                    placeholder={props.placeholder}
                    className={classNames(
                    style.field,
                    isInvalid && style.invalid
                )} />
                <label className={style.label}>
                    <span className={classNames(
                        style.labelIcon,
                        style[props.iconColor],
                        style[props.icon],
                    )}>{props.icon}</span>
                    <span>{props.label}</span>
                </label>
            </div>
            <FieldErrorMessage meta={props.meta} />
        </div>
    )
}

const mapDispatchToProps = {
    change
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(Component)
