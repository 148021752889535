import queryString from 'query-string'

import { setBuildingMetersSettings }  from '../../actions/actions'
import _ from 'lodash'

function submit(values, dispatch) {
    const { startDate, endDate, isElec, elecType, isWater, waterType, isGas, isHeating, cameras } = values
    const queryParams = queryString.parse(window.location.search)
    const id = _.get(queryParams, 'id')

    const data = {submit_start_day: startDate, submit_end_day: endDate}
    const reading_types = []
    if (isWater) {
        reading_types.push('water_cold')
        if (waterType === 'water_hot_and_cold') {
            reading_types.push('water_hot')
        }
    }
    if (isElec) {
        reading_types.push('elec_day')
        if (elecType === 'elec_day_and_night') {
            reading_types.push('elec_night')
        }
    }
    if (isGas) {
        reading_types.push('gas')
    }
    if (isHeating) {
        reading_types.push('heating')
    }

    const cam = _.reduce(cameras, (result, i, key) => {
        if (i) { result.push(key.replace('id-', '')) }
        return result
    }, [])

    dispatch(setBuildingMetersSettings(id, {...data, reading_types, cameras: cam}))
}

export default submit
