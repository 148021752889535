import React from 'react'
import {useScrollYPosition} from 'react-use-scroll-position'
import classNames from 'classnames'
import _ from 'lodash'

import style from './style.css'
import Menu from '../context-menu'
import activeIcon from "../../../settings/icon/actvie";
import createdIcon from "../../../settings/icon/created";
import blockIcon from "../../../settings/icon/block";
import ReactTooltip from "react-tooltip";

function Component(props) {
    const {
        object,
        residents,
        handleClick,
    } = props

    const handleClose = e => {
        e.preventDefault()
        handleClick(null)
    }


    const statusListText = {
        added: 'Добавлен',
        blocked: 'Заблокирован',
        invited: 'Приглашён',
        system: 'В системе',
        active: 'В системе',
        created: 'Создан',
    }

    const statusList = {
        active:  activeIcon(),
        created: createdIcon(),
        suspended: blockIcon(),
    }

    const isSticky = useScrollYPosition() > 85
    console.log("CARD PROPS")
    console.log(props)
    return (
        <div className={classNames(style.container, isSticky && style.containerSticky)}>
            <div className={style.header}>
                <div className={style.nameWrapper}>
                    <div className={style.flat}>• {object.label} •</div>
                </div>
                <a href='' onClick={handleClose} className={style.closeButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g fill="none" fillRule="evenodd">
                            <path fill="#F7F8FA" d="M0 0H1440V900H0z" transform="translate(-897 -143)"/>
                            <g>
                                <path fill="#D8D8D8" fill-opacity="0" d="M0 0H46V46H0z"
                                      transform="translate(-897 -143) translate(880 126)"/>
                                <g>
                                    <path d="M0 0L24 0 24 24 0 24z"
                                          transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path d="M0 0L24 0 24 24 0 24z"
                                          transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path fill="#7D8B8D"
                                          d="M12 13.414l-3.542 3.542c-.387.387-1.015.387-1.408-.006-.39-.39-.388-1.026-.006-1.408L10.586 12 7.044 8.458c-.387-.387-.387-1.015.006-1.408.39-.39 1.026-.388 1.408-.006L12 10.586l3.542-3.542c.387-.387 1.015-.387 1.408.006.39.39.388 1.026.006 1.408L13.414 12l3.542 3.542c.387.387.387 1.015-.006 1.408-.39.39-1.026.388-1.408.006L12 13.414z"
                                          transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path d="M0 0L24 0 24 24 0 24z"
                                          transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
            {_.map(residents, resident => {
                const tipId = `tip-${resident.user}`
                return (
                <div className={style.fields}>
                    <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                        <title>DISPLAY/avatar</title>
                        <defs>
                            <circle id="path-1" cx="30" cy="30" r="30"></circle>
                            <linearGradient x1="50%" y1="1.15175769%" x2="50%" y2="100%" id="linearGradient-3">
                                <stop stop-color="#FFFFFF" offset="0%"></stop>
                                <stop stop-color="#EAEAEA" offset="100%"></stop>
                            </linearGradient>
                        </defs>
                        <g id="SCREENS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="_ASSETS" transform="translate(-810.000000, -112.000000)">
                                <g id="DISPLAY/avatar" transform="translate(810.000000, 112.000000)">
                                    <mask id="mask-2" fill="white">
                                        <use href="#path-1"></use>
                                    </mask>
                                    <use id="MASK" fill="#FFFFFF" fillRule="evenodd" href="#path-1"></use>
                                    <g id="XTRAZ/avatars/_person" mask="url(#mask-2)" fillRule="evenodd">
                                        <rect id="Rectangle" fill="url(#linearGradient-3)" x="0" y="0" width="60"
                                              height="60"></rect>
                                        <path
                                            d="M37.5,21.25 C37.5,17.244375 34.255625,14 30.25,14 C26.244375,14 23,17.244375 23,21.25 C23,25.255625 26.244375,28.5 30.25,28.5 C34.255625,28.5 37.5,25.255625 37.5,21.25 Z M30.0498176,31 C34.0953955,31 41.6664583,33.9087309 43.5,39.6882178 C40.1959067,43.55356 35.3070398,46 29.8518504,46 C24.5572284,46 19.7960815,43.695457 16.5,40.0263081 C18.1397135,34.0220554 25.9261847,31 30.0498176,31 Z"
                                            id="Shape" fill="#B7BABF"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <div className={style.fieldValue}
                         dangerouslySetInnerHTML={{__html: `${resident.last_name} ${resident.first_name}\n${resident.phone_number}`}}>
                    </div>
                    <div className={style.fieldValue} data-tip={statusListText[resident.status]} data-for={tipId}>
                        <ReactTooltip id={tipId} className={style.tooltip} />
                        {statusList[resident.status]}
                    </div>
                    <Menu status={resident.status} user={resident.user}/>
                </div>
                )})}
        </div>
    )
}

export default Component
