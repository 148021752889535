import React, { Fragment } from 'react'
import classNames from 'classnames'

import style from './radio-button.css'
import {FieldErrorMessage} from "../index";

function Component(props) {
    const { meta, input, status, isFirst } = props
    const isInvalid = meta.invalid && meta.touched

    return (
        <Fragment>
            <div className={classNames(
                style.container,
                isInvalid && style.invalid,
            )}>
                <input
                    {...input}
                    className={style.field}
                    type="radio"
                    id={input.name + "_" + input.value}
                />
                <label
                    className={classNames(
                        style.label,
                        style[`status-${status}`],
                    )}
                    htmlFor={input.name + "_" + input.value}
                >{props.label}</label>
            </div>
            {isFirst && (
                <div className={style.messageWrapper}>
                    <FieldErrorMessage meta={props.meta} />
                </div>
            )}
        </Fragment>
    )
}

Component.defaultProps = {
    status: 'default',
}

export default Component
