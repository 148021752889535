import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeBuildingFlats } from '../../../../__data__/selectors/building'
import { getBuildingData } from '../../../../__data__/actions/buildings'
import { FlatSelectInput } from '../../../../components/form'

function Component({ input, meta, getBuildingData, homeNumber, flatsList, label, disabled }) {
    useEffect(() => {
        if(!homeNumber) {
            return
        }
        getBuildingData(homeNumber)
    }, [homeNumber])

    return (
        <FlatSelectInput input={input} disabled={disabled} meta={meta} flatsList={flatsList} label={label} />
    )
}

const mapStateToProps = createStructuredSelector({
    flatsList: makeBuildingFlats(),
})

const mapDispatchToProps = {
    getBuildingData,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
