import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {makeImageUrl} from '../../utils/blob-helpers';
import defaultAvatar from '../../assets/icon-profile.svg'

import style from './style.css';

export const Avatar = ({photo, size}) => {
    const avatar = makeImageUrl(photo);

    return (
        <div className={classnames(style.avatar, {[style.mSize]: size === "m", [style.fullWidth]: avatar})}>
            <img
                src={avatar || defaultAvatar}
                alt="user-avatar"
            />
        </div>
    )
}

Avatar.propTypes = {
    photo: PropTypes.shape({
        data: PropTypes.string,
        type: PropTypes.string
    }),
    size: PropTypes.oneOf(['m']),
    isOnline: PropTypes.bool
}
