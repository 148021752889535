import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import classNames from 'classnames'
import _ from 'lodash'

import style from './style.css'

function Component(props) {
    const {
        flatNumber,
        metersList,
        handleClick,
    } = props

    const handleClose = e => {
        e.preventDefault()
        handleClick(null)
    }

    const isSticky = useScrollYPosition() > 85

    return (
        <div className={classNames(style.container, isSticky && style.containerSticky)}>
            <div className={style.header}>
                <div className={style.avatar}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                        <defs>
                            <linearGradient id="avatar_flat-a" x1="50%" x2="50%" y1="1.152%" y2="100%">
                                <stop offset="0%" stop-color="#FFF"/>
                                <stop offset="100%" stop-color="#EAEAEA"/>
                            </linearGradient>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle cx="30" cy="30" r="30" fill="url(#avatar_flat-a)"/>
                            <path fill="#B7BABF" d="M21.5,21.0555556 L21.5,41.5 L38.5,41.5 L38.5,21.0555556 C38.5,19.6372222 37.2391667,18.5 35.6666667,18.5 L24.3333333,18.5 C22.7608333,18.5 21.5,19.6372222 21.5,21.0555556 Z M36,23 L36,39 L36,39 L24,39 L24,23 C24,21.8954305 24.8954305,21 26,21 L34,21 C35.1045695,21 36,21.8954305 36,23 Z M31.5,29 L31.5,31.5 L34.5,31.5 L34.5,29 L31.5,29 Z"/>
                        </g>
                    </svg>
                </div>
                <div className={style.headerContent}>
                    <div className={style.flatNumberWrapper}>
                        <div className={style.flatNumberLabel}>Квартира</div>
                        <div className={style.flatNumber}>{flatNumber}</div>
                    </div>
                </div>
                <a href='' onClick={handleClose} className={style.closeButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g fill="none" fillRule="evenodd">
                            <path fill="#F7F8FA" d="M0 0H1440V900H0z" transform="translate(-897 -143)"/>
                            <g>
                                <path fill="#D8D8D8" fill-opacity="0" d="M0 0H46V46H0z" transform="translate(-897 -143) translate(880 126)"/>
                                <g>
                                    <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path fill="#7D8B8D" d="M12 13.414l-3.542 3.542c-.387.387-1.015.387-1.408-.006-.39-.39-.388-1.026-.006-1.408L10.586 12 7.044 8.458c-.387-.387-.387-1.015.006-1.408.39-.39 1.026-.388 1.408-.006L12 10.586l3.542-3.542c.387-.387 1.015-.387 1.408.006.39.39.388 1.026.006 1.408L13.414 12l3.542 3.542c.387.387.387 1.015-.006 1.408-.39.39-1.026.388-1.408.006L12 13.414z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
            <div className={style.meters}>
                { _.map(metersList, i => (
                    <div className={style.meter}>
                        <div className={style.date}>{i.date}</div>
                        <div className={style.name}>{i.sender}</div>
                        { _.map(i.passes, p => (
                            <div className={style.field} key={p.name}>
                                <div className={style.fieldLabel}>{p.name}</div>
                                <div className={style.fieldValue}>{p.value}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Component
