import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { formatDate } from '../../../../__data__/utils'

import style from './style.css'

const Component = props => {
    const { title, description, name, date, order } = props
    const dateTime = formatDate(date, 'D MMMM, в h:mm')

    return (
        <div className={style.container} data-order={order}>
            <div className={style.title}>{title}</div>
            <div className={style.description}>{description}</div>
            <div className={style.footer}>
                <div className={style.name}>{name}</div>
                <div className={style.date}>{dateTime}</div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
})

const mapDispatchToProps = {

}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
