import _ from 'lodash'

import {createResident, editResident} from '../../../__data__/actions/residents'

export const getData = (values) => ({
    first_name: _.get(values, 'name'),
    last_name: _.get(values, 'surname'),
    phone_number: _.get(values, 'phoneNumber'),
    gender: _.get(values, 'gender'),
    units: values.units.map(({flatNumber}) => flatNumber.id),
})

export function submitCreatedResident(values, dispatch) {
    const data = getData(values)
    dispatch(createResident(data))
}

export function submitEditedResident(values, dispatch) {
    const data = getData(values)
    dispatch(editResident({rsData: data,  id: values?.id}))
}
