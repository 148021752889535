import React, { useCallback } from 'react'
import classNames  from 'classnames'
import DropdownList from 'react-widgets/lib/DropdownList'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {makeTasksFilterDate, makeTasksFilterType, makeTasksSortedList} from '../../../../../../__data__/selectors/tasks'
import { makeCheckedBuildings } from '../../../../../../__data__/selectors/common'
import { patchTask, saveTasksToStoreList, getTasksCount } from '../../../../../../__data__/actions/tasks'

import style from './style.css'

const ListItem = ({ item }) => (
    <div className={style.item}>
        <div className={style.itemText}>{item.label}</div>
    </div>
)

const inputProps = {spellcheck: "false"}

let ValueInput = ({ item }) => item ? (
    <div className={style.item}>
        <div className={style.itemText}>{item.label}</div>
    </div>
) : ''

function Component(props) {
    const {
        input,
        meta,
        placeholder,
        list,
        size,
        defaultOpen,
        taskId,
        stage,
        patchTask,
        tasksSortedList,
        saveTasksToStoreList,
        checkedBuildings,
        getTasksCount,
        changeModalVisibility,
        tasksFilterDate,
        tasksFilterType,
    } = props
    const isInvalid = meta.invalid && meta.touched

    const onChange = useCallback(e => {
        const performer = e.value

        if (stage === 'created') {
            const firstInProgressTaskOrder = _.get(tasksSortedList, 'in_progress[0].order')
            const order = firstInProgressTaskOrder ? firstInProgressTaskOrder - 0.001 : 1
            // Сохраняем в базу
            patchTask(taskId, {performer, stage: 'in_progress', order})
            getTasksCount(checkedBuildings, 'created', tasksFilterDate, tasksFilterType.value)
            getTasksCount(checkedBuildings, 'in_progress', tasksFilterDate, tasksFilterType.value)

            // Сохраняем в стор
            const currentTask = _.find(_.get(tasksSortedList, 'created'), i => i.id === +taskId)
            currentTask.order = order
            currentTask.stage = 'in_progress'

            const createdTasks = _.filter(_.get(tasksSortedList, 'created'), i => i.id !== +taskId)
            const inProgressTasks = [currentTask, ..._.get(tasksSortedList, 'in_progress')]
            const doneTasks = _.get(tasksSortedList, 'done')

            saveTasksToStoreList({created: createdTasks, in_progress: inProgressTasks, done: doneTasks})
        }

        if (stage === 'in_progress') {
            // Сохраняем в базу
            patchTask(taskId, {performer})
        }
        changeModalVisibility(false)
    }, [checkedBuildings, getTasksCount, saveTasksToStoreList, patchTask, stage, taskId, tasksSortedList])

    return (
        <div>
            <div className={style.container}>
                <DropdownList
                    value={input.value}
                    onFocus={input.onFocus}
                    textField='label'
                    itemComponent={ListItem}
                    onChange={onChange}
                    data={list}
                    className={classNames(
                        style[`size-${size}`],
                        isInvalid && style.invalid,
                    )}
                    inputProps={inputProps}
                    valueComponent={ValueInput}
                    placeholder={placeholder || "Выбрать"}
                    defaultOpen={defaultOpen}
                />
            </div>
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
    defaultOpen: PropTypes.bool,
}

Component.defaultProps = {
    size: 'auto',
    defaultOpen: false,
}

const mapStateToProps = createStructuredSelector({
    tasksSortedList: makeTasksSortedList(),
    checkedBuildings: makeCheckedBuildings(),
    tasksFilterDate: makeTasksFilterDate(),
    tasksFilterType: makeTasksFilterType(),
})

const mapDispatchToProps = {
    patchTask,
    saveTasksToStoreList,
    getTasksCount,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
