import React, {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {formatDate} from '../../../../__data__/utils';
import { ContactInfo } from './contact-info';

import style from './style.css'

export const Contact = ({user, onClick, isOnline, isGroup}) => {
    const { search } = useLocation();
    const selectedTopic = useMemo(() => new URLSearchParams(search).get('id'), [search]);
    const onHandleClick = useCallback(() => {
        onClick(user)
    }, [user, onClick])

    return (
        <li
            onClick={onHandleClick}
            className={style.wrapper}
        >
            <div className={classnames(style.contact, {[style.selected]: user.user === selectedTopic})}>
                <ContactInfo
                    isOnline={isOnline}
                    photo={user.public && user.public.photo}
                    title={user.public && user.public.fn}
                    subtitle={user.address}
                    number={user.porch}
                />
                <div className={style.info}>
                    <div className={classnames(style.tag, {[style.selected]: !!user.unread})}>
                        {user.unread || ''}
                    </div>
                    {user.touched && <span className={style.date}>
                        {formatDate(user.touched)}
                    </span>}
                </div>
                {!isGroup && <span className={classnames(style.checkmark, {[style.online]: isOnline})}/>}
            </div>
        </li>
    )
}

Contact.propTypes = {
    user: PropTypes.shape({
        public: PropTypes.shape({
            photo: PropTypes.object,
            fn: PropTypes.string.isRequired
        }).isRequired,
        user: PropTypes.string.isRequired
    }),
    onClick: PropTypes.func,
    isGroup: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool
}
