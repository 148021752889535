export class WebSocketClient {
    constructor(url) {
        WebSocketClient._instance = this;
        this.connection = new WebSocket(url);
    }

    sendPreviewComplaintsRequest(checkedBuildings) {
        this.connection.send(JSON.stringify({
            "type": 'receive_preview_complaints',
            "buildings": checkedBuildings
        }))
    }

    sendComplaintsListRequest(complaintType, checkedBuildings) {
        this.connection.send(JSON.stringify({
            "type": 'get_complaints',
            "complaint_type": complaintType,
            "buildings": checkedBuildings,
            // TODO: костыль, пока нет пагинации для отображения жалоб (переделать на lazy loading).
            "page_size": 322
        }))
    }

    sendComplaintsReadRequest(complaintsList) {
        this.connection.send(JSON.stringify({"type": 'read_complaints', "complaints_list": complaintsList}))
    }
}
