import React  from 'react'
import ReactTooltip from 'react-tooltip'

import { getFileFormatIcon } from '../../../../../../__data__/utils'
import loader from '../../../../../../theme/blinking_loader.svg'

import style from './style.css'

function Attachment(props) {
    const { fileName, realName, index } = props

    if(!fileName) return <img alt='file' className={style.loader} src={loader} />
    const icon = getFileFormatIcon(realName)

    return (
        <a className={style.container} target="_blank" href={fileName} data-tip={realName}
           data-for={`${realName}${index}`}>
            <ReactTooltip id={`${realName}${index}`} className={style.tooltip}/>
            <img src={icon}/>
            <span className={style.fileName}>{realName}</span>
        </a>
    )
}

export default Attachment
