import React from 'react'
import style from './style.css'


function Component(props) {
    const {handleStoriClick, stori} = props

    return (
        // TODO: use the small + medium + large + original for loading images
        <div className={style.stori} onClick={(event) => handleStoriClick(event, stori)}>
            <img src={ stori.cover.medium.file } className={style.image} />
        </div>
    )
}

export default Component
