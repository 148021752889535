import React from 'react'

import Menu from './context-menu'
import style from './style.css'

const Component = (props) => {
    const { name, link, id } = props

    return (
        <div className={style.container}>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
                allowFullScreen="true"
                frameBorder="0"
                height="178"
                src={link}
                width="316"
            />
            <div className={style.footer}>
                <h3 className={style.name}>{name}</h3>
                <div className={style.menuWrapper}>
                    <Menu id={id} />
                </div>
            </div>
        </div>
    )
}

export default Component
