import React from 'react'
import styles from "../../../styles.css";
import {ContactInfo} from "../../contact/contact-info";
import {Header} from "../../header";
import {Complaints} from "../complaints";
import {COMPLAINT_TYPE} from "../../../../../constants/chat";
import {SearchField} from "../../search-field";

export const ComplaintsView = ({complaintsList, complaintType}) => {
    if (!complaintType) {
        return null
    }
    const complaint = COMPLAINT_TYPE.find(item => item.value === complaintType);
    const title = complaint.title;
    const description = complaint.description;
    // TODO: использоваться вложения с бэкенда, также (при нажатии на жалобу)
    //  открывать карточку пользователя, которая у нас уже есть
    //  (и там уже отображать кнопку/ссылку на чат, адреса проживания и т. д.)


    return (
        <div className={styles.contentWrapper}>
            <Header>
                {/*TODO: добавить SearchField с поиском по всем жалобам в рамках выбранных подконтрольных объектов */}
                {/*<SearchField onSearch={onSearch} {...rest} />*/}
                <ContactInfo
                    title={title}
                    isAvatar={false}
                >
                    <span className={styles.active}>{description}</span>
                </ContactInfo>
            </Header>
            <Complaints
                complaints={complaintsList}
            />
        </div>
    )
}