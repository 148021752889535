import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'

import style from './action.css'

function Component(props) {
    const { formName, dispatch, setHomeSelect } = props

    const handleClick = () => {
        dispatch(submit(formName))

        // Записываем в стор открыто/закрыто окно выбора зданий. Чтобы выводить серый фон над контентом.
        setHomeSelect(false)
    }

    return (
        <button
            className={style.container}
            type='button'
            onClick={handleClick}
        >
            Применить
        </button>
    )
}

export default connect()(Component)
