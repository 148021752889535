import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { URL_LIST } from '../../../../__data__/constants'

import style from './style.css'
import { checkPermissionForSection } from "../../../../__data__/actions/permissions";

function Component(props) {
    const { buildings } = props
    const isAvailableByPerms = checkPermissionForSection('Объекты')

    return (
        <div className={style.container}>
            <div className={style.title}>
                <div className={style.titleText}>Мои объекты</div>
                <div className={style.titleSize}>{_.size(buildings)}</div>
            </div>
            <div className={style.list}>
                {
                    _.map(buildings, i => (
                        !isAvailableByPerms ? (
                            <Link to={undefined} key={i.id} className={style.item}
                                  style={{textDecoration: 'none', pointerEvents: 'none'}}>
                                <div className={style.building}>{i.building}</div>
                                <div className={style.complex}>{i.complex}</div>
                                <div className={style.porches}>{i.porches}</div>
                            </Link>
                        ) : (
                            <Link to={`${URL_LIST.settingsBuildings}?id=${i.id}`} key={i.id} className={style.item}
                                  style={{textDecoration: 'none'}}>
                                <div className={style.building}>{i.building}</div>
                                <div className={style.complex}>{i.complex}</div>
                                <div className={style.porches}>{i.porches}</div>
                            </Link>
                        )
                    ))
                }
            </div>
        </div>
    )
}

export default Component
