import axios from 'axios'

import * as types from '../actions-types'
import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchCameraList = () => ({ type: types.GET_CAMERA_LIST })
export const fetchCameraListSuccess = (data) => ({ type: types.GET_CAMERA_LIST_SUCCESS, data })
export const fetchCameraListFail = (error) => ({ type: types.GET_CAMERA_LIST_FAIL, error })

export const getCameraList = buildingsId => dispatch => {
    dispatch(fetchCameraList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/cameras/?buildings_id=${buildingsId}`,
    })
        .then(response => {
            dispatch(fetchCameraListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchCameraListFail(error))
        })
}
