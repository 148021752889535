import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeTasksCreateServiceList } from '../../../__data__/selectors/task-form'
import { getServices } from '../../../__data__/actions/tasks'
import { SelectInput } from '../../../components/form'

function Component(props) {
    const { input, meta, tasksServiceList, formName, changeField, label, buildingSelectValue } = props

    useEffect(() => {
        if (buildingSelectValue.type === 'building') {
            props.getServices(buildingSelectValue.value)
        }
        if (buildingSelectValue.type === 'porch') {
            props.getServices(buildingSelectValue.buildingId)
        }
    }, [buildingSelectValue?.value, buildingSelectValue?.type])

    const handleChange = useCallback(() => {
        changeField(formName, 'performer', null)
    }, [])

    return (
        <SelectInput input={input} meta={meta} list={tasksServiceList} handleChange={handleChange} label={label} />
    )
}

const mapStateToProps = createStructuredSelector({
    tasksServiceList: makeTasksCreateServiceList(),
})

const mapDispatchToProps = {
    getServices,
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
