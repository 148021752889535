import axios from 'axios'

import { MODERATOR_STORAGE_NAME } from '../constants'
import { getToken } from '../utils'
import * as types from '../actions-types'

export const fetchGetMessagingDeviceToken = () => ({ type: types.FETCH_GET_MESSAGING_DEVICE_TOKEN })
export const fetchGetMessagingDeviceTokenSuccess = (data) => ({ type: types.FETCH_GET_MESSAGING_DEVICE_TOKEN_SUCCESS, data })
export const fetchGetMessagingDeviceTokenFail = (error) => ({ type: types.FETCH_GET_MESSAGING_DEVICE_TOKEN_FAIL, error })

export const fetchRepeatMessagingDeviceToken = () => ({ type: types.FETCH_REPEAT_MESSAGING_DEVICE_TOKEN })
export const fetchRepeatMessagingDeviceTokenSuccess = (data) => ({ type: types.FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_SUCCESS, data })
export const fetchRepeatMessagingDeviceTokenFail = (error) => ({ type: types.FETCH_REPEAT_MESSAGING_DEVICE_TOKEN_FAIL, error })

export const fetchSendMessagingDeviceToken = () => ({ type: types.FETCH_SEND_MESSAGING_DEVICE_TOKEN })
export const fetchSendMessagingDeviceTokenSuccess = (data) => ({ type: types.FETCH_SEND_MESSAGING_DEVICE_TOKEN_SUCCESS, data })
export const fetchSendMessagingDeviceTokenFail = (error) => ({ type: types.FETCH_SEND_MESSAGING_DEVICE_TOKEN_FAIL, error })

export const fetchPushNotification = (data) => ({ data, type: types.FETCH_PUSH_NOTIFICATION })

export const fetchDeleteNotification = (data) => ({ data, type: types.FETCH_DELETE_NOTIFICATION })

export const fetchDeleteAllNotification = (data) => ({ data, type: types.FETCH_DELETE_ALL_NOTIFICATION })

export const sendMessagingDeviceToken = (deviceToken) => dispatch => {
    dispatch(fetchSendMessagingDeviceToken())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: '/api/authorized/devices/',
        data: {
            registration_id: deviceToken,
            type: 'web',
        },
    })
        .then(response => {

        })
        .catch(error => {

        })
}

export const getMessagingDeviceToken = (firebase) => dispatch => {
    dispatch(fetchGetMessagingDeviceToken())

    return firebase.messaging().getToken().then((currentToken) => {
        dispatch(fetchGetMessagingDeviceTokenSuccess())
        // // Saving the Device Token to the datastore.
        dispatch(sendMessagingDeviceToken(currentToken))
    }).catch(function(error){
        // console.error('Unable to get messaging token.', error)
        dispatch(fetchGetMessagingDeviceTokenFail(error))
    })
}

export const pushNotification = (data) => dispatch => {
    dispatch(fetchPushNotification(data))
}

export const deleteNotification = (data) => dispatch => {
    dispatch(fetchDeleteNotification(data))
}

export const deleteAllNotification = (data) => dispatch => {
    setTimeout(() => {
        dispatch(fetchDeleteAllNotification(data))
    }, 300)
}
