import React from 'react'

export default function Diamond() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.90336 13.6934L9.95219 20.751C11.3057 22.0957 12.6944 22.1045 14.0303 20.7686L21.1143 13.6846C22.4502 12.3486 22.4414 10.96 21.0967 9.60644L14.0479 2.55761C12.6944 1.2041 11.3057 1.19531 9.96977 2.54003L2.877 9.62402C1.54106 10.96 1.54985 12.3486 2.90336 13.6934ZM4.22172 12.542C3.64164 11.9619 3.62407 11.3643 4.23051 10.7578L11.1036 3.88476C11.71 3.27831 12.2989 3.29589 12.8877 3.88476L19.7696 10.7666C20.3497 11.3467 20.3672 11.9443 19.7608 12.5508L12.8965 19.4238C12.2901 20.0215 11.6836 20.0039 11.1036 19.4326L4.22172 12.542Z" fill="#8A9CAD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 11.7C3 11.2029 3.40294 10.8 3.9 10.8H20.1C20.5971 10.8 21 11.2029 21 11.7C21 12.197 20.5971 12.6 20.1 12.6H3.9C3.40294 12.6 3 12.197 3 11.7Z" fill="#8A9CAD"/>
    </svg>


}



