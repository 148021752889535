import React, {useState} from "react"

import style from './item.css'

function Component(props) {
    const { text, processText } = props

    const [isProgress, handleProgress] = useState(false)

    const handleButtonPress = e => {
        e.preventDefault()
        handleProgress(true)
    }

    const handleButtonRelease = e => {
        e.preventDefault()
        handleProgress(false)
    }

    return (
        <button
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
            className={style.container}
        >
            { isProgress && <div className={style.longPress} /> }
            <div className={style.children}>{text}</div>
            <div className={style.childrenProcess}>{processText}</div>
        </button>
    )
}

export default Component
