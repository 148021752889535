import { createSelector } from 'reselect'
import _ from 'lodash'

import { UNIT_ENTRY_TYPES } from '../constants'
import { formatPhoneNumber } from '../utils'

const app = state => _.get(state, 'app', {})

export const makeResidentsObject = () =>
    createSelector(app, slice => {
        if (slice.units === undefined) {
            return []
        }
        const residentsObject = _.get(slice, 'units', [])
        return residentsObject.results
    })

export const makeResidentFormInit = () =>
    createSelector(app, slice => {
        const resident = _.get(slice, 'resident')
        if (!resident) return {}

        const units = resident.units.map(({object}) => (
            {
                homeNumber: {
                    id: object?.building.id,
                    label: `${object.building.street} ${object.building.number}`
                },
                flatNumber: {
                    id: object.id,
                    label: object.label
                }
            }))

        return {
            id: resident?.user,
            name: resident?.first_name,
            surname: resident?.last_name,
            gender: resident?.gender,
            phoneNumber: resident?.phone_number,
            email: resident?.email,
            units
        }
    })

export const makeFilteredEntry = () =>
    createSelector(app, slice => {
        const buildingsData = _.get(slice, 'filteredEntry.buildings', [])
        const complexesData = _.get(slice, 'filteredEntry.complexes', [])
        const residentsData = _.get(slice, 'filteredEntry.residents', [])
        const typesData = _.get(slice, 'filteredEntry.types', [])
        const searchString = _.get(slice, 'filteredEntry.search_str')

        const buildings = _.map(buildingsData, i => ({
            id: `building-${i.id}`,
            uid: i.id,
            labelPrev: UNIT_ENTRY_TYPES['building'],
            label: `${i.street} ${i.number}`,
            searchString,
            objectType: 'building',
            searchType: 'object'
        }))

        const complexes = _.map(complexesData, i => ({
            id: `complex-${i.id}`,
            uid: i.id,
            labelPrev: UNIT_ENTRY_TYPES['complex'],
            label: i.name,
            searchString,
            objectType: 'complex',
            searchType: 'object'
        }))

        const residents = _.map(residentsData, i => ({
            id: `resident-${i.id}`,
            uid: i.user,
            labelPrev: UNIT_ENTRY_TYPES['resident'],
            label: `${i.first_name} ${i.last_name}`,
            searchString,
            objectType: 'resident',
            searchType: 'object'
        }))

        const types = _.map(typesData, i => ({
            id: `entry-${i}`,
            uid: i,
            labelPrev: UNIT_ENTRY_TYPES[i],
            label: `..${searchString}..`,
            searchString,
            objectType: i,
            searchType: 'contains'
        }))

        return [ ...buildings, ...complexes, ...residents, ...types ]
    })

export const makeFilteredEntryStatus = () =>
    createSelector(app, slice => {
        return {
            isLoading: _.get(slice, 'isResidentsFilterLoading', false),
            isError: _.get(slice, 'isResidentsFilterError', false),
        }
    })

export const makeImportedResidents = () =>
    createSelector(app, slice => {
        const list = _.get(slice, 'convertedResidents')
        const incorrectResidents = []
        const correctResidents = []

        _.forEach(list, (value) => {
            if (_.isEmpty(value.errors)) {
                correctResidents.push(value)
            } else {
                incorrectResidents.push(value)
            }
        })

        return { correctResidents: _.sortBy(correctResidents, i => parseInt(i.label)), incorrectResidents: _.sortBy(incorrectResidents, i => parseInt(i.label)) }
    })
