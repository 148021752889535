import React from 'react'
import { Field } from 'redux-form'
import _ from 'lodash'

import { Checkbox } from '../../../../../../components/form'
import { BUILDING_TYPES_SHORTCUT } from '../../../../../../__data__/constants'

import style from './group.css'
import PorchesSelect from './porches-select'

function Component (props) {
    const { groupName, buildings, formValues, formName } = props

    return (
        <div>
            <div className={style.title}>{`${groupName} (${_.size(buildings)})`}</div>
            {_.map(buildings, building => {
                if (!building.isEditable) {
                    return (
                        <div className={style.item}>
                            <div className={style.disabledCheckbox}>
                                {`${building.street}, ${building.number}`}
                            </div>
                        </div>
                    )
                }

                const sortedPorches = formValues.sortedPorches[building.id]

                const { buildingName, hasAccess, porches, porchesData, disabledPorches } = sortedPorches

                const name = `sortedPorches.${building.id}.hasAccess`
                const porchesName = `sortedPorches.${building.id}.porches`

                return (
                    <div className={style.item}>
                        <div className={style.fieldWrapper}>
                            <Field
                                name={name}
                                component={Checkbox}
                                type="checkbox"
                                id={building.id}
                                label={buildingName}
                            />
                        </div>
                        <div className={style.buildingType}>{BUILDING_TYPES_SHORTCUT[building.building_type]}</div>
                        {hasAccess && (
                            <div className={style.categoryField}>
                                <Field
                                    name={porchesName}
                                    component={PorchesSelect}
                                    porches={porches}
                                    porchesData={porchesData}
                                    disabledPorches={disabledPorches}
                                    id={porchesName}
                                    buildingId={building.id}
                                    formName={formName}
                                    formValues={formValues}
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default Component
