import React from 'react'
import _ from 'lodash'
import { change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { default as Image } from './components/image'
import style from './style.css'
import {deleteAttachment} from "../../s3/uploader";

function Component(props) {
    const { formValues, formName, changeField, input } = props

    const files = _.get(props, 'input.value', [])

    const handleDeleteClick = event => {
        const fields = { ...formValues[input.name] }
        delete fields[event.target.dataset.id]
        deleteAttachment(input.value[event.target.dataset.id].id)
        changeField(formName, `${input.name}`, fields)
    }
    return (
        <div className={files && style.container}>
            {
                _.map(files, (i, key) => {
                    if (!i) return null

                    return (
                        <div className={style.previewWrapper} key={key}>
                            <div className={style.previewContainer}>
                                <Image name={i.realName} link={i.file} status={i.status} />
                                <span onClick={handleDeleteClick} data-id={key} className={style.previewButton} />
                            </div>
                            <div className={style.fileName} >{i.realName}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
})

const mapDispatchToProps = {
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
