import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeTasksExecutorsList } from '../../../__data__/selectors/task-form'
import { getExecutors } from '../../../__data__/actions/tasks'
import { SelectInput } from '../../../components/form'

function Component(props) {
    const { input, meta, placeholder, tasksExecutorsList, label, buildingSelectValue, serviceId } = props

    useEffect(() => {
        if (buildingSelectValue.type === 'building') {
            props.getExecutors(buildingSelectValue.value, serviceId)
        }
        if (buildingSelectValue.type === 'porch') {
            props.getExecutors(buildingSelectValue.buildingId, serviceId)
        }
    }, [buildingSelectValue?.value, buildingSelectValue?.type, serviceId])

    return (
        <SelectInput placeholder={placeholder} input={input} meta={meta} list={tasksExecutorsList} label={label} />
    )
}

const mapStateToProps = createStructuredSelector({
    tasksExecutorsList: makeTasksExecutorsList(),
})

const mapDispatchToProps = {
    getExecutors,
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
