import React from 'react';
import PageToggle from '../../components/page-toggle';
import {URL_LIST} from "../../__data__/constants";

import style from './style.css';

const items = [
    {
        id: 0,
        title: 'Вручную',
        url: URL_LIST.createResidentForm
    },
    {
        id: 1,
        title: 'Списком',
        url: URL_LIST.downloadResidentForm
    }
]

const ResidentForm = ({children}) => {
    return (
        <div className={style.container}>
            <div className={style.header}>
                <PageToggle items={items} />
            </div>
            <div>{children}</div>
        </div>
    )
}

export default ResidentForm;
