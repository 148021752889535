import {isUserAdmin} from "../../../../__data__/utils";

export const VALUE_SETTING_NEWS = [
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Создавать и редактировать',
    },
]

export const VALUE_SETTING_TASKS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Создавать и редактировать',
    },
    isUserAdmin && {
        value: 'performer',
        label: 'Быть исполнителем',
    },
]


export const VALUE_SETTING_CHATS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Читать и писать',
    },
    {
        value: 'write',
        label: 'Читать и писать',
    },
]

export const VALUE_SETTING_RESIDENTS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Добавлять и редактировать',
    },
]

export const VALUE_SETTING_CAMERAS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Добавлять и редактировать',
    },
]

export const VALUE_SETTING_READINGS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Добавлять и редактировать',
    },
]

export const VALUE_SETTING_EMPLOYEES = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Добавлять и редактировать',
    },
]

export const VALUE_SETTING_OBJECTS = [
    {
        value: 'denied',
        label: 'Скрыты',
    },
    {
        value: 'read',
        label: 'Только просматривать',
    },
    {
        value: 'write',
        label: 'Добавлять и редактировать',
    },
]
