import queryString from 'query-string'

import { setBuildingMetersSettings }  from '../../actions/actions'
import _ from 'lodash'

function submit(values, dispatch) {
    const { start_date, end_date, is_elec, elec_type, is_water, water_type, is_gas, is_heating } = values
    const queryParams = queryString.parse(window.location.search)
    const id = _.get(queryParams, 'id')

    const data = {submit_start_day: start_date, submit_end_day: end_date}
    const reading_types = []
    if (is_water) {
        reading_types.push('water_cold')
        if (water_type === 'water_hot_and_cold') {
            reading_types.push('water_hot')
        }
    }
    if (is_elec) {
        reading_types.push('elec_day')
        if (elec_type === 'elec_day_and_night') {
            reading_types.push('elec_night')
        }
    }
    if (is_gas) {
        reading_types.push('gas')
    }
    if (is_heating) {
        reading_types.push('heating')
    }

    dispatch(setBuildingMetersSettings(id, {...data, reading_types}))
}

export default submit
