import React, {useRef} from 'react'
import style from './style.css';
import PropTypes from 'prop-types';
import {Drafty} from 'tinode-sdk';
import {blobToBase64, imageScaled} from '../../utils/blob-helpers';
import Icon from '../../assets/file-upload-icon.svg'

import {
    IMAGE_PREVIEW_DIM,
    MAX_EXTERN_ATTACHMENT_SIZE,
} from "../../../../constants/chat";

const ImageRegExp = /image\//
const FileTextRegExp = /text\//
const FilePdfRegExp = /application\//

export const Uploader = ({sendMessage, tinode}) => {
    const attachFile = useRef(null);
    let imagePreviewMime = null
    let imagePreviewWidth = null
    let imagePreviewHeight = null
    let imagePreviewFilename = null
    const sendImageAttachment = (blob) => {
        const uploader = tinode.getLargeFileHelper();
        if (!uploader) {
            console.error('Cannot initiate file upload.')
            return;
        }
        const uploadCompletionPromise = uploader.upload(blob);

        // Make small preview to show while uploading.
        imageScaled(blob, IMAGE_PREVIEW_DIM, IMAGE_PREVIEW_DIM, -1, false)
            // Convert tiny image into base64 for serialization and previewing.
            .then(scaled => {
                imagePreviewMime = scaled.mime
                imagePreviewWidth = scaled.width
                imagePreviewHeight = scaled.height
                imagePreviewFilename = scaled.name
                return blobToBase64(scaled.blob)
            })
            .then(b64 => {
                let msg = Drafty.insertImage(null, 0, {
                    mime: imagePreviewMime,
                    _tempPreview: b64.bits, // This preview will not be serialized.
                    bits: b64.bits, // Image thumbnail.
                    width: imagePreviewWidth,
                    height: imagePreviewHeight,
                    filename: imagePreviewFilename,
                    size: blob.size,
                    urlPromise: uploadCompletionPromise
                });
                sendMessage(msg, uploadCompletionPromise, uploader);
            })
            .catch(err => console.error('Error while imageScaled: ' + err));
    }

    const sendFileAttachment = (file) => {
        const uploader = tinode.getLargeFileHelper();
        if(!uploader) {
            console.error('cannot_initiate_upload');
            return;
        }

        const uploadCompletionPromise = uploader.upload(file);
        const msg = Drafty.attachFile(null, {
            mime: file.type,
            filename: file.name,
            size: file.size,
            urlPromise: uploadCompletionPromise
        });
        // Pass data and the uploader to the TinodeWeb.
        sendMessage(msg, uploadCompletionPromise, uploader);
    }

    const handleAttachImage = (file) => {
        sendImageAttachment(file)
    }

    const handleAttachFile = (file, ref) => {
        const maxExternAttachmentSize = tinode.getServerParam('maxFileUploadSize', MAX_EXTERN_ATTACHMENT_SIZE);
        if(file.size > maxExternAttachmentSize) {
            //todo сделать норальную ошибку с размером
            console.error('file_attachment_too_large')
        } else {
            sendFileAttachment(file, ref)
        }
    }

    const handleAttach = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            if (ImageRegExp.test(file.type)) {
                handleAttachImage(file)
            } else if(FilePdfRegExp.test(file.type) || FileTextRegExp.test(file.type)) {
                handleAttachFile(file, attachFile);
            }
        }
    }

    return (
        <label
            htmlFor="uploader"
            className={style.button}
        >
            <input
                hidden
                id="uploader"
                type="file"
                onChange={handleAttach}
            />
            <img src={Icon} alt="uploader-icon"/>
        </label>
    )
}

Uploader.propTypes = {
    tinode: PropTypes.object.isRequired,
    sendMessage: PropTypes.func.isRequired
}
