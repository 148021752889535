import React, {useCallback, useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change } from 'redux-form'
import { createStructuredSelector } from 'reselect'

import { makeBuildingList } from '../../../__data__/selectors/buildings'
import { HomeSelectInput } from '../../../components/form'
import { getBuildingData } from '../../../__data__/actions/buildings'
import { getBuildingsSettings } from '../../../__data__/actions/readings'

function Component(props) {
    const { input, meta, buildingsList, defaultOpen, formName, changeField, label, getBuildingData, getBuildingsSettings } = props

    useEffect(() => {
        if (input.value?.id) {
            getBuildingData(input.value.id)
            getBuildingsSettings(input.value.id)
        }
    }, [input.value])

    const handleChange = useCallback(() => {
        changeField(formName, 'flat_number', null)
        changeField(formName, 'data', {})
    }, [])

    return (
       <HomeSelectInput input={input} meta={meta} list={buildingsList} handleChange={handleChange} label={label} defaultOpen={defaultOpen} />
    )
}

const mapStateToProps = createStructuredSelector({
    buildingsList: makeBuildingList(),
})

const mapDispatchToProps = {
    getBuildingData,
    getBuildingsSettings,
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
