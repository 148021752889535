import { createSelector } from 'reselect'
import _ from 'lodash'

// TODO: Переместить этот селектор в соответствующий странице файл

const app = state => _.get(state, 'app', {})

export const makeSettingsReadings = () =>
    createSelector(app, slice => _.get(slice, 'settingsReadings'))

export const makeBuildingMetersSettings = () => createSelector(app, slice => _.get(slice, 'buildingMetersSettings'))

export const makeEmployeeFormOrganisations = () => createSelector(app, slice => {
    const list = _.get(slice, 'employeeFormOrganisations')
    return _.map(list, i => {
        const postfix = i.is_tenant ? 'головная' : 'дочерняя'
        return ({
            id: i.id,
            label: `${i.label} (${postfix})`,
        })
    })
})
