import { SubmissionError } from 'redux-form'
import { setPassword }  from '../../__data__/actions/login'
import { passwordRepeatValidationMessages } from '../../components/form/validation'

function onSubmitForm(values, token, dispatch) {
    if (values?.password !== values?.passwordConfirm) {
        throw new SubmissionError({error: 'необходимо ввести один и тот же пароль в оба поля'})
    }
    return dispatch(setPassword({password: values?.password}, token))
        .catch((error) => {
        throw new SubmissionError(passwordRepeatValidationMessages[error?.message] || ' ')
    })
}

export default onSubmitForm
