import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import Scroll from 'react-scroll'
import classNames from 'classnames'

import style from './style.css'

function Component(props) {
    const { formName, dispatch } = props

    const scrollTo = () => Scroll.scroller.scrollTo('fieldErrorScrollTarget', {
        duration: 500,
        delay: 50,
        smooth: true,
        offset: -200,
    })

    const handleClick = () => {
        dispatch(submit(formName))
        setTimeout( scrollTo, 100 )
    }

    return (
        <div className={style.wrapper}>
            <button
                className={classNames(style.container, props.isValid ? style.primary : style.secondary)}
                type={props.type}
                onClick={handleClick}
            >
            { props.isValid ? "Сохранить настройки" : "Сохранить настройки" }
            </button>
        </div>
    )
}

export default connect()(Component)
