import React from 'react'
import _ from 'lodash'

import PorchesFields from './porches-fields'
import FloorsFields from './floors-fields'

function Component(props) {
    const { formValues, formName } = props
    const buildingValue = _.get(formValues, 'homeNumber')
    const porches = _.get(formValues, 'porches')
    const hasCheckedPorches = !_.isEmpty(porches) && _.find(porches, i => i === true)

    if (buildingValue.type === 'porch') return <FloorsFields formName={formName} buildingValue={buildingValue} />
    if (buildingValue.type === 'building') return (
        <>
            <PorchesFields formName={formName} buildingValue={buildingValue} />
            {hasCheckedPorches && <FloorsFields formName={formName} buildingValue={buildingValue} />}
        </>
    )
    return null
}

export default Component
