import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import _ from 'lodash'

import Paginator from '../../../../components/paginator'

import { default as EnhancedTableHead } from './head'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    });
    return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '920px',
        marginLeft: '32px',
        marginTop: '20px',
        '& .MuiTableCell-root': {
            fontFamily: 'Open Sans',
        },
    },
    paper: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '2px',
        border: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    tableContainer: {
        overflow: 'unset',
    },
    table: {
        borderColor: '#e3e4e5',
        color: '#2d313a',
        border: 0,
    },
    tbody: {
        border: '1px solid #e3e4e5',
        backgroundColor: '#fff',
        padding: '2px',
    },
    tr: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f8fa !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#f7f8fa !important',
            boxShadow: '0 2px 2px 2px rgba(255,255,255,0.50), inset 0 1px 4px 0 rgba(0,0,0,0.16)',
            '& .MuiTableCell-body': {
                color: '#000',
            }
        }
    },
    nameValue: {
        fontWeight: '600',
    },
    statusValue: {
        paddingRight: '16px',
    },
    value: {
        fontSize: '13px',
        color: '#2d313a',
        padding: '11px 0 11px 16px',
        '&:last-child': {
            paddingRight: '16px',
        },
    },
    hasIconValue: {
        paddingLeft: '16px',
    }
}))

export default function EnhancedTable(props) {
    const { metersObject, handleClick, selected, meterTypes, isAllBuildings, getMetersListPagination, dateString, checkedBuildings } = props

    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const isSelected = (userId) => userId === selected

    const getDataPagination = (page, countPerPage) => {
        getMetersListPagination(checkedBuildings, dateString, page, countPerPage)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            meterTypes={meterTypes}
                            isAllBuildings={isAllBuildings}
                        />
                        <TableBody className={classes.tbody}>
                            {stableSort(metersObject.list, getComparator(order, orderBy))
                                .map((row) => {
                                    const isItemSelected = isSelected(row.flatId)

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.flatId)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.userId}
                                            selected={isItemSelected}
                                            className={classes.tr}
                                        >
                                            { isAllBuildings &&
                                                <TableCell className={classes.value} align="left">
                                                    <div>{row.homeNumber}</div>
                                                </TableCell>
                                            }
                                            <TableCell className={classes.value} align="left">
                                                <div>{row.flatNumber}</div>
                                            </TableCell>
                                            <TableCell className={classNames(classes.value, classes.nameValue)}>
                                                <div>{`${row['lastName']} ${row['firstName']}`}</div>
                                            </TableCell>
                                            <TableCell className={classes.value} align="left">
                                                <div>{row.date}</div>
                                            </TableCell>
                                            {
                                                _.includes(meterTypes, 'water_cold') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.water_cold}</div>
                                                    </TableCell>
                                                )
                                            }
                                            {
                                                _.includes(meterTypes, 'water_hot') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.water_hot}</div>
                                                    </TableCell>
                                                )
                                            }
                                            {
                                                _.includes(meterTypes, 'elec_day') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.elec_day}</div>
                                                    </TableCell>
                                                )
                                            }
                                            {
                                                _.includes(meterTypes, 'elec_night') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.elec_night}</div>
                                                    </TableCell>
                                                )
                                            }
                                            {
                                                _.includes(meterTypes, 'gas') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.gas}</div>
                                                    </TableCell>
                                                )
                                            }
                                            {
                                                _.includes(meterTypes, 'heating') && (
                                                    <TableCell className={classes.value} align="left">
                                                        <div className={classes.hasIconValue}>{row.monthMeters.heating}</div>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paginator tableObject={metersObject} getDataPagination={getDataPagination} />
            </Paper>
        </div>
    )
}
