import React, { Component } from 'react'
import enhanceWithClickOutside from 'react-click-outside'

import { PROFILE_USER_ID, URL_LIST } from '../../../../../../__data__/constants'

import { default as Link } from './link'
import { default as Action } from './action'
import { default as ActionWrapperBlock } from './action-wrapper-block'
import { default as ActionWrapperDelete } from './action-wrapper-delete'
import style from './style.css'
import classNames from "classnames";
import { checkWritePermissionForSection } from "../../../../../../__data__/actions/permissions";
import LocalStorageUtil from "../../../../../chat-new/utils/local-storage";

class Comp extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    handleClick = (e) => {
        e.preventDefault()
        this.setState({ isOpen: !this.state.isOpen })
    }

    /* На самом деле он используется в enhanceWithClickOutside. Вот такая неявность. */
    handleClickOutside= () => {
        this.setState({ isOpen: false })
    }

    render() {
        return (
            <div className={style.container}>
                <a href='/' onClick={this.handleClick} className={classNames(style.trigger, (!checkWritePermissionForSection('Сотрудники') || LocalStorageUtil.getObject(PROFILE_USER_ID) === this.props.user) && style.disabled)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="3" viewBox="0 0 12 3">
                        <path className={style.triggerSymbol} fill="#7D8B8D" fillRule="evenodd" d="M1.5,0 C2.32842712,0 3,0.671572875 3,1.5 C3,2.32842712 2.32842712,3 1.5,3 C0.671572875,3 0,2.32842712 0,1.5 C0,0.671572875 0.671572875,0 1.5,0 Z M6,0 C6.82842712,0 7.5,0.671572875 7.5,1.5 C7.5,2.32842712 6.82842712,3 6,3 C5.17157288,3 4.5,2.32842712 4.5,1.5 C4.5,0.671572875 5.17157288,0 6,0 Z M10.5,0 C11.3284271,0 12,0.671572875 12,1.5 C12,2.32842712 11.3284271,3 10.5,3 C9.67157288,3 9,2.32842712 9,1.5 C9,0.671572875 9.67157288,0 10.5,0 Z"/>
                    </svg>
                </a>
                {this.state.isOpen && (
                    <div className={style.menuContainer}>
                        <Link href={`${URL_LIST.settingsEditEmployee}?id=${this.props.user}`}>Редактировать</Link>

                        <ActionWrapperBlock callback={this.handleClickOutside} status={this.props.status} userID={this.props.user} checkedBuildings={this.props.checkedBuildings}>
                        </ActionWrapperBlock>
                        <ActionWrapperDelete userID={this.props.user} checkedBuildings={this.props.checkedBuildings}>
                            <Action text='Удалить из системы' processText='Удерживайте...' />
                        </ActionWrapperDelete>
                    </div>
                )}
            </div>
        )
    }
}

export default enhanceWithClickOutside(Comp)
