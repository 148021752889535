import React from 'react'

import style from './style.css'

function Component(props) {
    const { input, id, label } = props

    return (
        <div className={style.container} data-tip={props.dataTip}>
            <input {...input} id={id} type='checkbox' className={style.field} />
            <label htmlFor={id} className={style.label}>
                <span className={style.labelText}>{`№ ${label}`}</span>
                { input.value && (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3273 4.68382C12.9482 4.3078 12.3348 4.31476 11.9644 4.69929L6.5577 10.3121L3.99111 7.43123C3.63681 7.03355 3.02191 7.01304 2.6419 7.38623C2.28376 7.73794 2.26841 8.31008 2.60717 8.68049L5.84229 12.2178C6.23048 12.6422 6.89582 12.6525 7.29693 12.2403L13.3392 6.03031C13.7052 5.65417 13.6999 5.05343 13.3273 4.68382Z" fill="black" />
                    </svg>
                )}
            </label>
        </div>
    )
}

export default Component
