import React from 'react'

import style from './style.css'

export default function Component(props) {
    const { label, value } = props

    return (
        <div>
            <div className={style.label}>{label}</div>
            <div className={style.value}>{value}</div>
        </div>
    )
}
