import _ from 'lodash'

import { createEmployee } from '../../../actions/staff-actions'

const getData = (values) => ({
    first_name: _.get(values, 'firstName'),
    last_name: _.get(values, 'lastName'),
    phone_number: _.get(values, 'phoneNumber'),
    company: _.get(values, 'company.id'),
    position: _.get(values, 'position'),
    gender: _.get(values, 'gender'),
})

export function submit(values, dispatch) {
    const data = getData(values)
    dispatch(createEmployee(data))
}

export default submit
