import React, { useCallback, useState } from 'react'
import Avatar from 'react-avatar-edit'
import { change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import style from './style.css'

function Component(props) {
    const { formName, changeField, input } = props

    const [preview, setPreview] = useState(null)

    const onClose = () => {
        changeField(formName, input.name, '')
        setPreview(null)
    }

    const onBeforeFileLoad = useCallback((elem) => {
        if (elem.target.files[0].size > 7168000) {
            alert("Файл слишком большой(")
            elem.target.value = ""
        }
    }, [])

    const onCrop = preview => {
        setPreview(preview)
        changeField(formName, input.name, preview)
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <div className={style.avatarContainer}>
                    <Avatar
                        width='400'
                        height='400'
                        onCrop={onCrop}
                        onBeforeFileLoad={onBeforeFileLoad}
                        onClose={onClose}
                        // exportMimeType='image/jpeg'
                        label='Нажмите и выберите или перетащите файлы в эту область'
                        labelStyle={{fontSize:'12px'}}
                    />
                </div>
                { preview && (
                    <div className={style.previewContainer}>
                        <img src={preview} className={style.preview} alt="Preview" />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
})

const mapDispatchToProps = {
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
