import React, {useCallback, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import classNames from 'classnames'
import moment from 'moment'

import { fetchSetTasksFiltersDate } from '../../../../__data__/actions/tasks'
import { makeTasksFilterDate } from '../../../../__data__/selectors/tasks'

import style from './style.css'

const dateValues = {
    '1': moment().startOf('day').format('X'),
    '2': moment().subtract(7, 'days').startOf('day').format('X'),
    '3': moment().subtract(30, 'days').startOf('day').format('X'),
}

const Component = props => {
    const { fetchSetTasksFiltersDate } = props

    const [active, setActive] = useState('1')

    useEffect(() => {
        fetchSetTasksFiltersDate(dateValues[active])
    }, [])

    const handleActive = useCallback((e) => {
        e.preventDefault()
        const activeDate = dateValues[e.target.dataset.key]
        fetchSetTasksFiltersDate(activeDate)
        setActive(e.target.dataset.key)
    }, [])

    return (
        <ul className={style.list}>
            <li data-key='1' onClick={handleActive} className={classNames(style.item, active === '1' && style.active)}>Сегодня</li>
            <li data-key='2' onClick={handleActive} className={classNames(style.item, active === '2' && style.active)}>Неделя</li>
            <li data-key='3' onClick={handleActive} className={classNames(style.item, active === '3' && style.active)}>Месяц</li>
        </ul>
    )
}

const mapStateToProps = createStructuredSelector({
    tasksFilterDate: makeTasksFilterDate(),
})

const mapDispatchToProps = {
    fetchSetTasksFiltersDate,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
