import React, {useEffect} from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { makePasswordGeneration } from '../../__data__/selectors/login'
import { CHAT_TOKEN, MODERATOR_STORAGE_NAME } from '../../__data__/constants'

import PhoneNumberForm from './components/confirmation-phone-number-form'
import ConfirmationCodeForm from './components/confirmation-code-form'
import style from './style.css'

function Component({ passwordGeneration }) {

    // Разлогиниваем
    useEffect(() => {
        localStorage.removeItem(MODERATOR_STORAGE_NAME)
        localStorage.removeItem(CHAT_TOKEN)
    }, [])

    const isConfirmFields = passwordGeneration?.isRSFinished && !passwordGeneration?.isError

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                { !isConfirmFields ?
                    <PhoneNumberForm /> :
                    <ConfirmationCodeForm />
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    passwordGeneration: makePasswordGeneration(),
})

const mapDispatchToProps = {

}

const withConnect = connect(
    mapStateToProps, mapDispatchToProps
)

export default compose(withConnect)(Component)
