import React, {useCallback, useEffect} from 'react'
import DropdownList from 'react-widgets/lib/DropdownList'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import { makeTasksServiceList } from '../../../../__data__/selectors/task-form'
import { makeCheckedBuildings } from '../../../../__data__/selectors/common'
import { makeTasksFilterType } from '../../../../__data__/selectors/tasks'
import {
    getServices,
    fetchSetTasksFiltersType,
} from '../../../../__data__/actions/tasks'

import style from './style.css'

const ListItem = ({ item }) => (
    <div className={style.item}>
        <div className={style.itemText}>{item.label}</div>
    </div>
)

const inputProps = { spellcheck: "false" }

let ValueInput = ({ item }) => item ? (
    <div className={style.item}>
        <div className={style.itemText}>{item.label}</div>
    </div>
) : ''

function Component(props) {
    const {
        checkedBuildings,
        getServices,
        tasksServiceList,
        fetchSetTasksFiltersType,
        tasksFilterType,
    } = props

    const isListEmpty = _.size(tasksServiceList) === 1

    useEffect(() => {
        checkedBuildings && getServices(checkedBuildings)
    }, [checkedBuildings])

    useEffect(() => {
        !isListEmpty && fetchSetTasksFiltersType(_.head(tasksServiceList))
    }, [isListEmpty])

    const onChange = useCallback(e => {
        fetchSetTasksFiltersType(e)
    }, [])

    if (isListEmpty) return null

    return (
        <div className={style.container}>
            <DropdownList
                onFocus={() => null}
                textField='label'
                itemComponent={ListItem}
                onChange={onChange}
                data={tasksServiceList}
                inputProps={inputProps}
                valueComponent={ValueInput}
                placeholder='тип услуги'
                className='types-filter'
                value={tasksFilterType}
            />
        </div>
    )
}

Component.propTypes = {
    defaultOpen: PropTypes.bool,
}

Component.defaultProps = {
    defaultOpen: false,
}

const mapStateToProps = createStructuredSelector({
    checkedBuildings: makeCheckedBuildings(),
    tasksServiceList: makeTasksServiceList(),
    tasksFilterType: makeTasksFilterType(),
})

const mapDispatchToProps = {
    getServices,
    fetchSetTasksFiltersType,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
