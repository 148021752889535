import axios from 'axios'
import Config from 'Config'

import * as types from '../actions-types'
import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchGetNewsList = () => ({ type: types.GET_NEWS_LIST })
export const fetchGetNewsListSuccess = (data) => ({ type: types.GET_NEWS_LIST_SUCCESS, data })
export const fetchGetNewsListFail = (error) => ({ type: types.GET_NEWS_LIST_FAIL, error })

export const fetchDeleteNews = () => ({ type: types.DELETE_NEWS })
export const fetchDeleteNewsSuccess = (data) => ({ type: types.DELETE_NEWS_SUCCESS, data })
export const fetchDeleteNewsFail = (error) => ({ type: types.DELETE_NEWS_FAIL, error })

export const fetchCreateNews = () => ({ type: types.CREATE_NEWS })
export const fetchCreateNewsSuccess = (data) => ({ type: types.CREATE_NEWS_SUCCESS, data })
export const fetchCreateNewsFail = (error) => ({ type: types.CREATE_NEWS_FAIL, error })

export const fetchGetNews = () => ({ type: types.GET_NEWS })
export const fetchGetNewsSuccess = (data) => ({ type: types.GET_NEWS_SUCCESS, data })
export const fetchGetNewsFail = (error) => ({ type: types.GET_NEWS_FAIL, error })

export const fetchGetNextNewsList = () => ({ type: types.GET_NEXT_NEWS_LIST })
export const fetchGetNextNewsListSuccess = (data) => ({ type: types.GET_NEXT_NEWS_LIST_SUCCESS, data })
export const fetchGetNextNewsListFail = (error) => ({ type: types.GET_NEXT_NEWS_LIST_FAIL, error })

export const fetchEditNews = () => ({ type: types.EDIT_NEWS })
export const fetchEditNewsSuccess = (data) => ({ type: types.EDIT_NEWS_SUCCESS, data })
export const fetchEditNewsFail = (error) => ({ type: types.EDIT_NEWS_FAIL, error })

export const fetchCreateS3Attachments = () => ({ type: types.POST_S3_ATTACHMENTS })
export const fetchCreateS3AttachmentsSuccess = (data) => ({ type: types.POST_S3_ATTACHMENTS_SUCCESS, ...data })
export const fetchCreateS3AttachmentsFail = (error) => ({ type: types.POST_S3_ATTACHMENTS_FAIL, error })

export const fetchSetCarousel = (data) => ({ type: types.SET_CAROUSEL, data })
export const fetchSetStori = (data) => ({ type: types.SET_STORI, data })

export const fetchGetStoriList = () => ({ type: types.GET_STORI_LIST })
export const fetchGetStoriListSuccess = (data) => ({ type: types.GET_STORI_LIST_SUCCESS, data })
export const fetchGetStoriListFail = (error) => ({ type: types.GET_STORI_LIST_FAIL, error })

export const fetchGetStoriFull = () => ({ type: types.GET_STORI_FULL })
export const fetchGetStoriFullSuccess = (data) => ({ type: types.GET_STORI_FULL_SUCCESS, data })
export const fetchGetStoriFullFail = (error) => ({ type: types.GET_STORI_FULL_FAIL, error })

export const getNewsList = buildingsId => dispatch => {
    dispatch(fetchGetNewsList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/news/?buildings_id=${buildingsId}&page_size=20`,
    })
        .then(response => {
            dispatch(fetchGetNewsListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetNewsListFail(error))
        })
}

export const getNextNewsList = url => dispatch => {
    dispatch(fetchGetNextNewsList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url,
    })
        .then(response => {
            dispatch(fetchGetNextNewsListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetNextNewsListFail(error))
        })
}

export const deleteNews = (data) => dispatch => {
    dispatch(fetchDeleteNews())

    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/news/${data.newsId}/`,
    })
        .then(response => {
            dispatch(fetchDeleteNewsSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchDeleteNewsFail(error))
        })
}

export const createNews = (data) => dispatch => {
    dispatch(fetchCreateNews())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/news/',
        ...data,
    })
        .then(response => {
            dispatch(fetchCreateNewsSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchCreateNewsFail(error))
        })
}

export const getNews = newsId => dispatch => {
    dispatch(fetchGetNews())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/news/${newsId}/`,
    })
        .then(response => {
            dispatch(fetchGetNewsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetNewsFail(error))
        })
}

export const editNews = (data, id) => dispatch => {
    dispatch(fetchEditNews())

    return axios({
        method: 'PATCH',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/news/${id}/`,
        data,
    })
        .then(response => {
            dispatch(fetchEditNewsSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchEditNewsFail(error))
        })
}

export const setCarousel = data => dispatch => {
    dispatch(fetchSetCarousel(data))
}

export const getStoryList = buildingsId => dispatch => {
    dispatch(fetchGetStoriList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/booklets/?buildings_id=${buildingsId}`,
    })
        .then(response => {
            dispatch(fetchGetStoriListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetStoriListFail(error))
        })
}

export const getStoriFull = (id) => dispatch => {
    dispatch(fetchGetStoriFull())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/booklets/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetStoriFullSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetStoriFullFail(error))
        })
}

export const setStori = data => dispatch => {
    dispatch(fetchSetStori(data))
}
