import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeTasksSortedList = () =>
    createSelector(app, slice => {
        const tasks = _.get(slice, 'tasks')

        return {
            created: _.sortBy(tasks.created, i => i.order),
            in_progress: _.sortBy(tasks.in_progress, i => i.order),
            done: _.sortBy(tasks.done, i => i.order),
        }
    })

export const makeIsTasksLoading = () =>
    createSelector(app, slice => _.includes(_.get(slice, 'isTasksLoading'), true))

export const makeTasksNextLoadingStatus = () =>
    createSelector(app, slice => _.get(slice, 'isTasksUpdating'))

export const makeActiveTask = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'activeTask')
        const floors = _.map(_.get(data, 'target.floors'), i => i.number).join(', ')
        const porches = _.map(_.get(data, 'target.porches'), i => i.number).join(', ')

        return {
            name: _.get(data, 'service.name'),
            id: _.get(data, 'id'),
            street: _.get(data, 'target.building.street'),
            homeNumber: _.get(data, 'target.building.number'),
            buildingId: _.get(data, 'target.building.id'),
            floors,
            porches,
            icon: _.get(data, 'service.icon'),
            serviceId: _.get(data, 'service.id'),
            performer: _.get(data, 'performer'),
            stage: _.get(data, 'stage'),
            resolution: _.get(data, 'resolution'),
            history: _.reverse(_.get(data, 'history')),
            taskName: _.get(data, 'task_name')
        }
    })

export const makeDetailsLoadingStatus = () =>
    createSelector(app, slice => _.get(slice, 'isTaskDetailsLoading'))

export const makeTasksCount = () =>
    createSelector(app, slice => _.get(slice, 'tasksCount'))

export const makePerformerFormData = (taskId) =>
    createSelector(app, slice => {
        const data = _.get(slice, 'performerFormTask')
        return {
            buildingId: _.get(data, 'target.building.id'),
            serviceId: _.get(data, 'service.id'),
            serviceName: _.get(data, 'service.name'),
            street: _.get(data, 'target.building.street'),
            homeNumber: _.get(data, 'target.building.number'),
            stage: _.get(data, 'stage')
        }
    })

export const makePerformerFormTasksExecutorsList = () =>
    createSelector(app, slice => {
        return _.get(slice, 'serviceExecutors')
    })

export const makeIsPerformerFormTaskDetailsLoading = () =>
    createSelector(app, slice => _.get(slice, 'isPerformerFormTaskDetailsLoading'))

export const makeTasksFilterDate = () =>
    createSelector(app, slice => _.get(slice, 'tasksFilterDate'))

export const makeTasksFilterType = () =>
    createSelector(app, slice => _.get(slice, 'tasksFilterType'))

export const makeIsTaskPatchError = () =>
    createSelector(app, slice => _.get(slice, 'isTaskPatchError'))
