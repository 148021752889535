import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import _ from 'lodash'
import classNames from 'classnames'

import { CheckboxToggle, RadioGroupMenu } from '../../../../../../components/form'
import {
    VALUE_SETTING_CAMERAS,
    VALUE_SETTING_CHATS, VALUE_SETTING_EMPLOYEES,
    VALUE_SETTING_NEWS, VALUE_SETTING_OBJECTS,
    VALUE_SETTING_READINGS,
    VALUE_SETTING_RESIDENTS,
    VALUE_SETTING_TASKS
} from '../../constants'
import style from '../../../style.css'

import { radioValidation } from './validation'

function Component (props) {
    const { formValues } = props

    const isTasks = _.get(formValues, 'isTasks')
    const isChats = _.get(formValues, 'isChats')
    const isResidents = _.get(formValues, 'isResidents')
    const isReadings = _.get(formValues, 'isReadings')
    const isCameras = _.get(formValues, 'isCameras')
    const isSettings = _.get(formValues, 'isSettings')

    return (
        <Fragment>
            <div className={classNames(style.fieldsSection)}>
                <div className={style.labelRadioGroup}>Новости</div>
                <div className={style.radioGroupWrapper}>
                    <RadioGroupMenu name='news' items={VALUE_SETTING_NEWS} />
                </div>
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isTasks'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isTasks'
                    label='Заявки'
                />
                {isTasks && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu
                            name='tasks'
                            items={VALUE_SETTING_TASKS}
                            validate={[radioValidation]}
                        />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isChats'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isChats'
                    label='Чаты'
                />
                {isChats && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='chats' items={VALUE_SETTING_CHATS} validate={[radioValidation]} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isResidents'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isResidents'
                    label='Жильцы'
                />
                {isResidents && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='residents' items={VALUE_SETTING_RESIDENTS} validate={[radioValidation]} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isReadings'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isReadings'
                    label='Показания'
                />
                {isReadings && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='readings' items={VALUE_SETTING_READINGS} validate={[radioValidation]} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isCameras'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isCameras'
                    label='Камеры'
                />
                {isCameras && (
                    <div className={style.radioGroupWrapper}>
                        <RadioGroupMenu name='cameras' items={VALUE_SETTING_CAMERAS} validate={[radioValidation]} />
                    </div>
                )}
            </div>
            <div className={classNames(style.fieldsSection)}>
                <Field
                    name='isSettings'
                    component={CheckboxToggle}
                    type="checkbox"
                    id='isSettings'
                    label='Настройки'
                />
                {isSettings && (
                    <Fragment>
                        <div className={style.radioGroupWrapper}>
                            <div className={style.labelRadioGroup}>Сотрудники</div>
                            <RadioGroupMenu name='employees' items={VALUE_SETTING_EMPLOYEES} />
                        </div>
                        <div className={style.radioGroupWrapper}>
                            <div className={style.labelRadioGroup}>Объекты</div>
                            <RadioGroupMenu name='buildingsSettings' items={VALUE_SETTING_OBJECTS} validate={[radioValidation]} />
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default Component
