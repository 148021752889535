import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeBuildingsObject = () =>
    createSelector(app, slice => _.get(slice, 'settingsBuildings', {}))

export const makeBuildingObject = () =>
    createSelector(app, slice => _.get(slice, 'settingsObject', {}))


