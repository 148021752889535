import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose } from 'redux'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'

import { makeNewsBuildingsList } from '../../../__data__/selectors/news-form'
import { getBuildingData, getPorchData } from '../../../__data__/actions/buildings'
import { NEWS_BUILDING_MENU_ITEMS_ID, NEWS_BUILDING_SELECT_ITEMS } from '../../../__data__/constants'
import { HomeSelectInput } from '../../../components/form'

function Component(props) {
    const { input, meta, buildingsList, defaultOpen, formName, changeField, label } = props

    useEffect(() => {
        if (input.value.value && !_.has(NEWS_BUILDING_SELECT_ITEMS, input.value.value)) {
            if (input.value.type === 'porch') props.getPorchData(input.value.value)
            if (input.value.type === 'building') props.getBuildingData(input.value.value)
        }
    }, [input.value])

    const handleChange = useCallback(() => {
        changeField(formName, 'porchPropsToggle', NEWS_BUILDING_MENU_ITEMS_ID.porch)
        changeField(formName, 'buildingPropsToggle', NEWS_BUILDING_MENU_ITEMS_ID.home)
        changeField(formName, 'flats', [])
        changeField(formName, 'porches', null)
        changeField(formName, 'floors', null)
        changeField(formName, 'pipes', null)
    }, [])

    return (
        <HomeSelectInput input={input} meta={meta} list={buildingsList} handleChange={handleChange} label={label} defaultOpen={defaultOpen} />
    )
}

const mapStateToProps = createStructuredSelector({
    buildingsList: makeNewsBuildingsList(),
})

const mapDispatchToProps = {
    getBuildingData,
    getPorchData,
    changeField: change,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
