import React, { useState } from 'react'
import { useTimer } from  'reactjs-countdown-hook'
import style from "../../login-form/style.css";

function Component({ interval, onFinish }) {

    const [isDisabled, setDisabled] = useState(false)

    const handleTimerFinish = () => {
        onFinish()
        setDisabled(true)
    }
    const {
        seconds,
        minutes,
        hours,
    } = useTimer(interval, handleTimerFinish)

    if (!seconds) return null
    if (isDisabled) return null

    return (
        <div className={style.timerTooltip}>{`через ${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`}</div>
    )
}

export default Component
