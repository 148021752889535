import React from 'react';
import PropTypes from 'prop-types';
import style from '../style.css';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

export const FormHeader = ({isFormChanged, goBackLinkUrl, goBackLinkText, pageTitle}) => {
    return (
        <div className={style.headerWrapper}>
            <div className={style.header}>
                <div data-tip='изменения не сохранятся' data-for='tip-return-link-alert'>
                    <Link
                        className={classNames(style.goListLink, isFormChanged && style.goListLinkAlert)}
                        to={goBackLinkUrl}
                    >
                        <div
                            className={classNames(style.goListLinkIcon, isFormChanged && style.goListLinkIconAlert)}>L
                        </div>
                        <div>{goBackLinkText}</div>
                    </Link>
                </div>
                {isFormChanged && <ReactTooltip place='bottom' id='tip-return-link-alert' className={style.tooltip}/>}
            </div>
            {pageTitle &&
            <>
                <h1 className={style.title}>{pageTitle}</h1>
                <div className={style.titleBorder}/>
            </>
            }
        </div>
    )
}

FormHeader.propTypes = {
    isFormChanged: PropTypes.bool,
    goBackLinkUrl: PropTypes.string,
    goBackLinkText: PropTypes.string,
    pageTitle: PropTypes.string
}
