import React, { useRef, useEffect } from 'react'
import classNames  from 'classnames'
import PropTypes from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'
import _ from 'lodash'

import useOutsideClick from '../custom-hooks/useOutsideClick'

import style from './style.css'

function Component(props) {
    const { options, textBeforeValue, id, change, onChange, value, listUp } = props
    const [isOpenDropdown, setOpenDropdown] = React.useState(false);
    const [valueSelect, setValueSelect] = React.useState(value);
    const ref = useRef();

    const onClick =  (e) => {
        const valueElement = e.currentTarget.dataset.id
        const value = options.find((elem) => elem.value == valueElement)
        setValueSelect(value)
        onChange(value)
        setOpenDropdown(false)
    }

    const onClickSelect = () => {
        setOpenDropdown(!isOpenDropdown)
    }

    useEffect(
        () => {
            setValueSelect(value)
        },
        [value],
    )

    useOutsideClick(ref, () => {
        setOpenDropdown(false)
    })

    const isSelectCur = (cur) => {
        return  valueSelect.value == cur.value
    }

    return (
        <div className={classNames(style.container, style.container_border )} ref={ref}>
            <div className={style.container}>
                <div onClick={onClickSelect}>
                    <span className={style.beforeValueText}>{textBeforeValue}</span> <span className={style.valueText}>{valueSelect.label}</span>
                    <svg className={classNames(style.valueIcon, !isOpenDropdown && style.valueActiveIcon)} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.32562 0.281509C4.69807 -0.0938358 5.30193 -0.0938358 5.67438 0.281509L9.72066 4.35925C10.0931 4.73459 10.0931 5.34315 9.72066 5.71849C9.34821 6.09384 8.74435 6.09384 8.3719 5.71849L5 2.32038L1.6281 5.71849C1.25565 6.09384 0.651788 6.09384 0.279337 5.71849C-0.0931125 5.34315 -0.0931125 4.73459 0.279338 4.35925L4.32562 0.281509Z" fill="#1E1F21"/>
                    </svg>
                </div>  {
                isOpenDropdown && (
                    <div style={listUp && {top: -(options.length * 38 + options.length * 8) }} className={classNames(style.selectAllButtonWrapper)}>
                        {_.map(options, (cur) => {
                            return (
                                <div key={cur.value} onClick={onClick} data-id={`${cur.value}`} className={classNames(style.selectAllButtonContainer, isSelectCur(cur) && style.selectButton)}>
                                    <span  className={style.selectAllButton} >{cur.label}</span>
                                    {isSelectCur(cur) && (
                                        <div className={classNames(style.icon)}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3273 4.68382C12.9482 4.3078 12.3348 4.31476 11.9644 4.69929L6.5577 10.3121L3.99111 7.43123C3.63681 7.03355 3.02191 7.01304 2.6419 7.38623C2.28376 7.73794 2.26841 8.31008 2.60717 8.68049L5.84229 12.2178C6.23048 12.6422 6.89582 12.6525 7.29693 12.2403L13.3392 6.03031C13.7052 5.65417 13.6999 5.05343 13.3273 4.68382Z" fill="black" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
}

Component.defaultProps = {
    size: 'auto',
}

export default enhanceWithClickOutside(Component)
