import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, getFormValues, Field } from 'redux-form'
import _ from 'lodash'

import {
    FORM_NAMES, URL_LIST,
    TASK_FORM_FIELDS_ERRORS,
    TASK_PORCHES_ITEMS_ID,
    TASK_FLOORS_ITEMS_ID,
} from '../../__data__/constants'
import { FormConstructor, TextArea } from '../../components/form'
import HomeSelectInput from '../../components/task-form-fields/home-select-input'
import TaskSelectInput from '../../components/task-form-fields/task-select-input'
import ExecutorSelectInput from '../../components/task-form-fields/executor-select-input'

import formStyle from '../../theme/form.css'
import {
    formDescriptionValidateMax,
    formDescriptionValidateMin,
    homeNumberRequired,
    taskRequired,
    filesValidation,
} from '../../components/form/validation'
import TargetFactory from '../../components/task-form-fields/form-factory'
import UploadComponent from '../../components/s3/uploader'

import submit from './submit'

function Component(props) {
    useEffect(() => {
        props.initialize({
            porchesToggle: TASK_PORCHES_ITEMS_ID.home,
            floorsToggle: TASK_FLOORS_ITEMS_ID.all,
            porches: [],
            floors: [],
        })
    }, [])

    const buildingSelectValue = _.get(props, 'formValues.homeNumber')
    const taskTypeSelectValue = _.get(props, 'formValues.taskType.value')

    return (
        <FormConstructor
            pageTitle='Где и в чём нужна помощь?'
            goBackLinkText='Отменить и вернуться'
            goBackLinkUrl={URL_LIST.tasks}
            columnCount='5'
            buttonText='Создать заявку'
            formName={props.formName}
            onSubmit={submit}
            fieldErrors={TASK_FORM_FIELDS_ERRORS}
        >
            <Fragment>
                <div className={formStyle.groupSection}>
                    <Field
                        name="homeNumber"
                        component={HomeSelectInput}
                        label="Объект"
                        validate={[homeNumberRequired]}
                        defaultOpen={true}
                        formName={props.formName}
                    />
                    { buildingSelectValue && <TargetFactory formName={props.formName} formValues={props.formValues} /> }
                </div>
                {
                    buildingSelectValue && (
                        <Fragment>
                            <div className={formStyle.groupSection}>
                                <Field
                                    name="taskType"
                                    component={TaskSelectInput}
                                    label="Тип услуги"
                                    validate={[taskRequired]}
                                    formName={props.formName}
                                    buildingSelectValue={buildingSelectValue}
                                />
                                <Field
                                    name="description"
                                    component={TextArea}
                                    type="text"
                                    label="Описание заявки"
                                    value=''
                                    placeholder="Расскажите, что случилось"
                                    validate={[formDescriptionValidateMin, formDescriptionValidateMax]}
                                />
                                <Field
                                    name="files"
                                    component={UploadComponent}
                                    value=''
                                    formName={props.formName}
                                    validate={[filesValidation]}
                                />
                            </div>
                            { taskTypeSelectValue &&
                                <div className={formStyle.groupSection}>
                                    <Field
                                        name="performer"
                                        component={ExecutorSelectInput}
                                        label="Исполнитель"
                                        placeholder="назначить позже"
                                        buildingSelectValue={buildingSelectValue}
                                        serviceId={taskTypeSelectValue}
                                    />
                                </div>
                            }
                        </Fragment>
                    )
                }
            </Fragment>
        </FormConstructor>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
})

const withConnect = connect(
    mapStateToProps,
)

Component = reduxForm({
    form: FORM_NAMES.createTask,
    onSubmit: submit
}) (Component)

export default compose(withConnect)(Component)
