import React, { useCallback, useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { clearTaskPatchError } from '../../../../__data__/actions/tasks'

import style from './style.css'

function Component(props) {
    const handleClick = useCallback(e => {
        e.preventDefault()
        props.clearTaskPatchError()
        document.location.reload()
    }, [])

    useEffect(() => {
        // returned function will be called on component unmount
        return () => {
            props.clearTaskPatchError()
        }
    }, [])

    return (
        <div className={style.container}>
            <div>
                <div className={style.imgWrapper}>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_3862:645)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.276708 22.2533C2.17221 16.4036 23.8659 0.600006 30 0.600006C36.1339 0.600006 57.8278 16.4036 59.7233 22.2533C61.6188 28.1026 53.3324 53.6735 48.37 57.2887C43.4075 60.9038 16.5925 60.9038 11.63 57.2887C6.66736 53.6735 -1.61879 28.1026 0.276708 22.2533ZM45.0661 52.712C45.1613 52.6025 45.3725 52.3481 45.6877 51.8709C46.2013 51.0932 46.8023 50.0147 47.4574 48.656C48.7642 45.9457 50.1158 42.477 51.2863 38.8649C52.4568 35.2529 53.3968 31.6501 53.9283 28.6876C54.1948 27.2024 54.3407 25.976 54.381 25.0448C54.4056 24.4777 54.3844 24.1488 54.3716 24.0033C54.2966 23.8779 54.1207 23.5987 53.7676 23.1534C53.1886 22.4232 52.3511 21.5165 51.2645 20.4717C49.0972 18.3875 46.2241 16.0263 43.1592 13.7936C40.0944 11.5608 36.9667 9.55064 34.3198 8.12765C32.9928 7.4143 31.8735 6.89559 31.0012 6.56877C30.4624 6.36693 30.1409 6.28557 30 6.25327C29.8591 6.28557 29.5375 6.36693 28.9987 6.56878C28.1264 6.8956 27.0071 7.41431 25.6801 8.12766C23.0332 9.55064 19.9056 11.5608 16.8407 13.7935C13.7758 16.0263 10.9028 18.3875 8.73545 20.4717C7.64891 21.5165 6.81145 22.4232 6.23244 23.1534C5.87934 23.5987 5.70344 23.8779 5.62839 24.0033C5.61563 24.1488 5.59438 24.4777 5.61895 25.0448C5.65931 25.976 5.80522 27.2024 6.07168 28.6876C6.6032 31.6501 7.54311 35.2529 8.7136 38.8649C9.88409 42.477 11.2357 45.9457 12.5425 48.656C13.1976 50.0147 13.7987 51.0932 14.3122 51.8708C14.6274 52.3481 14.8387 52.6025 14.9339 52.712C15.0674 52.7691 15.3749 52.8924 15.9269 53.046C16.8244 53.2957 18.0342 53.5358 19.5261 53.7408C22.5021 54.1496 26.2112 54.3666 30 54.3666C33.7888 54.3666 37.4979 54.1496 40.4739 53.7408C41.9658 53.5358 43.1757 53.2957 44.0731 53.046C44.6251 52.8924 44.9325 52.7691 45.0661 52.712Z" fill="#8A9CAD"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M30.0156 44.5593C37.0611 44.5593 42.7726 38.8214 42.7726 31.7434C42.7726 24.6653 37.0611 18.9275 30.0156 18.9275C22.9701 18.9275 17.2585 24.6653 17.2585 31.7434C17.2585 38.8214 22.9701 44.5593 30.0156 44.5593ZM30.0156 49.3476C39.6934 49.3476 47.5389 41.4659 47.5389 31.7434C47.5389 22.0208 39.6934 14.1391 30.0156 14.1391C20.3377 14.1391 12.4922 22.0208 12.4922 31.7434C12.4922 41.4659 20.3377 49.3476 30.0156 49.3476Z" fill="#8A9CAD"/>
                            <path d="M34.071 24.8776C34.8208 24.1278 36.0365 24.1278 36.7863 24.8776C37.5361 25.6274 37.5361 26.8431 36.7863 27.5929L25.9251 38.4541C25.1753 39.2039 23.9596 39.2039 23.2098 38.4541C22.46 37.7043 22.46 36.4886 23.2098 35.7388L34.071 24.8776Z" fill="#8A9CAD"/>
                            <path d="M36.7865 35.7388C37.5363 36.4886 37.5363 37.7043 36.7865 38.4541C36.0367 39.2039 34.821 39.2039 34.0712 38.4541L23.2101 27.5929C22.4602 26.8431 22.4602 25.6274 23.2101 24.8776C23.9599 24.1278 25.1755 24.1278 25.9253 24.8776L36.7865 35.7388Z" fill="#8A9CAD"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3862:645">
                                <rect width="60" height="60" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className={style.text}>Данные не доехали</div>
                <div className={style.description}>
                    <span>{`Так бывает из-за проблем со связью
                    или сервером, не отчаивайтесь
                    и попробуйте `
                    }</span>
                    <a className={style.link} href='/' onClick={handleClick}>обновить страницу.</a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({

})

const mapDispatchToProps = {
    clearTaskPatchError,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
