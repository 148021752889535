import { createSelector } from 'reselect'
import _ from 'lodash'

import { getAllBuildingsSelectValue, getBuildingString } from '../utils'
import { FORM_NAMES, NEWS_BUILDING_SELECT_ITEMS } from '../constants'

const app = state => _.get(state, 'app', {})
const form = state => _.get(state, 'form', {})

export const makeNewsBuildingsList = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')
        const list = []
        _.forEach(buildings, item => {
            const label = getBuildingString(item)
            if (item.is_all_porches) {
                list.push({ value: item.id, label, type: 'building' })
            } else {
                _.forEach(item.available_porches, porch => {
                    list.push({ value: porch.id, label: `${label} (п. ${porch.number})`, type: 'porch' })
                })

            }
        })

        if (_.size(list) === 1) return list

        const allBuildingsOption = {
            value: NEWS_BUILDING_SELECT_ITEMS.all,
            label: getAllBuildingsSelectValue(list),
        }

        const someBuildingsOption = {
            value: NEWS_BUILDING_SELECT_ITEMS.some,
            label: 'Некоторым объектам',
        }

        return ([ allBuildingsOption, someBuildingsOption, ...list ])

    })

export const makeSomeBuildingsFields = () =>
    createSelector(app, slice => {
        const buildings = _.get(slice, 'buildings')
        const list = []
        _.forEach(buildings, item => {
            const label = getBuildingString(item)
            if (item.is_all_porches) {
                list.push({ id: item.id, label, type: 'building' })
            } else {
                _.forEach(item.available_porches, porch => {
                    list.push({ id: porch.id, label: `${label} (п. ${porch.number})`, type: 'porch' })
                })

            }
        })
        return list
    })

export const makeSomeBuildingsFieldsValues = () =>
    createSelector(form, slice => {
        return _.get(slice, [FORM_NAMES.createNews, 'values', 'buildingsList'])
    })

export const makeEditNewsInitialData = () => createSelector(app, slice => {
    const news = _.get(slice, 'news')

    const id = _.get(news, 'id')
    const target = _.get(news, 'target')
    const newsTitle = _.get(news, 'title')
    const newsDescription = _.get(news, 'description')
    const newsType = _.get(news, 'type')

    const image_attachments = _.get(news, 'image_attachments')
    const file_attachments = _.get(news, 'file_attachments')
    const imagesAtt = _.map(image_attachments, i => ({
        id: i.id,
        file: i.medium.file,
        realName: i.name,
        status: 'done'
    }))
    const filesAtt = _.map(file_attachments, i => ({
        id: i.id,
        file: i.s3_file.file,
        realName: i.name,
        status: 'done'
    }))
    /* Объединил картинки и файлы в один массив и превратил массив в объект, в котором ключи - порядковые номера файлов в массивве */
    const files = { ...[ ...imagesAtt, ...filesAtt ] }

    return {id, target, newsTitle, newsDescription, newsType, files}
})
