import axios from 'axios'
import * as types from '../../../__data__/actions-types'

import { MODERATOR_STORAGE_NAME } from '../../../__data__/constants'
import { getToken } from '../../../__data__/utils'

export const fetchGetSettingsReading = () => ({ type: types.GET_SETTINGS_READING })
export const fetchGetSettingsReadingSuccess = (data) => ({ type: types.GET_SETTINGS_READING_SUCCESS, data })
export const fetchGetSettingsReadingFail = (error) => ({ type: types.GET_SETTINGS_READING_FAIL, error })

export const fetchGetBuildingMetersSettings = () => ({ type: types.GET_BUILDING_METERS_SETTINGS })
export const fetchGetBuildingMetersSettingsSuccess = (data) => ({ type: types.GET_BUILDING_METERS_SETTINGS_SUCCESS, data })
export const fetchGetBuildingMetersSettingsFail = (error) => ({ type: types.GET_BUILDING_METERS_SETTINGS_FAIL, error })

export const fetchSetBuildingMetersSettings = () => ({ type: types.SET_BUILDING_METERS_SETTINGS })
export const fetchSetBuildingMetersSettingsSuccess = (data) => ({ type: types.SET_BUILDING_METERS_SETTINGS_SUCCESS, data })
export const fetchSetBuildingMetersSettingsFail = (error) => ({ type: types.SET_BUILDING_METERS_SETTINGS_FAIL, error })

export const fetchGetEmployeeFormOrganisations = () => ({ type: types.GET_EMPLOYEE_FORM_ORGANISATIONS })
export const fetchGetEmployeeFormOrganisationsSuccess = (data) => ({ type: types.GET_EMPLOYEE_FORM_ORGANISATIONS_SUCCESS, data })
export const fetchGetEmployeeFormOrganisationsFail = (error) => ({ type: types.GET_EMPLOYEE_FORM_ORGANISATIONS_FAIL, error })

export const fetchGetBuildingPassport = () => ({ type: types.GET_BUILDING_PASSPORT })
export const fetchGetBuildingPassportSuccess = (data) => ({ type: types.GET_BUILDING_PASSPORT_SUCCESS, data })
export const fetchGetBuildingPassportFail = (error) => ({ type: types.GET_BUILDING_PASSPORT_FAIL, error })

export const getSettingsReadings = buildings_id => dispatch => {
    dispatch(fetchGetSettingsReading())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/settings/building/?buildings_id=${buildings_id}`,
    })
        .then(response => {
            dispatch(fetchGetSettingsReadingSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetSettingsReadingFail(error))
        })
}

export const getBuildingMetersSettings = building_id => dispatch => {
    dispatch(fetchGetBuildingMetersSettings())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/settings/building/${building_id}/`,
    })
        .then(response => {
            dispatch(fetchGetBuildingMetersSettingsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingMetersSettingsFail(error))
        })
}

export const setBuildingMetersSettings = (building_id, data) => dispatch => {
    dispatch(fetchSetBuildingMetersSettings())

    return axios({
        method: 'PUT',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        data,
        url: `/api/crm/settings/building/${building_id}/`,
    })
        .then(response => {
            dispatch(fetchSetBuildingMetersSettingsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchSetBuildingMetersSettingsFail(error))
        })
}

export const getEmployeeFormOrganisations = () => dispatch => {
    dispatch(fetchGetEmployeeFormOrganisations())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/scenarios/companies/`,
    })
        .then(response => {
            dispatch(fetchGetEmployeeFormOrganisationsSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetEmployeeFormOrganisationsFail(error))
        })
}

export const getBuildingPassport = (id) => dispatch => {
    dispatch(fetchGetBuildingPassport())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/buildings/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetBuildingPassportSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingPassportFail(error))
        })
}
