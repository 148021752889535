import React from 'react'

export default function ElectroDay() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.95215 21.2256L18.1611 10.749C18.3193 10.5557 18.3984 10.3711 18.3984 10.1602C18.3984 9.79102 18.1172 9.52734 17.7305 9.52734H12.7119L15.3135 2.63672C15.6738 1.66992 14.6631 1.16016 14.0479 1.95117L5.84766 12.4189C5.68945 12.6211 5.60156 12.8057 5.60156 13.0166C5.60156 13.377 5.88281 13.6494 6.26953 13.6494H11.2969L8.68652 20.5312C8.32617 21.498 9.33691 22.0078 9.95215 21.2256ZM13.4854 12.2344H8.01855L12.8789 5.80078L10.5059 10.9424H15.9727L11.1211 17.376L13.4854 12.2344Z" fill="#FAB301"/>
    </svg>
}



