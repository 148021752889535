import React, { useCallback } from 'react'
import classNames  from 'classnames'
import _ from 'lodash'
import DropdownList from 'react-widgets/lib/DropdownList'
import PropTypes from 'prop-types'

import { FieldErrorMessage } from '../../../components/form'
import Label from '../../../components/form/label'

import style from './style.css'

const ListItem = ({ item }) => (
    <div className={style.item}>
        <div className={style.itemPrev}>H</div>
        <div className={style.itemText}>{item.label}</div>
    </div>
)

const inputProps = {spellcheck: "false"}

let ValueInput = ({ item }) => item ? (
    <div className={style.item}>
        <div className={style.itemPrev}>H</div>
        <div className={style.itemText}>{item.label}</div>
    </div>
) : ''

function Component(props) {
    const { input, meta, list, handleChange, size, label, defaultOpen } = props
    const isInvalid = meta.invalid && meta.touched

    const onChange = useCallback(e => {
        input.onChange(e)
        handleChange(e)
    }, [handleChange])

    const handleBlur = useCallback(e => {
        const value = _.find(list, i => i.label === e.target.value)

        if (value) {
            input.onBlur(value)
        } else {
            input.onBlur(e)
        }
    }, [])

    return (
        <div>
            <div className={style.container}>
                <DropdownList
                    {...input}
                    value={input.value}
                    onBlur={handleBlur}
                    onFocus={input.onFocus}
                    textField='label'
                    itemComponent={ListItem}
                    onChange={onChange}
                    data={list}
                    className={classNames(
                        style[`size-${size}`],
                        isInvalid && style.invalid,
                    )}
                    inputProps={inputProps}
                    valueComponent={ValueInput}
                    placeholder="Выбрать"
                    defaultOpen={defaultOpen}
                />
                <Label>{label}</Label>
            </div>
            <FieldErrorMessage meta={meta} />
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
    defaultOpen: PropTypes.bool,
}

Component.defaultProps = {
    size: 'auto',
    defaultOpen: false,
}

export default (Component)
