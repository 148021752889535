export const PACKAGE_VERSION = "0.21.2";

export const APP_NAME = 'TinodeWeb/' + PACKAGE_VERSION;

export const API_KEY = 'AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K';

export const HOST_NAME = 'internal-dev.org:6060';

// Linear dimensions of image preview: shrink image under this size for previews.
export const IMAGE_PREVIEW_DIM = 64;

export const MESSAGES_PAGE = 24;

export const CHAT_TOKEN = 'chat-token'

export const MAX_INBAND_ATTACHMENT_SIZE = 262144;
// Absolute maximum attachment size to be used with the server = 8MB. Increase to
// something like 100MB in production.
export const MAX_EXTERN_ATTACHMENT_SIZE = 1 << 23;
export const MAX_IMAGE_DIM = 768;
export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/gif', 'image/png', 'image/svg', 'image/svg+xml'];

export const LOGGING_ENABLED = true;

export const TABS = [
    {
        title: 'Все чаты',
        value: 'all'
    },
    {
        title: 'Жильцы',
        value: 'resident'
    },
    {
        title: 'Коллеги',
        value: 'company'
    },
    {
        title: 'Жалобы и предложения',
        value: 'complaint'
    }
]

export const USERS_STATUS = {
    suspended: 'suspended',
    deleted: 'deleted',
    denied: 'denied'
}

export const COMPLAINT_TYPE = [
    {
        title: 'Ненависть',
        value: 'complaint',
        description: 'Раздражает жильцов',
        photo: {'ref': 'complaint.svg'}
    },
    {
        title: 'Похвала',
        value: 'appreciation',
        description: 'Любовь и признание жильцов',
        photo: {'ref': 'appreciation.svg'}
    },
    {
        title: 'Идеи',
        value: 'suggestion',
        description: 'Предложения жильцов',
        photo: {'ref': 'suggestion.svg'}
    }
]

