import React from 'react'

import { NEWS_BUILDING_SELECT_ITEMS } from '../../../__data__/constants'

import SomeBuildingFields from './some-buildings-fields'
import BuildingFields from './building-fields'
import PorchFields from './porch-fields'

function Component(props) {
    if (!props.buildingValue || props.buildingValue.value === NEWS_BUILDING_SELECT_ITEMS.all) return null
    if (props.buildingValue.value === NEWS_BUILDING_SELECT_ITEMS.some) return <SomeBuildingFields formValues={props.formValues} />
    if (props.buildingValue.type === 'porch') return <PorchFields formName={props.formName} />
    else return <BuildingFields formName={props.formName} buildingId={props.buildingValue.value} />
}

export default Component
