import React from 'react'

import style from './item.css'

function Component(props) {
    const { callback, setPerformerSelect } = props

    const handleClick = e => {
        setPerformerSelect(true)
        callback()
    }

    return (
        <button
            className={style.container}
            type='button'
            onClick={handleClick}
        >
            Поменять исполнителя
        </button>

    )
}

export default Component
