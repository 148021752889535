
import React from 'react';
export default function blockIcon(){
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.15163 14C9.06921 13.3768 12.5758 10.8944 13.9985 8.84414L13.9985 8.8441C14.0488 6.81272 12.7942 2.61915 11.3456 0.58749C9.48781 -0.132755 4.92501 -0.23614 2.56354 0.531894C1.36925 2.1684 -0.0338528 6.45233 0.000622963 8.95448C1.19791 10.5886 4.77109 13.294 7.15163 14ZM11.6667 5.83333H2.33333V8.16667H11.6667V5.83333Z" fill="#FF7863"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
