import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { getSettingsStaffPagination} from '../../actions/staff-actions'
import { makeIsEmployeeListChanged, makeStaffsObject } from '../../selectors/staff-selectors'

import style from './style.css'
import StaffTable from './componets/staff-table/staff-table'
import StaffCard from './componets/staff-card/staff-card'

function StaffPage(props) {
    const {staffObject, isEmployeeListChanged, getSettingsStaffPagination} = props

    useEffect(() => {
        getSettingsStaffPagination()
    }, [])

    useEffect(() => {
        if (isEmployeeListChanged) {
            getSettingsStaffPagination()
        }
    }, [isEmployeeListChanged])

    const [selected, setSelected] = useState(null)

    const handleResidentClick = (event, userId) => {
        if (selected !== userId) {
            setSelected(userId)
        } else {
            setSelected(null)
        }
    }

    const activeContact = _.find(staffObject.list, i => i.user === selected)

    const getListBuildingsAddress = (buildings_list, porche_list) => {
       const objectFromPorches = porche_list.map(porche => porche.building);
       let allObjects = []
       allObjects = allObjects.concat(objectFromPorches)
       allObjects = allObjects.concat(buildings_list)
       // делаем сет что б избежать повторов TODO: Это комментарий Никиты. Разобраться
       const setObjects = new Set(allObjects.reduce(function (acc, cur) {
            if(cur){
                acc.push(cur.street + ' ' + cur.number)
            }
            return acc
        }, []))
       return Array.from(setObjects)
    }

    return (
        <div className={style.container}>
            <div>
                <StaffTable
                    staffObject={staffObject}
                    handleClick={handleResidentClick}
                    selected={selected}
                    getListBuildingsAddress={getListBuildingsAddress}
                    getSettingsStaffPagination={getSettingsStaffPagination}
                />
                {activeContact && <StaffCard {...activeContact} listBuldingsAddres={getListBuildingsAddress(activeContact.buildings_list,  activeContact.porchesList)}  handleClick={setSelected}/>}
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    staffObject: makeStaffsObject(),
    isEmployeeListChanged: makeIsEmployeeListChanged()
})

const mapDispatchToProps = {
    getSettingsStaffPagination
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(StaffPage)
