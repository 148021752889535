import React, {useMemo} from 'react'
import {Drafty} from 'tinode-sdk';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import {Avatar} from '../avatar'
import {FormattedMessage} from 'react-intl';
import {sanitizeImageUrl} from '../../utils/image-helper';
import {Attachment} from './attachment';
import {deliveryMarker} from '../../utils/utils';
import iconWaiting from '../../assets/icon-waiting.svg'
import iconDone from '../../assets/icon-done.svg'

import styles from './style.css'

export const Message = ({tinode, title, photo, content, from, isReceivedMsg, ts, status, isP2PTopic}) => {
    const deliveryStatus = deliveryMarker(status);
    const draftyFormatter = (type, data, values, key) => {
        let attr = Drafty.attrValue(type, data) || {};
        attr.key = key;
        //inline-image
        if (type === 'IM') {
            let element = Drafty.tagName(type);
            if (element) {
                attr.src = tinode.authorizeURL(sanitizeImageUrl(attr.src))
                if (!attr.src) {
                    attr.src = '/broken_image.png';
                }
                return React.createElement(element, attr, null);
            }
        } else if (type === 'EX') {
            return null
        }
        return values
    }

    const fileAttachment = useMemo(() => {
        if(Drafty.isValid(content)) {
            const attachments = [];
            Drafty.attachments(content, function(att, i) {
                if (att.mime === 'application/json') {
                    // не показвываем
                    return;
                }
                const url = tinode.authorizeURL(Drafty.getDownloadUrl(att))

                attachments.push(<Attachment
                    tinode={tinode}
                    url={url}
                    filename={att.name}
                    uploading={Drafty.isProcessing(att)}
                    mimetype={att.mime}
                    size={Drafty.getEntitySize(att)}
                    key={i} />);
            });
            return attachments;
        }
    }, [content, tinode])

    const {userTitle, userPhoto} = useMemo(() => {
        if(!isP2PTopic) {
            const topic = tinode.getTopic(from);
            if(!topic) {
                return {
                    userTitle: '',
                    userPhoto: ''
                }
            }
            const desc = (topic && topic.public) || {};
            return {
                userTitle: desc.fn || '',
                userPhoto: desc.photo || null
            }
        }
        return {
            userTitle: title,
            userPhoto: photo
        }
    }, [isP2PTopic, title, photo, tinode, from]);

    const contentMessage = useMemo(() => {
        if(Drafty.isValid(content)) {
            return React.createElement(React.Fragment, null, Drafty.format(content, draftyFormatter))
        }
        return <FormattedMessage
            id="invalid_content"
            defaultMessage="invalid content"
            description="Shown when message is unreadable"
        />
    }, [content])

    return (
        <li
            className={classnames(styles.container, {[styles.containerRight]: isReceivedMsg})}
        >
            {isReceivedMsg && <Avatar photo={userPhoto} size="m" />}
            <div className={styles.content}>
                {isReceivedMsg && <b className={styles.title}>{userTitle}</b>}
                {contentMessage}
                {fileAttachment}
                <span className={styles.time}>
                {isReceivedMsg
                    ? ts
                    : <img src={deliveryStatus === 'done_all' ? iconDone : iconWaiting} alt="received-icon" />
                }
                </span>
            </div>
        </li>
    )
}

Message.propTypes = {
    tinode: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    photo: PropTypes.shape({
        value: PropTypes.string,
        type: PropTypes.string
    }),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isReceivedMsg: PropTypes.bool.isRequired,
    ts: PropTypes.string,
    isP2PTopic: PropTypes.bool.isRequired
}
