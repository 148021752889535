import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeTasksExecutorsList } from '../../../../../__data__/selectors/task-form'
import { getExecutors } from '../../../../../__data__/actions/tasks'

import style from './style.css'
import { default as SelectInput } from './select-input'
import ContextMenu from './context-menu'
import ConfirmButton from './confirm-button'
import { checkWritePermissionForSection } from "../../../../../__data__/actions/permissions";
import classNames from "classnames";

const Component = props => {
    const { tasksExecutorsList, serviceId, buildingId, taskId, performer, stage, handleRejectModal, tasksSortedList, changeVisibility } = props

    const [isPerformerSelect, setPerformerSelect] = useState(false)
    const isAvailableByPerms = checkWritePermissionForSection('Заявки')

    useEffect(() => {
        buildingId && serviceId && props.getExecutors(buildingId, serviceId)
    }, [])

    const onAssignButtonClick = e => {
        e.preventDefault()
        setPerformerSelect(true)
    }

    return (
        <div className={style.container}>
            {!isPerformerSelect && (
                <div className={style.menuWrapper}>
                    { !performer?.first_name && <a onClick={onAssignButtonClick} className={classNames(style.assignButton, !isAvailableByPerms && style.disabled)} href='/'>Назначить исполнителя</a> }
                    { stage === 'in_progress' && <ConfirmButton taskId={taskId} changeDetailsVisibility={changeVisibility} />}
                    <ContextMenu
                        handleRejectModal={handleRejectModal}
                        performer={performer}
                        setPerformerSelect={setPerformerSelect}
                        taskId={taskId}
                        tasksSortedList={tasksSortedList}
                    />
                </div>
            )}
            {isPerformerSelect && (
                <SelectInput
                    placeholder='Выберите исполнителя'
                    taskId={taskId}
                    input={{}}
                    meta={{}}
                    list={tasksExecutorsList}
                    stage={stage}
                    changeModalVisibility={changeVisibility}
                />
            )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    tasksExecutorsList: makeTasksExecutorsList(),
})

const mapDispatchToProps = {
    getExecutors,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
