import React from 'react';
import PropTypes from 'prop-types';
import {TABS} from '../../../../constants/chat';
import {TabCell} from './tab-cell';

import style from './style.css';

export const Tabs = ({ onClick, previewComplaints, selectedTab }) => {
    return (
        <ul className={style.tabs}>
            {TABS.map(({title, value}) => (
                <TabCell
                    key={`${title}_${value}`}
                    value={value}
                    title={title}
                    previewComplaints={previewComplaints}
                    selectedTab={selectedTab.value}
                    onClick={onClick}
                />))}
        </ul>
    )
}

Tabs.propTypes = {
    onClick: PropTypes.func,
    selectedTab: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string
    }).isRequired
}
