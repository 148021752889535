import { useMemo } from 'react'
import {Tinode} from 'tinode-sdk'

import { API_KEY, APP_NAME, HOST_NAME, CHAT_TOKEN } from '../../constants/chat'
import LocalStorageUtil from '../../pages/chat-new/utils/local-storage'
import {WebSocketClient} from "../../webSocketClient";
import {getToken} from "../../__data__/utils";
import {MODERATOR_STORAGE_NAME} from "../../__data__/constants";

export function useChatLogin() {
    const tinode = useMemo(() => {
      // return new Tinode({appName: APP_NAME, host: HOST_NAME, apiKey: API_KEY, transport: "ws", platform: "web", persist: true, secure: true})
      return new Tinode({appName: APP_NAME, host: HOST_NAME, apiKey: API_KEY, secure: true})
    }, [])

    const handleConnect = (name, password) => {
        const cred = Tinode.credential();
        tinode.loginBasic(name, password, cred)
            .then((ctrl) => {
                if(ctrl.code === 200) {
                    LocalStorageUtil.setObject(CHAT_TOKEN, {
                        expires: ctrl.params.expires,
                        token: ctrl.params.token
                    });
                }
            })
            .catch((err) => {
                console.error(err, 'login authorization error');
                LocalStorageUtil.removeItem(CHAT_TOKEN);
            });
    }

    const handleLoginRequest = ({name, password}) => {
        name = name.replaceAll('+', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')
        if(!tinode) {
            return;
        }

        if (tinode.isConnected()) {
            handleConnect(name, password);
        } else {
            tinode.connect()
                .then(() => {
                    handleConnect(name, password)
                })
                .catch((err) => {
                    // Socket error
                    console.error(err, 'login request error')
                });
        }
    }

    return  {
        handleLoginRequest,
        tinode
    }
}

// TODO: move isDevelopment, isHttps, base_ws_url to the right place
export const isDevelopment: () => boolean = () =>
    process.env.NODE_ENV === 'development'

export const isHttps: () => boolean = () =>
    window.location.protocol === 'https:'

export function wsBackAuth() {
    let base_ws_url = isDevelopment() ? 'ws://95.174.92.169:8000/ws' : `${isHttps() ? 'wss:' : 'ws:'}//${window.location.host}/ws`
    return new WebSocketClient(`${base_ws_url}/complaints?token=${getToken(MODERATOR_STORAGE_NAME)}`)
}
