import React from 'react'
import _ from 'lodash'
import moment from 'moment-with-locales-es6'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { MODERATOR_STORAGE_ROLE_NAME, fileExtensions, fileFormatIcons, imagesFormats } from './constants'

moment.locale('ru')

export const getToken = storageName => localStorage.getItem(storageName)
export const userRole = localStorage.getItem(MODERATOR_STORAGE_ROLE_NAME)
export const isUserAdmin = userRole === 'admin' || userRole === 'superuser'

export const declOfNum = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2]
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

export const getRepresentableTarget = data => {
    let value = ''

    if (data.is_all === true) {
        value = 'Всем подконтрольным объектам'
    } else if (!_.isEmpty(data.buildings) && _.isEmpty(data.porches)) {
        if (data.buildings.length == 1) {
            value = data.buildings[0].street + " " + data.buildings[0].number
        } else if (data.buildings.length == 2) {
            value = data.buildings[0].street + " " + data.buildings[0].number +
                " и " + data.buildings[1].street + " " + data.buildings[1].number
        } else {
            value = data.buildings.length + declOfNum(data.buildings.length, ["-м", "-м", "-и"]) + " домам"
        }
    } else if (!_.isEmpty(data.porches) && _.isEmpty(data.buildings) && _.isEmpty(data.floors)) {
        let selected_building_porches = data.porches[0]
        if (data.porches.length == 1) {
            if (selected_building_porches.items.length == 1) {
                value = selected_building_porches.street + " " + selected_building_porches.number + ": " +
                    selected_building_porches.items[0].number + "-му подъезду"
            } else if (selected_building_porches.items.length == 2) {
                value = selected_building_porches.street + " " + selected_building_porches.number + ": " +
                    selected_building_porches.items[0].number + "-му и " +
                    selected_building_porches.items[1].number + "-му подъездам"
            }
        } else if (data.porches.length == 2) {
            let porches_length = selected_building_porches.items.length + data.porches[1].items.length
            value = porches_length + declOfNum(porches_length, ["-м", "-м", "-и"]) + " подъездам в двух домах"
        } else {
            // TODO: fixed titles for "домах"
            value = "Некоторым подъездам " + data.porches.length + declOfNum(data.porches.length, ["-х", "-и", "-и"]) + " домов"
        }
    } else if (!_.isEmpty(data.buildings) && !_.isEmpty(data.porches) && _.isEmpty(data.floors)) {
        value = "Некоторым объектам (как зданиям, так и подъездам)"
    } else if (!_.isEmpty(data.porches) && !_.isEmpty(data.floors)) {
        let selected_building_porches = data.porches[0]
        let selected_building_floors = data.floors[0]
        if (selected_building_floors.items.length == 1) {
            value = selected_building_porches.street + " " + selected_building_porches.number + ": " +
                selected_building_floors.items[0].number + "-му этажу " +
                selected_building_porches.items[0].number + "-го подъезда"
        } else if (selected_building_floors.items.length == 2) {
            value = selected_building_porches.street + " " + selected_building_porches.number + ": " +
                selected_building_floors.items[0].number + '-му и ' +
                selected_building_floors.items[1].number + "-му этажам " +
                selected_building_porches.items[0].number + "-го подъезда"
        } else {
            value = selected_building_porches.street + " " + selected_building_porches.number + ": некоторым этажам " +
                selected_building_porches.items[0].number + "-го подъезда"
        }
    } else if (!_.isEmpty(data.pipes)) {
        let selected_building_pipes = data.pipes[0]
        if (selected_building_pipes.items.length == 1) {
            value =  selected_building_pipes.street + " " + selected_building_pipes.number + ": " + 
                selected_building_pipes.items[0].name + "-му стояку"
        } else if (selected_building_pipes.items.length == 2) {
            value = selected_building_pipes.street + " " + selected_building_pipes.number + ": " + 
                selected_building_pipes.items[0].name +
                " и " + selected_building_pipes.items[1].name + "-му стояку"
        } else {
            value = selected_building_pipes.street + " " + selected_building_pipes.number + ": " + 
                selected_building_pipes.items.length + 
                declOfNum(selected_building_pipes.items.length, ['-м', '-м', '-и']) + " стоякам"
        }
    } else if (!_.isEmpty(data.units)) {
        // TODO: replace "квартир*", use the unit_type from Response: UnitType(models.TextChoices):
        //     FLAT = 'flat'
        //     HOTEL_ROOM = 'hotel_room'
        //     PARKING_LOT = 'parking_lot'
        //     TOWN_HOUSE_LOT = 'town_house_lot'
        //     COTTAGE = 'cottage'
        //     PUBLIC_PREMISE = 'public_premise'
        let selected_building_units = data.units[0]
        if (selected_building_units.items.length == 1) {
            value = selected_building_units.street + " " + selected_building_units.number + ": " + 
                selected_building_units.items[0].label + "-й квартире"
        } else if (selected_building_units.items.length == 2) {
            value = selected_building_units.street + " " + selected_building_units.number + ": " + 
                selected_building_units.items[0].label +
                " и " + selected_building_units.items[1].label + "-й квартире"
        } else {
            value = selected_building_units.street + " " + selected_building_units.number + ": " + 
                selected_building_units.items.length + 
                declOfNum(selected_building_units.items.length, ['-м', '-м', '-и']) + " квартирам"
        }
    }

    return value
}

export const formatDate = (stringDate, format) => {
    const date = moment(stringDate)
    // Не текущий год
    if (date.format('YYYY') !== moment().format('YYYY')) {
        return date.format('D.MM.YY в HH:mm')
    }
    // Сегодня или вчера
    if (date.isAfter(moment().subtract(2, 'days'))) {
        return date.calendar()
    }
    // Текущий год, но 2+ дня назад
    return date.format('D MMM в HH:mm')
}

export const getAllBuildingsSelectValue = (buildingKeys) => {
    const numberPrefix = declOfNum(buildingKeys.length, ['-му', '-м', '-и'])
    const buildingText = declOfNum(buildingKeys.length, ['объекту', 'объектам', 'объектам'])
    return `Всем ${buildingKeys.length}${numberPrefix} ${buildingText}`
}

export const getBuildingString = (building) => `${building.street}, ${building.number}`

export const getNavigationBuildingString = (building) => {
    if (building.is_all_porches) {
        return `${building.street}, ${building.number}`
    }

    const porches = _.map(building.available_porches, porche => porche.number).join(',')
    return `${building.street}, ${building.number} (п. ${porches})`
}

export const getFileFormatIcon = (fileName) => {
    const format = _.last(_.split(fileName, '.'))
    const iconName = _.get(fileExtensions, format, fileExtensions.generic)
    return fileFormatIcons[iconName]
}

export const getIsFileImage = (fileName) => {
    const format = _.last(_.split(fileName, '.'))
    return _.includes(imagesFormats, format)
}

export const getSettingsWaterText = (coldWater, hotWater) => {
    if (coldWater && hotWater) return 'Горячая и холодная'
    if (hotWater) return 'Горячая'
    if (coldWater) return 'Холодная'
    return 'Отсутствует'
}

export const getSettingsElecText = (nightElec, dayElec) => {
    if (nightElec && dayElec) return 'Дневная и ночная'
    if (dayElec) return 'Единая'
    if (nightElec) return 'Ночная'
    return 'Отсутствует'
}

export const getSettingsGasText = (gas) => {
    if (gas) return 'Есть'
    return 'Отсутствует'
}

export const getSettingsHeatingText = (heating) => {
    if (heating) return 'Есть'
    return 'Отсутствует'
}

export const formatPhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumberFromString(value)
    if (!phoneNumber) return value
    return phoneNumber.format('INTERNATIONAL')
}
