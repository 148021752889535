import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import classNames from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import {
    declOfNum,
    getSettingsWaterText,
    getSettingsElecText,
    getSettingsGasText,
    getSettingsHeatingText
} from '../../../../../__data__/utils'
import { URL_LIST } from '../../../../../__data__/constants'

import style from './style.css'

function Component(props) {

    const {
        handleClick,
        metersList,
        reading_types,
        building,
        submit_end_day,
        submit_start_day,
        id,
    } = props

    const handleClose = e => {
        e.preventDefault()
        handleClick(null)
    }

    const isSticky = useScrollYPosition() > 85

    const indicatorsCountText = `${reading_types.length} ${declOfNum(reading_types.length, ['показателей', 'показателя', 'показателей'])}`

    const hasPeriod = submit_end_day && submit_start_day
    const hasWater = _.includes(reading_types, 'water_cold') || _.includes(reading_types, 'water_hot')
    const hasElec = _.includes(reading_types, 'elec_day') || _.includes(reading_types, 'elec_night')
    const hasGas = _.includes(reading_types, 'gas')
    const hasHeating = _.includes(reading_types, 'heating')

    return (
        <div className={classNames(style.container, isSticky && style.containerSticky)}>
            <div className={style.header}>
                <div className={style.headerLine}>
                    <div className={style.avatar}>
                        <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>avatar_meters</title>
                            <defs>
                                <circle id="path-1" cx="30" cy="30" r="30"></circle>
                                <linearGradient x1="50%" y1="1.15175769%" x2="50%" y2="100%" id="linearGradient-3">
                                    <stop stop-color="#FFFFFF" offset="0%"></stop>
                                    <stop stop-color="#EAEAEA" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="SETTINGS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="06_settings_b1" transform="translate(-1116.000000, -195.000000)">
                                    <g id="Group-2" transform="translate(1100.000000, 175.000000)">
                                        <g id="DISPLAY/avatar" transform="translate(16.000000, 20.000000)">
                                            <mask id="mask-2" fill="white">
                                                <use xlinkHref="#path-1"></use>
                                            </mask>
                                            <use id="MASK" fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
                                            <g id="XTRAZ/avatars/_meters" mask="url(#mask-2)" fillRule="evenodd">
                                                <rect id="Rectangle" fill="url(#linearGradient-3)" x="0" y="0" width="60" height="60"></rect>
                                                <path d="M30,17 C22.8202983,17 17,22.8202983 17,30 C17,33.4478144 18.3696389,36.7544152 20.8076118,39.1923882 C23.2455848,41.6303611 26.5521856,43 30,43 C33.4478144,43 36.7544152,41.6303611 39.1923882,39.1923882 C41.6303611,36.7544152 43,33.4478144 43,30 C43,26.5521856 41.6303611,23.2455848 39.1923882,20.8076118 C36.7544152,18.3696389 33.4478144,17 30,17 M30.25,19.5 C35.9109187,19.5 40.5,24.2329898 40.5,30.0714286 C40.5,33.2428571 39.21875,36.0178571 37.040625,38 C35.246875,36.2821429 32.8125,35.3571429 30.25,35.3571429 C27.6875,35.3571429 25.38125,36.2821429 23.459375,38 C21.28125,36.0178571 20,33.2428571 20,30.0714286 C20,24.2329898 24.5890813,19.5 30.25,19.5 M32.4421047,22.0156678 L29.4330741,27.3501252 L29.2988893,27.6570946 C28.3461777,27.8305991 27.5544878,28.4578844 27.192189,29.3387531 C26.6420316,30.7134421 27.3129553,32.2616356 28.695058,32.8088419 C30.0771608,33.3560482 31.6337037,32.6887234 32.1838611,31.3140344 C32.5327414,30.4331657 32.3717197,29.418832 31.7947253,28.6714283 L31.9289101,28.3244194 L33.5,22.4725568 C33.2573368,22.0616542 32.8851831,21.9552663 32.4421047,22.0156678 Z M27.25,22.5 C26.5596441,22.5 26,23.0596441 26,23.75 C26,24.4403559 26.5596441,25 27.25,25 C27.9403559,25 28.5,24.4403559 28.5,23.75 C28.5,23.0596441 27.9403559,22.5 27.25,22.5 M23.75,26 C23.0596441,26 22.5,26.5596441 22.5,27.25 C22.5,27.9403559 23.0596441,28.5 23.75,28.5 C24.4403559,28.5 25,27.9403559 25,27.25 C25,26.5596441 24.4403559,26 23.75,26 M36.75,26 C36.0596441,26 35.5,26.5596441 35.5,27.25 C35.5,27.9403559 36.0596441,28.5 36.75,28.5 C37.4403559,28.5 38,27.9403559 38,27.25 C38,26.5596441 37.4403559,26 36.75,26 Z" id="ICON" fill="#B7BABF"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className={style.headerContent}>
                        <div className={style.address}>{`${building.street} ${building.number}`}</div>
                        <div className={style.indicatorsCountText}>{indicatorsCountText}</div>
                    </div>
                    <a href='/' onClick={handleClose} className={style.closeButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                            <g fill="none" fillRule="evenodd">
                                <path fill="#F7F8FA" d="M0 0H1440V900H0z" transform="translate(-897 -143)"/>
                                <g>
                                    <path fill="#D8D8D8" fillOpacity="0" d="M0 0H46V46H0z" transform="translate(-897 -143) translate(880 126)"/>
                                    <g>
                                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path fill="#7D8B8D" d="M12 13.414l-3.542 3.542c-.387.387-1.015.387-1.408-.006-.39-.39-.388-1.026-.006-1.408L10.586 12 7.044 8.458c-.387-.387-.387-1.015.006-1.408.39-.39 1.026-.388 1.408-.006L12 10.586l3.542-3.542c.387-.387 1.015-.387 1.408.006.39.39.388 1.026.006 1.408L13.414 12l3.542 3.542c.387.387.387 1.015-.006 1.408-.39.39-1.026.388-1.408.006L12 13.414z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-897 -143) translate(880 126) translate(11 11)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <Link className={style.editLink} to={`${URL_LIST.settingsMetersReadingsForm}?id=${id}`}>Редактировать</Link>
            </div>
            <div className={style.body}>
                {hasPeriod && (
                    <div className={style.bodyItem}>
                        <div className={style.field}>
                            <div className={style.fieldLabel}>Дни подачи</div>
                            <div className={style.fieldValue}>{`${submit_start_day} - ${submit_end_day}`}</div>
                        </div>
                    </div>
                )}
                { (hasWater || hasElec || hasGas || hasHeating) && (
                    <div className={style.bodyItem}>
                        {hasWater && (
                            <div className={style.field}>
                                <div className={style.fieldLabel}>Вода</div>
                                <div className={style.fieldValue}>{getSettingsWaterText(_.includes(reading_types, 'water_cold'), _.includes(reading_types, 'water_hot'))}</div>
                            </div>
                        )}
                        {hasElec && (
                            <div className={style.field}>
                                <div className={style.fieldLabel}>Электр.</div>
                                <div className={style.fieldValue}>{getSettingsElecText(_.includes(reading_types, 'elec_day'), _.includes(reading_types, 'elec_night'))}</div>
                            </div>
                        )}
                        {hasGas && (
                            <div className={style.field}>
                                <div className={style.fieldLabel}>Газ</div>
                                <div className={style.fieldValue}>{getSettingsGasText(_.includes(reading_types, 'gas'))}</div>
                            </div>
                        )}
                        {hasHeating && (
                            <div className={style.field}>
                                <div className={style.fieldLabel}>Отопление</div>
                                <div className={style.fieldValue}>{getSettingsHeatingText(_.includes(reading_types, 'heating'))}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Component
