
import React from 'react';
export default function createdIcon(){
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 4.25C6 3.83579 6.33579 3.5 6.75 3.5C7.16421 3.5 7.5 3.83579 7.5 4.25V7.25H6V4.25Z" fill="#9EAAB5"/>
            <path d="M6 7.25C6 7.66421 6.33579 8 6.75 8H9.75C10.1642 8 10.5 7.66421 10.5 7.25C10.5 6.83579 10.1642 6.5 9.75 6.5H6V7.25Z" fill="#9EAAB5"/>
            <path d="M13.2 7C13.2 10.4242 10.4242 13.2 7 13.2C3.57583 13.2 0.8 10.4242 0.8 7C0.8 3.57583 3.57583 0.8 7 0.8C10.4242 0.8 13.2 3.57583 13.2 7Z" stroke="#9EAAB5" stroke-width="1.6"/>
        </svg>

    )
}


