import _ from 'lodash'

import { declOfNum } from '../../../../__data__/utils'

export const prepareBuildings = (buildingsList) => _.map(buildingsList, i => _.replace(i, 'building-', ''))

export const getBuildingsId = (values) => {
    return _.reduce(values, (result, buildings) => {
        buildings && _.forEach(buildings, (building, key) => {
            result.push(_.replace(key, 'building-', ''))
        })
        return result
    }, [])
}

export  const hasChecked = (values) => _.reduce(values, (result, tenant) => {
    _.forEach(tenant, building => {
        if (building) {
            result = true
            return result
        }
    })
    return result
}, false)

export const getChecked = (values) => _.reduce(values, (result, buildings) => {
    buildings && _.forEach(buildings, (building, key) => {
        if (building) {
            result.push(key)
        }
    })
    return result
}, [])

export const getTargetText = (values, buildingList) => {
    const buildingsCount = _.size(buildingList)
    const checkedCount = _.size(values)
    const AllBuildsLabel = `Все объекты (${checkedCount})`
    if (checkedCount === 1) return _.get(_.find(buildingList, i => i.id == values[0]), 'label')
    else if (checkedCount === buildingsCount) return AllBuildsLabel
    else return `Некоторые объекты (${checkedCount})`
}

