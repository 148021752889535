import _ from 'lodash'

export  const hasChecked = (values) => _.reduce(values, (result, i) => {
    _.forEach(i, b => {
        if (b) {
            result = true
            return result
        }
    })
    return result
}, false)

export const getTargetText = (checkedFields, fields) => {
    const fieldsCount = _.size(fields)
    const checkedCount = _.size(checkedFields)
    if (checkedCount === fieldsCount) return 'Все доступные'
    return `${checkedCount} из ${fieldsCount}`
}
