import docIcon from '../theme/icons/file-attachments/doc.svg'
import pdfIcon from '../theme/icons/file-attachments/pdf.svg'
import sheetIcon from '../theme/icons/file-attachments/sheet.svg'
import archIcon from '../theme/icons/file-attachments/arch.svg'
import genericIcon from '../theme/icons/file-attachments/generic.svg'
import pptIcon from '../theme/icons/file-attachments/ppt.svg'
import imageIcon from '../theme/icons/file-attachments/image.svg'
import errorIcon from '../theme/icons/file-attachments/error.svg'

export const MODERATOR_STORAGE_NAME = 'home-moderator'
export const MODERATOR_STORAGE_ROLE_NAME = 'home-moderator-role'
export const PERMISSION_SECTIONS = 'permission-sections'
export const PROFILE_COMPANY_ID = 'profile-company-id'
export const PROFILE_USER_ID = 'profile-user-id'

export const CHECKED_BUILDINGS_STORAGE_NAME = 'checkedBuildings'

export const CHAT_TOKEN = 'chat-token'
export const CHAT_KEEP_LOGGED_IN = 'keep-logged-in'

export const FORM_NAMES = {
    buildingsForm: 'buildingsForm',
    loginForm: 'loginForm',
    confirmationPhoneNumberForm: 'confirmationPhoneNumberForm',
    confirmationCodeForm: 'confirmationCodeForm',
    registrationOfferForm: 'registrationOfferForm',
    passwordCreatingForm: 'passwordCreatingForm',
    passwordChangeForm: 'passwordChangeForm',
    passwordChangeLoggedForm: 'passwordChangeLoggedForm',
    confirmationForm: 'confirmationForm',
    createNews: 'createNewsForm',
    editNews: 'editNewsForm',
    createTask: 'createTaskForm',
    createResident: 'createResidentForm',
    downloadResident: 'downloadResidentForm',
    downloadResidents: 'downloadResidentsForm',
    editResident: 'editResidentForm',
    createMeters: 'createMetersForm',
    settingsMetersReadings: 'settingsMetersReadings',
    settingsAddEmployee: 'settingsAddEmployeeForm',
    settingsEditEmployee: 'settingsEditEmployee',
    settingsServices: 'settingsServicesForm',
    avatarForm: 'avatarForm',
    createCamera: 'createCamera',
    editCamera: 'editCamera',
    settingsObjectsForm: 'settingsObjectsForm',
}

export const PAGE_KEYS = {
    news: 'news',
    tasks: 'tasks',
    chats: 'chats',
    residents: 'residents',
    meters: 'meters',
    cameras: 'cameras',
    settings: 'settings',
    profile: 'profile',
    notFound: 'notFound',
}

export const URL_LIST = {
    loginForm: '/auth',
    confirmationForm: '/confirmation',
    registrationOfferForm: '/registration',
    passwordCreatingForm: '/password-creating/',
    passwordChangeForm: '/password-change/',
    passwordChangeLoggedForm: '/password-change-logged',
    news: '/news',
    tasks: '/tasks',
    chats: '/chats',
    chatsNew: '/chats-new',
    cameras: '/cameras',
    createCameraForm: '/create-camera-form',
    editCameraForm: '/edit-camera-form',
    meters: '/readings',
    metersForm: '/add-readings',
    createNewsForm: '/create-news-form',
    editNewsForm: '/edit-news-form',
    createTask: '/create-task',
    residents: '/residents',
    createResidentForm: '/create-resident-form',
    downloadResidentForm: '/download-resident-form',
    editResidentForm: '/edit-resident-form',
    profile: '/profile',
    avatarForm: '/avatar',
    settings: '/settings',
    settingsEmployeesPermissions: '/settings/employees-permissions',
    settingsBuildings: '/settings/buildings',
    settingsAddEmployee: '/settings/add-employee',
    settingsEditEmployee: '/settings/edit-employee',
    settingsMetersReadings: '/settings/meters-readings',
    settingsMetersReadingsForm: '/settings/meters-readings-form',
    settingsCompanyProfile: '/settings/company-profile',
    settingsBuildingPassport: '/settings/building-passport',
    settingsObjectsForm: '/settings/objects-form',
}

export const UNAUTHORIZED_PAGES = [
    URL_LIST.loginForm,
    URL_LIST.registrationOfferForm,
    URL_LIST.confirmationForm,
    URL_LIST.passwordCreatingForm,
    URL_LIST.passwordChangeForm,
]

export const NEWS_TYPES_LIST = [
    {
        value: 'info',
        status: 'info',
        label: 'Инфо',
    },
    {
        value: 'accident',
        status: 'accident',
        label: 'Авария',
    },
    {
        value: 'alert',
        status: 'alert',
        label: 'Важно',
    },
    {
        value: 'report',
        status: 'report',
        label: 'Отчёт',
    },
    {
        value: 'adv',
        status: 'adv',
        label: 'Реклама',
    },
]

export const NEWS_BUILDING_SELECT_ITEMS = {
    all: 'all',
    some: 'some',
}

export const NEWS_BUILDING_MENU_ITEMS_ID = {
    home: 'home',
    porch: 'porch',
    apartments: 'apartments',
    porches: 'porches',
    floors: 'floors',
    pipes: 'pipes',
}

export const NEWS_BUILDING_MENU_ITEMS = [
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.home,
        label: 'Всему дому',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.apartments,
        label: 'Квартирам',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.porches,
        label: 'Подъездам',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.floors,
        label: 'Этажам',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.pipes,
        label: 'Стоякам',
    },
]

export const NEWS_PORCH_MENU_ITEMS = [
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.porch,
        label: 'Всему подъезду',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.apartments,
        label: 'Квартирам',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.floors,
        label: 'Этажам',
    },
    {
        value: NEWS_BUILDING_MENU_ITEMS_ID.pipes,
        label: 'Стоякам',
    },
]

export const NEWS_FORM_FIELDS_ERRORS = {
    homeNumber: 'номер дома',
    newsTitle: 'заголовок объявления',
    newsDescription: 'текст объявления',
    buildingsList: 'список домов',
    flats: 'квартиры',
    porches: 'подъезды',
    floors: 'этажи',
    pipes: 'стояки',
    files: 'дождитесь загрузки файлов',
}

export const TASK_PORCHES_ITEMS_ID = {
    home: 'home',
    porches: 'porches',
}

export const TASK_PORCHES_MENU_ITEMS = [
    {
        value: TASK_PORCHES_ITEMS_ID.home,
        label: 'Дом',
    },
    {
        value: TASK_PORCHES_ITEMS_ID.porches,
        label: 'Подъезд',
    }
]

export const TASK_FLOORS_ITEMS_ID = {
    all: 'all',
    some: 'some',
}

export const TASK_FLOORS_MENU_ITEMS = [
    {
        value: TASK_FLOORS_ITEMS_ID.all,
        label: 'У входа',
    },
    {
        value: TASK_FLOORS_ITEMS_ID.some,
        label: 'Этаж',
    },
]

export const TASK_FORM_FIELDS_ERRORS = {
    homeNumber: 'объект',
    taskType: 'тип услуги',
    description: 'описание заявки',
    porches: 'подъезды',
    floors: 'этажи',
    files: 'дождитесь загрузки файлов',
}

export const CAMERA_FORM_FIELDS_ERRORS = {
    name: 'название',
    link: 'ссылка',
    buildings: 'объекты',
}

export const RESIDENT_FORM_FIELDS_ERRORS = {
    name: 'имя',
    surname: 'фамилия',
    phoneNumber: 'телефон',
    gender: 'пол',
    units: 'объект'
}

export const CONTACT_FORM_FIELDS_ERRORS = {
    name: 'имя',
    surname: 'фамилия',
    homeNumber: 'дом',
    flatNumber: 'квартира',
    phoneNumber: 'телефон',
    email: 'email',
}

export const METERS = {
    water_hot: 'Горячая',
    water_cold: 'Холодная',
    elec_night: 'Эл. день',
    elec_day: 'Эл. ночь',
    gas: 'Газ',
}

export const METERS_TYPES = {
    meter: 'Умный счётчик автоматически',
    resident: 'Холодная',
    employee: 'Эл. день',
}

export const METERS_FORM_FIELDS_ERRORS = {
    home_number: 'дом',
    flat_number: 'квартира',
}


export const COUNT_TOGGLE_VALUES = {
    all: 'all',
    some: 'some',
}

export const fileFormatIcons = {
    doc: docIcon,
    pdf: pdfIcon,
    sheet: sheetIcon,
    arch: archIcon,
    generic: genericIcon,
    ppt: pptIcon,
    image: imageIcon,
    error: errorIcon,
}

export const fileExtensions = {
    doc: 'doc',
    docx: 'doc',
    rtf: 'doc',
    txt: 'doc',
    pdf: 'pdf',
    ppt: 'ppt',
    pptx: 'ppt',
    xls: 'sheet',
    xlsx: 'sheet',
    csv: 'sheet',
    zip: 'arch',
    rar: 'arch',
    '7z': 'arch',
    generic: 'generic',
    png: 'image',
    jpg: 'image',
    jpeg: 'image',
    svg: 'image',
    gif: 'image',
    error: 'error',
}

export const imagesFormats = [
    'bmp',
    'gif',
    'jpeg',
    'jpg',
    'png',
    'tiff',
    'webp',
    'heic',
]

export const SETTINGS_WATER_LIST = [
    {
        value: 'water_hot_and_cold',
        label: 'Горячая и холодная',
    },
    {
        value: 'water_common',
        label: 'Один показатель',
    },
]

export const SETTINGS_ELEC_LIST = [
    {
        value: 'elec_day_and_night',
        label: 'Дневная и ночная',
    },
    {
        value: 'elec_common',
        label: 'Один показатель',
    },
]

export const ACCESSES_TYPES = {
    buildings: "объекты",
    cameras: "вокруг",
    chats: "общение",
    employees: "сотрудники",
    meters: "показания",
    news: "новости",
    readings: "показания",
    residents: "жильцы",
    tasks: "заявки",
}

const SEX_ITEMS_ID = {
    male: 'male',
    female: 'female',
}

export const SEX_ITEMS = [
    {
        value: SEX_ITEMS_ID.male,
        label: 'Мужчина',
    },
    {
        value: SEX_ITEMS_ID.female,
        label: 'Женщина',
    }
]

export const EMPLOYEE_FORM_FIELDS_ERRORS = {
    firstName: 'имя',
    lastName: 'фамилию',
    gender: 'пол',
    position: 'должность',
    company: 'организацию',
    phoneNumber: 'номер телефона',
}

export const EDIT_EMPLOYEE_FORM_FIELDS_ERRORS = {
    firstName: 'имя',
    lastName: 'фамилию',
    gender: 'пол',
    position: 'должность',
    company: 'организацию',
    phoneNumber: 'номер телефона',
    tasks: 'заявки',
    chats: 'общение',
    residents: 'жильцы',
    readings: 'показания',
    cameras: 'вокруг',
    buildingsSettings: 'здания',
    meters: 'счётчики',
    services: 'сервисы',
}

export const SETTINGS_OBJECTS_FORM_FIELDS_ERRORS = {

}

export const BUILDING_TYPES_SHORTCUT = {
    tenement: 'МКД',
    cottage: 'Кот.',
    parking: 'Парк.',
    hotel: 'Отель',
    townhouse: 'ТХ',
    office: 'Офис',
    mall: 'ТЦ',
}

export const BUILDING_TYPES = {
    tenement: 'Многоквартирный дом',
    cottage: 'Коттедж',
    parking: 'Парковка',
    hotel: 'Отель',
    townhouse: 'Таунхаус',
    office: 'Офис',
    mall: 'Торговый центр',
}


export const EMPLOYEES_SECTIONS = {
    denied: 'denied',
    read: 'read',
    write: 'write',
    performer: 'performer',
}

export const UNIT_ENTRY_TYPES = {
    building: 'Здание',
    porch: 'Подъезд',
    phone: 'Телефон',
    resident: 'Жилец',
    floor: 'Этаж',
    unit: 'Помещение',
    pipe: 'Стояк',
    complex: 'Комплекс',
}
