import React from 'react'
import {compose} from 'redux'
import ReactTooltip from 'react-tooltip'

import {getFileFormatIcon} from '../../../../../../../__data__/utils'

import style from "./style.css"

function Attachment(props) {
    const {fileName, realName, index} = props
    const icon = getFileFormatIcon(realName)

    return (
        <a className={style.container} target="_blank" href={fileName} data-tip={realName}
           data-for={`${realName}${index}`}>
            <ReactTooltip id={`${realName}${index}`} className={style.tooltip}/>
            <img src={icon}/>
            <span className={style.fileName}>{realName}</span>
        </a>
    )
}

export default compose()(Attachment)
