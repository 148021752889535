import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Field } from 'redux-form'
import classNames from 'classnames'
import _ from 'lodash'
import { compose } from 'redux'

import { Checkbox } from '../../../../../components/form'
import { makeCamerasFields } from '../../../selectors/staff-selectors'

import style from './style.css'

function Component(props) {
    const { camerasFields } = props

    return (
        <div className={classNames(style.fieldsSection)}>
            {_.map(camerasFields, (i, key) => (
                    <>
                        <div className={style.cameraString}>
                            <Field
                                key={i.id}
                                name={`cameras[id-${i.id}]`}
                                component={Checkbox}
                                type='checkbox'
                                id={`cameras[id-${i.id}]`}
                                label={i.name}
                            />
                            <div className={style.cameraAddress}>{i.objects.join(', ')}</div>
                        </div>
                        {key !== (_.size(camerasFields) -1) && <div className={style.delimiter} />}
                    </>
                )
            )}
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({
    camerasFields: makeCamerasFields(),
})

const withConnect = connect(
    mapStateToProps,
    null,
)

export default compose(withConnect)(Component)
