import React from "react"
import _ from 'lodash'

import Attachment from './attachment'
import style from './style.css'

function Component(props) {
    const { fileNameList } = props

    return (
        <div className={style.container}>
            {_.map(fileNameList, (item) => (
                <Attachment fileName={item.s3_file.file} realName={item.name} key={item.id} />
            ))}
        </div>
    )
}

export default Component
