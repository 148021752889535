import _ from 'lodash'

import { setGlobalBuilding } from '../../../../__data__/actions/buildings'
import { CHECKED_BUILDINGS_STORAGE_NAME } from '../../../../__data__/constants'

import { getBuildingsId, prepareBuildings, getChecked } from './utils'

function submit(values, dispatch) {
    const data = getChecked(values)
    const prepared = prepareBuildings(data)
    localStorage.setItem(CHECKED_BUILDINGS_STORAGE_NAME, prepared)

    // Если ни одно здание не выбрано, считаем что выбраны все. Иначе записываем выбранные. В ридакс стор.
    const allCheckedBuildings = _.isEmpty(prepared) ? getBuildingsId(values) : prepared
    dispatch(setGlobalBuilding(allCheckedBuildings))
}

export default submit
