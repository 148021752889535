import _ from 'lodash'

import { createNews }  from '../../__data__/actions/news'
import { NEWS_BUILDING_SELECT_ITEMS } from '../../__data__/constants'

const getData = (values) => {

    const files = _.filter(values.files, i => _.get(i, 'status') === 'done')
    const attachments = _.map(files, i => (i.id))

    const commonParams = {
        title: _.get(values, 'newsTitle'),
        description: _.get(values, 'newsDescription'),
        type: _.get(values, 'newsType'),
        // form_state: { ...values, files }, // Удалили файлы без статуса added
        attachments,
    }

    /* Все строения */
    if (values.homeNumber.value === NEWS_BUILDING_SELECT_ITEMS.all) {
        return {
            data: {
                for_all_objects: true,
                ...commonParams,
            }
        }
    }

    /* Некоторые строения */
    else if (values.homeNumber.value === NEWS_BUILDING_SELECT_ITEMS.some) {
        const targets = _.reduce(values.buildingsList, (result, value, key) => {
            if (value) {
                const type = key.includes('building') ? 'building' : 'porch'
                if (type === 'building') {
                    const id = key.replace('building-', '')
                    result.buildings.push(id)
                } else {
                    const id = key.replace('porch-', '')
                    result.porches.push(id)
                }
            }
            return result
        }, {buildings: [], porches: []})
        return {
            data: {
                ...targets,
                ...commonParams,
            }
        }
    }

    /* Выбран дом */
    else if (values.homeNumber.type === 'building') {
        /* Всему дому */
        if (values.buildingPropsToggle === 'home') {

            return {
                data: {
                    buildings: [values.homeNumber.value],
                    ...commonParams,
                }
            }
        }

        /* Квартиры */
        else if (values.buildingPropsToggle === 'apartments') {
            const units = _.map(values.flats, i => i.id)

            return {
                data: {
                    units,
                    ...commonParams,
                }
            }
        }

        /* Подъезды */
        else if (values.buildingPropsToggle === 'porches') {

            const porches = _.reduce(values.porches, (result, value, key) => {
                if (value) {
                    result.push(key)
                }
                return result
            }, [])

            const data = {
                porches,
                ...commonParams,
            }

            return { data }
        }

        /* Этажи */
        else if (values.buildingPropsToggle === 'floors') {

            const floors = _.reduce(values.floors, (result, value, key) => {
                if (value) {
                    result.push(key)
                }
                return result
            }, [])

            const data = {
                floors,
                ...commonParams,
            }

            return { data }
        }

        /* Стояки */
        else if (values.buildingPropsToggle === 'pipes') {

            const pipes = _.reduce(values.pipes, (result, value, key) => {
                if (value) {
                    result.push(key)
                }
                return result
            }, [])

            return {
                data: {
                    pipes,
                    ...commonParams,
                }
            }
        }
    }

    /* Выбран подъезд */
    else if (values.homeNumber.type === 'porch') {
        /* Всему подъезду */
        if (values.porchPropsToggle === 'porch') {

            return {
                data: {
                    porches: [values.homeNumber.value],
                    ...commonParams,
                }
            }
        }

        /* Квартиры */
        else if (values.porchPropsToggle === 'apartments') {
            const units = _.map(values.flats, i => i.id)

            return {
                data: {
                    units,
                    ...commonParams,
                }
            }
        }

        /* Этажи */
        else if (values.porchPropsToggle === 'floors') {

            const floors = _.reduce(values.floors, (result, value, key) => {
                if (value) {
                    result.push(key)
                }
                return result
            }, [])

            const data = {
                porches: [values.homeNumber.value],
                floors,
                ...commonParams,
            }

            return { data }
        }

        /* Стояки */
        else if (values.porchPropsToggle === 'pipes') {

            const pipes = _.reduce(values.pipes, (result, value, key) => {
                if (value) {
                    result.push(key)
                }
                return result
            }, [])

            return {
                data: {
                    pipes,
                    ...commonParams,
                }
            }
        }
    }

    return {
        data: {
            ...commonParams
        }
    }
}

function submit(values, dispatch) {
    const data = getData(values)
    dispatch(createNews(data))
}

export default submit
