import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, getFormValues } from 'redux-form'
import _ from 'lodash'

import { Checkbox, RadioGroupMenu, MultiSelectInput } from '../../../../components/form'
import formStyle from '../../../../theme/form.css'
import { flatsNumbersRequired } from '../../../form/validation'
import {
    makeBuildingFlats,
    makeBuildingPorches,
    makeBuildingFloorList,
    makeBuildingPipes,
} from '../../../../__data__/selectors/building'
import {
    NEWS_BUILDING_MENU_ITEMS,
    NEWS_BUILDING_MENU_ITEMS_ID,
} from '../../../../__data__/constants'

function Component(props) {
    const { flats, porches, floors, pipes } = props

    const buildingSelectValue = _.get(props, 'formValues.buildingPropsToggle')

    const porchesBuildingPorchesFieldsValues = _.get(props, 'formValues.porches')
    const floorsBuildingFloorsFieldsValues = _.get(props, 'formValues.floors')
    const buildingPipesFieldsValues = _.get(props, 'formValues.pipes')
    const isPorchesBuildingPorchesFieldsValuesValid = !_.isEmpty(porchesBuildingPorchesFieldsValues) && _.includes(porchesBuildingPorchesFieldsValues, true)
    const isFloorsBuildingFloorsFieldsValuesValid = !_.isEmpty(floorsBuildingFloorsFieldsValues) && _.includes(floorsBuildingFloorsFieldsValues, true)
    const isBuildingPipesFieldsValuesValid = !_.isEmpty(buildingPipesFieldsValues) && _.includes(buildingPipesFieldsValues, true)
    const isOneOfPorchesBuildingPorchesFieldsValues = () => isPorchesBuildingPorchesFieldsValuesValid ? undefined : 'Выберите хотя бы один подъезд'
    const isOneOfFloorsBuildingFloorsFieldsValues = () => isFloorsBuildingFloorsFieldsValuesValid ? undefined : 'Выберите хотя бы один этаж'
    const isOneOfBuildingPipesFields = () => isBuildingPipesFieldsValuesValid ? undefined : 'Выберите хотя бы один стояк'

    return (
        <Fragment>
            <RadioGroupMenu name='buildingPropsToggle' items={NEWS_BUILDING_MENU_ITEMS} />
            {buildingSelectValue === NEWS_BUILDING_MENU_ITEMS_ID.apartments &&
                <Field
                    name="flats"
                    component={MultiSelectInput}
                    label="Номера квартир"
                    options={flats}
                    validate={[ flatsNumbersRequired ]}
                />
            }
            {buildingSelectValue === NEWS_BUILDING_MENU_ITEMS_ID.porches &&
                <div className={formStyle.checkboxGroup}>
                    {_.map(porches, porch => (
                        <Field
                            name={`porches.${porch.id}`}
                            component={Checkbox}
                            type="checkbox"
                            label={`${porch.number}-му подъезду`}
                            value=''
                            id={`porches.${porch.id}`}
                            validate={[ isOneOfPorchesBuildingPorchesFieldsValues ]}
                        />
                    ))}
                </div>
            }
            {buildingSelectValue === NEWS_BUILDING_MENU_ITEMS_ID.floors &&
                <div className={formStyle.checkboxGroup}>
                    {_.map(floors, i => (
                        <Field
                            name={`floors.${i.id}`}
                            component={Checkbox}
                            type="checkbox"
                            label={`${i.number}-му этажу`}
                            value=''
                            id={`floors.${i.id}`}
                            validate={[ isOneOfFloorsBuildingFloorsFieldsValues ]}
                        />
                    ))}
                </div>
            }
            {buildingSelectValue === NEWS_BUILDING_MENU_ITEMS_ID.pipes &&
                <div className={formStyle.checkboxGroup}>
                    {_.map(pipes, i => (
                        <Field
                            name={`pipes.${i.id}`}
                            component={Checkbox}
                            type="checkbox"
                            label={i.name}
                            value=''
                            id={i.id}
                            validate={[ isOneOfBuildingPipesFields ]}
                            dataTip={i.details}
                        />
                    ))}
                </div>
            }
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    flats: makeBuildingFlats(),
    porches: makeBuildingPorches(ownProps.buildingId),
    floors: makeBuildingFloorList(),
    pipes: makeBuildingPipes(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
