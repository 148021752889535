import React from 'react'
import classnames from 'classnames'

import style from './radio-button.css'

function Component(props) {

    return (
        <div className={style.container}>
            <input
                className={style.field}
                type="radio"
                name={props.name}
                id={props.name + "_" + props.value}
                onClick={() => props.changePage(props.value)}
                checked={props.checked}
            />
            <label
                className={classnames(style.label, style[`status-default`])}
                htmlFor={props.name + "_" + props.value}
            >{props.label}</label>
        </div>
    )
}


export default Component
