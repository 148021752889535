import React from 'react'
import { Redirect } from 'react-router-dom'

import { getToken } from '../../__data__/utils'
import { MODERATOR_STORAGE_NAME, URL_LIST } from '../../__data__/constants'

import LoginForm from './components/login-form'
import style from './style.css'

function Component() {
    const token = getToken(MODERATOR_STORAGE_NAME)

    if (token) {
        return <Redirect to={URL_LIST.profile} />
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <LoginForm />
            </div>
        </div>
    )
}

export default Component
