import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { makeBuildingFlats } from '../../../__data__/selectors/building'
import { FlatSelectInput } from '../../../components/form'

function Component(props) {
    const { input, meta, flatsList, label } = props

    return (
        <FlatSelectInput input={input} meta={meta} flatsList={flatsList} label={label} />
    )
}

const mapStateToProps = createStructuredSelector({
    flatsList: makeBuildingFlats(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
