export const GET_SETTINGS_STAFF_PAGINATION = 'GET_SETTINGS_STAFF_PAGINATION'
export const GET_SETTINGS_STAFF_SUCCESS_PAGINATION = 'GET_SETTINGS_STAFF_SUCCESS_PAGINATION'
export const GET_SETTINGS_STAFF_FAIL_PAGINATION = 'GET_SETTINGS_STAFF_FAIL_PAGINATION'

export const GET_SETTINGS_EMPLOYEE = 'GET_SETTINGS_EMPLOYEE'
export const GET_SETTINGS_EMPLOYEE_SUCCESS = 'GET_SETTINGS_EMPLOYEE_SUCCESS'
export const GET_SETTINGS_EMPLOYEE_FAIL = 'GET_SETTINGS_EMPLOYEE_FAIL'

export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION'
export const GET_COMPANY_INFORMATION_SUCCESS = 'GET_COMPANY_INFORMATION_SUCCESS'
export const GET_COMPANY_INFORMATION_FAIL = 'GET_COMPANY_INFORMATION_FAIL'

export const GET_SETTINGS_BUILDINGS_PAGINATION = 'GET_SETTINGS_BUILDINGS_PAGINATION'
export const GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION = 'GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION'
export const GET_SETTINGS_BUILDINGS_FAIL_PAGINATION = 'GET_SETTINGS_BUILDINGS_FAIL_PAGINATION'

export const GET_SETTINGS_OBJECT = 'GET_SETTINGS_OBJECT'
export const GET_SETTINGS_OBJECT_SUCCESS = 'GET_SETTINGS_OBJECT_SUCCESS'
export const GET_SETTINGS_OBJECT_FAIL = 'GET_SETTINGS_OBJECT_FAIL'
