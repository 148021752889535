import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import {
    MODERATOR_STORAGE_NAME,
    URL_LIST,
    CHAT_TOKEN,
    PERMISSION_SECTIONS,
    MODERATOR_STORAGE_ROLE_NAME, PROFILE_COMPANY_ID, PROFILE_USER_ID, CHAT_KEEP_LOGGED_IN
} from '../../../../__data__/constants'
import { formatDate, formatPhoneNumber } from '../../../../__data__/utils'

import { default as Avatar } from './avatar'
import style from './style.css'

function Component(props) {
    const { avatar, firstName, lastName, phoneNumber, date, subsidiary, position, accesses } = props

    const [shouldRedirect, setRedirect] = useState(false)

    const handleClick = useCallback(e => {
        e.preventDefault()
        localStorage.removeItem(MODERATOR_STORAGE_NAME)
        localStorage.removeItem(MODERATOR_STORAGE_ROLE_NAME)
        localStorage.removeItem(CHAT_TOKEN)
        localStorage.removeItem(PERMISSION_SECTIONS)
        localStorage.removeItem(PROFILE_COMPANY_ID)
        localStorage.removeItem(PROFILE_USER_ID)
        localStorage.removeItem(CHAT_KEEP_LOGGED_IN)
        setRedirect(true)
    }, [MODERATOR_STORAGE_NAME, MODERATOR_STORAGE_ROLE_NAME, CHAT_TOKEN, PERMISSION_SECTIONS, PROFILE_COMPANY_ID, PROFILE_USER_ID, CHAT_KEEP_LOGGED_IN])

    if(shouldRedirect) {
        return <Redirect to={URL_LIST.loginForm} />
    }

    return (
        <div className={style.container}>
            <div className={style.personalInfo}>
                <div className={style.avatar}>
                   <Avatar avatar={avatar} />
                </div>
                <div className={style.mainDetailsInfo}>
                    <div className={style.mainDetailsInfoName}>{`${firstName} ${lastName}`}</div>
                    <div className={style.mainDetailsInfoPosition}>{position}</div>
                    <div className={style.mainDetailsInfoOrganization}>{_.get(subsidiary, 'label')}</div>
                </div>
                <div>
                    <a onClick={handleClick} className={style.logoutLink} href="/">
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.80491 5.62223H4.1019C3.75826 5.62223 3.47968 5.34365 3.47968 5C3.47968 4.65636 3.75826 4.37778 4.1019 4.37778H8.80491C9.32548 4.36471 9.46442 5.61048 8.80491 5.62223Z" fill="#1E1F21"/>
                            <path d="M4.80489 9.11358C4.80489 9.41904 4.5412 9.66667 4.21591 9.66667H1.98183C1.3444 9.66667 0.833311 9.16889 0.833311 8.5605V1.43951C0.833311 0.825583 1.3444 0.333336 1.98183 0.333336H4.21591C4.5412 0.333336 4.80489 0.580961 4.80489 0.886422C4.80489 1.19188 4.5412 1.43951 4.21591 1.43951H1.98183V8.5605H4.21591C4.5412 8.5605 4.80489 8.80812 4.80489 9.11358Z" fill="#1E1F21"/>
                            <path d="M10.6456 4.55042C10.8959 4.79872 10.8959 5.20129 10.6456 5.44959L8.59382 7.48044C8.34359 7.72874 7.93788 7.72874 7.68765 7.48044C7.43742 7.23214 7.43742 6.82957 7.68765 6.58127L9.28639 5L7.68765 3.41874C7.43742 3.17043 7.43742 2.76786 7.68765 2.51956C7.93788 2.27126 8.34359 2.27126 8.59382 2.51956L10.6456 4.55042Z" fill="#1E1F21"/>
                        </svg>
                        <span className={style.logoutLinkText}>Выйти</span>
                    </a>
                    <Link className={style.resetPassLink} to={URL_LIST.passwordChangeLoggedForm}>Изменить пароль</Link>
                </div>
            </div>
            <div className={style.details}>
                <div className={style.detail}>
                    <div className={style.detailLabel}>Тел.</div>
                    {phoneNumber && <div className={style.detailValue}>{formatPhoneNumber(phoneNumber)}</div>}
                </div>
                <div className={style.detail}>
                    <div className={style.detailLabel}>Дата рег.</div>
                    {date && <div className={style.detailValue}>{formatDate(date, 'D MMMM YYYY')}</div>}
                </div>
                <div className={style.detail}>
                    <div className={style.detailLabel}>Разделы</div>
                    {accesses && <div className={classNames(style.detailValue, style.accessesValue)}>{accesses}</div>}
                </div>
            </div>
        </div>
    )
}

export default Component
