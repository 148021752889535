export const newsIcons = {
    info: '🔔️️',
    accident: '⚠️️️',
    alert: '⚠️️️',
    report: '🔔️️',
    warn: '⚠️️️',
    adv: '🔔️️',
}

export const complaintIcons = {
    complaint: '⚠️️️',
    appreciation: '🔔️️',
    suggestion: '🔔️️',
}

export const chatIcons = {
    message: '🔔️️',
}

export const bookletIcons = {
    rules: '⚠️️️',
    safety: '⚠️️️',
    muni: '⚠️️️',
    domcom: '🔔️️',
}

export const cameraIcons = {
    create: '🔔️️',
}

export const taskIcons = {
    created: '🔔️️',
    in_progress: '⚠️️️',
    done: '⚠️️️',
}
