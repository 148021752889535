import React from 'react'

export default function ColdWater() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20.3984C15.0859 20.3984 17.5469 17.9922 17.5469 14.9766C17.5469 13.3047 16.7188 11.6719 16.0078 10.3438L13.1719 5.03906C12.8984 4.55469 12.5 4.3125 12 4.3125C11.4844 4.3125 11.0859 4.55469 10.8203 5.03906L7.98438 10.3438C7.27344 11.6719 6.44531 13.3047 6.44531 14.9766C6.44531 17.9922 8.90625 20.3984 12 20.3984ZM12 18.8516C9.77344 18.8516 7.99219 17.1406 7.99219 14.9766C7.99219 13.6562 8.66406 12.4062 9.375 11.0781L11.9219 6.32812C11.9609 6.24219 12.0469 6.24219 12.0859 6.32812L14.625 11.0781C15.3359 12.3984 16 13.6562 16 14.9766C16 17.1406 14.2188 18.8516 12 18.8516Z" fill="#2944FF"/>
    </svg>

}



