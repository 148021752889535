export const TAGS_VALUES = {
    all: 'all',
    building: 'building',
    porch: 'porch',
    company: 'company',
    resident: 'resident'
}

export function getBuildingAndPorchTagValues (buildingsIds, buildingsList) {
    return buildingsIds.reduce((accum, current) => {
        current = Number(current);
        if(buildingsList.has(current)) {
            const building = buildingsList.get(current);
            const address = `${building.street || ''}, ${building.number || ''}`
            if (building.is_all_porches) {
                accum.push({tag: `${TAGS_VALUES.building}_${current}`, address});
            }
            const porches = building?.available_porches?.map((porch) => ({
                tag: `${TAGS_VALUES.porch}_${porch.id}`,
                address,
                porch: porch.number || ''
            })) || [];
            accum = accum.concat(porches);
        }
        return accum;
    }, []);
}

export function getEmployeeTagValues (id) {
    return [{tag: `${TAGS_VALUES.company}_${id}`}]
}

export function getResidentTagValues(checkedBuildingsIds, buildingsList) {
    let tags = []
    let address = null
    for (let buildingId of checkedBuildingsIds) {
        buildingId = Number(buildingId)
        if (buildingsList.has(buildingId)) {
            const building = buildingsList.get(buildingId);
            address = `${building.street || ''}, ${building.number || ''}`
            if (building.is_all_porches) {
                tags.push({
                    tag: `${TAGS_VALUES.resident} ${TAGS_VALUES.building}_${buildingId}`,
                    address
                })
            } else {
                const porches = building?.available_porches?.map((porch) => ({
                    tag: `${TAGS_VALUES.resident} ${TAGS_VALUES.porch}_${porch.id}`,
                    address,
                    porch: porch.number || ''
                })) || [];
                tags = tags.concat(porches)
            }
        }
    }
    return tags
}
