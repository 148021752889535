import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { FieldErrorMessage } from '../index'
import Label from '../label'

import style from './style.css'

function Component(props) {
    const isInvalid = props.meta.invalid && props.meta.touched

    return (
        <div>
            <div className={style.container}>
                <textarea {...props.input} placeholder={props.placeholder} className={classNames(
                    style.field,
                    style.textarea,
                    isInvalid && style.invalid,
                    style[`size-${props.size}`],
                )} />
                <Label>{props.label}</Label>
            </div>
            <FieldErrorMessage meta={props.meta} />
        </div>
    )
}

Component.propTypes = {
    size: PropTypes.string,
}

Component.defaultProps = {
    size: 'auto',
}

export default Component
