import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

function Component(props) {
    const { taskId, handleRejectModal } = props

    const handleClick = useCallback(e => {
        e.preventDefault()
        handleRejectModal(taskId)

    }, [handleRejectModal, taskId])

    return (
        <Link onClick={handleClick} to="/" className={props.className}>Отменить заявку</Link>
    )
}

export default Component
