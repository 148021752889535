import { createSelector } from 'reselect'
import _ from 'lodash'

const app = state => _.get(state, 'app', {})

export const makeNewsList = () =>
    createSelector(app, slice => _.get(slice, 'newsList'))

export const makeIsNewsListChanged = () =>
    createSelector(app, slice => _.get(slice, 'isNewsListChanged'))

export const makeNewsListNextUrl = () =>
    createSelector(app, slice => _.get(slice, 'newsListNextUrl'))

export const makeIsNextNewsListLoading = () =>
    createSelector(app, slice => _.get(slice, 'isNextNewsListLoading'))

export const makeCarousel = () =>
    createSelector(app, slice => {
        const data = _.get(slice, 'carousel')
        return {
            ...data
        }
    })