import _ from 'lodash'
import { SubmissionError } from 'redux-form'
import { login }  from '../../../../__data__/actions/login'
import { LoginValidationMessages } from '../../../../components/form/validation'
import { getPermissionSections } from "../../../../__data__/actions/permissions";

const getData = (values) => ({
    // username: _.get(values, 'name'),
    username: values.name.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
    password: _.get(values, 'password')
})

function onSubmitForm(values, dispatch) {
    const data = getData(values);

    return dispatch(login(data))
        .catch((error) => {
            throw new SubmissionError(LoginValidationMessages[error?.message] || ' ')
        })
        .then(() => {
            return dispatch(getPermissionSections());
        })
        .catch((error) => {
            console.error(error, 'Cannot initiate the permission sections')
        });
}

export default onSubmitForm
