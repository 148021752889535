import React from 'react';
export default function activeIcon(){
        return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84837 0C4.93079 0.62322 1.42419 3.10559 0.00151034 5.15586L0.00146069 5.1559C-0.0488133 7.18728 1.20584 11.3809 2.65437 13.4125C4.51219 14.1328 9.075 14.2361 11.4365 13.4681C12.6307 11.8316 14.0339 7.54767 13.9994 5.04552C12.8021 3.41139 9.22891 0.706015 6.84837 0ZM10.2205 5.29287C10.6135 4.94392 11.2126 4.97018 11.5736 5.35217C11.9459 5.74605 11.9245 6.36813 11.5262 6.7356L6.9622 10.9461C6.53871 11.3368 5.87353 11.2897 5.50929 10.8432L3.42907 8.29343C3.13634 7.93463 3.15662 7.41401 3.47637 7.07907C3.86637 6.67054 4.52925 6.70766 4.87119 7.15718L6.1811 8.87918L10.2205 5.29287Z" fill="#65BBFA"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="14" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        )
}


