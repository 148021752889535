import { createSelector } from 'reselect'
import _ from 'lodash'

import { formatDate } from '../utils'
import { METERS } from '../constants'

const app = state => _.get(state, 'app', {})

const getSenderText = (type, name) => {
    if (type === 'meter') return 'Умный счётчик автоматически'
    else if (type === 'resident') return `${name} через приложение`
    else if (type === 'employee') return 'Консьерж через панель Управдом'
}

export const makeMetersObject = () =>
    createSelector(app, slice => {
        const metersObject = _.get(slice, 'meters', [])

        const list =  _.map(metersObject.results, i => {
            /* TODO: Создать отдельный селектор для карточки (дома) с показаниями. Иначе создаём для каждой квартиры карточку, а это оверхед. Они не нужны. */
            const metersList = _.map(i.passes, i => ({
                date: formatDate(i.date, 'D MMMM, в h:mm' ),
                id: i.id,
                sender: getSenderText(i.sender.type, `${i.sender.last_name} ${i.sender.first_name}`),
                passes: _.map(i.meters, (value, key) => ({
                    name: METERS[key],
                    value: value,
                })),
            }))

            return ({
                homeNumber: `${i['unit']['building']['street']}, ${i['unit']['building']['number']}`,
                flatNumber: i.unit.label,
                flatId: i.unit.id,
                firstName: _.get(i,'month_result.sender.first_name'),
                lastName: _.get(i ,'month_result.sender.last_name'),
                senderId: _.get(i ,'month_result.sender.id'),
                senderType: _.get(i ,'month_result.sender.type'),
                date: formatDate(_.get(i ,'month_result.date'), 'D MMMM'),
                monthMeters: _.get(i, 'month_result.meters'),
                metersList: _.reverse(metersList),
            })
        })

        return {
            list: list,
            count: metersObject.count,
            previous: metersObject.previous,
            next: metersObject.next,
            number: metersObject.number,
            page_size: metersObject.page_size
        }
    })

// METERS LIST
export const makeBuildingsSettingsMeterTypes = () =>
    createSelector(app, slice => _.get(slice, 'buildingsSettings.reading_types'))

// Даты подачи показаний.
export const makeBuildingsSettingsSubmitDays = () =>
    createSelector(app, slice => ({
        startDay: _.get(slice, 'buildingsSettings.submit_start_day'),
        endDay: _.get(slice, 'buildingsSettings.submit_end_day'),
    }))
