import React from 'react';
import {bytesToHumanSize} from '../../utils/blob-helpers';
import File from '../../icons/file.svg'

import style from './style.css'

export const Attachment = ({url, filename, size}) => {
    const onClick = () => {
        if(!url) {
            console.log('файл слишком большой')
            //возможно, файл слишком большой
        }
    }

    return (
        <div className={style.attachment}>
            <img src={File} alt="download-file"/>
            <div className={style.attachmentContent}>
                <span className={style.attachmentText}>{filename}<small> ({bytesToHumanSize(size)})</small></span>
                <a className={style.attachmentLink} onClick={onClick} href={url} name={filename} download={filename}>Загрузить</a>
            </div>
        </div>
    )
}
