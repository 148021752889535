import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import searchIcon from '../../assets/icon-search.svg'

import style from './style.css'

export const SearchField = ({onSearch, selectedTab, checkedBuildings}) => {
    const [search, setSearch] = useState('');

    useEffect(() => {
        setSearch('');
    }, [selectedTab.title, checkedBuildings])

    const onChange = (event) => {
        setSearch(event.target.value);
        onSearch(event.target.value);
    }

    return (
        <label className={style.field} htmlFor="chat-contacts-search">
            <img src={searchIcon} alt="search-icon" />
            <input onChange={onChange} value={search} className={style.input} id="chat-contacts-search"/>
        </label>
    )
}

SearchField.propTypes = {
    onSearch: PropTypes.func,
    selectedTab: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string
    }),
    checkedBuildings: PropTypes.array
}
