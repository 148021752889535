import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field } from 'redux-form'
import _ from 'lodash'

import { Checkbox } from '../../../../components/form'
import formStyle from '../../../../theme/form.css'
import { makeSomeBuildingsFieldsValues, makeSomeBuildingsFields } from '../../../../__data__/selectors/news-form'

function Component(props) {
    const values = _.get(props, 'formValues.buildingsList', [])
    const isValid = !_.isEmpty(values) && _.includes(values, true)
    const isOneOf = () => isValid ? undefined : 'Выберите хотя бы одно значение'

    return (
        <div className={formStyle.checkboxGroup}>
            {_.map(props.buildingsList, building => (
                <Field
                    name={`buildingsList.${building.type}-${building.id}`}
                    component={Checkbox}
                    type='checkbox'
                    label={building.label}
                    value=''
                    id={`${building.type}-${building.id}`}
                    validate={[ isOneOf ]}
                />
            ))}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    buildingsList: makeSomeBuildingsFields(),
    values: makeSomeBuildingsFieldsValues(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
