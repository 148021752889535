import React from 'react'

export default function Pyramid() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.99995 16.5234C2.53413 17.3232 2.70113 18.167 3.51851 18.5273L11.2529 21.8936C11.7363 22.1045 12.2548 22.1045 12.7558 21.8936L20.4814 18.5273C21.2988 18.167 21.4658 17.3232 21 16.5234L12.9931 2.68945C12.7294 2.23242 12.3603 2.0918 12 2.0918C11.6396 2.0918 11.2705 2.23242 11.0068 2.68945L2.99995 16.5234ZM4.66109 16.8398L11.2089 5.66016V20.2588L4.79292 17.3145C4.60835 17.2266 4.54683 17.0508 4.66109 16.8398ZM19.3388 16.8398C19.4531 17.0508 19.3916 17.2266 19.207 17.3145L12.791 20.2588V5.66016L19.3388 16.8398Z" fill="#8A9CAD"/>
        </svg>
}



