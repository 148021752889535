import React from 'react'
import _ from 'lodash'

import style from './style.css'
import Attachment from './attachment'

function Images(props) {
    const { imageNames } = props

    if (_.isEmpty(imageNames)) return null
    return (
        <div className={style.container}>
            {_.map(imageNames, (item) => (
                <Attachment url={item} key={item.id} />
            ))}
        </div>
    )
}

export default Images
