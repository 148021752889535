import React from 'react'

import style from './style.css'
import {Button} from "@material-ui/core";
import ReactTooltip from "react-tooltip";

export let Component = (props) => {
    const { setTab, activeTab } = props
    const correctTipId = 'correctTipId'
    const incorrectTipId = 'incorrectTipId'

    const handleClick = (e) => {
        e.preventDefault()
        const valueElement = e.currentTarget.dataset.id
        setTab(valueElement)
    }

    return (
        <div className={style.container}>
            <div className={style.container} data-tip='Им будет отправлено приглашение' data-for={correctTipId}>
                <ReactTooltip id={correctTipId} className={style.tooltip} />
                <Button href="/" data-id='correct' className={activeTab === 'correct' && style.active} onClick={handleClick} color="default">Корректно</Button>
            </div>
            <div className={style.container} data-tip='Не смогут получить приглашение' data-for={incorrectTipId}>
                <ReactTooltip id={incorrectTipId} className={style.tooltip} />
                <Button href="/" data-id='incorrect' className={activeTab === 'incorrect' && style.active} onClick={handleClick} color="secondary">С ошибками</Button>
            </div>
        </div>
    )
}

export default Component
