import React from "react"
import _ from 'lodash'

import style from './style.css'

function Component (props) {
    const { imageUrl } = props

    return (
        <div className={style.imgWrapper}>
            <img className={style.img} src={imageUrl} />
        </div>
    )
}

export default Component
