import React from 'react'
import { Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { makeRedirectUrl } from '../../__data__/selectors/common'
import { clearRedirectUrl } from '../../__data__/actions/redirect'

// Этот редирект используется на формах, при успешном сабмите

function Component(props) {
    const { children, redirectUrl, clearRedirectUrl } = props

    if (redirectUrl) {
        clearRedirectUrl()
        return <Redirect to={redirectUrl} />
    }

    return children
}

const mapStateToProps = createStructuredSelector({
    redirectUrl: makeRedirectUrl(),
})

const mapDispatchToProps = {
    clearRedirectUrl,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
