import React from 'react'

import { formatDate } from '../../../../../__data__/utils'

import style from './selected-performer.css'

const Component = props => {
    const { performer, user, date } = props

    const d = formatDate(date, 'D MMMM, в h:mm')

    return (
        <div className={style.container}>
            <div className={style.body}>
                <span>{`Назначен ${performer.position} `}</span>
                <span className={style.performerName}>{`${performer.last_name} ${performer.first_name}`}</span>
            </div>
            <div className={style.footer}>
                <div className={style.userName}>{`${user.last_name} ${user.first_name}`}</div>
                <div className={style.date}>{d}</div>
            </div>
        </div>
    )
}

export default Component
