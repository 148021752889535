import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import _ from 'lodash'
import classNames from 'classnames'

import { getMeterIcon } from '../utils'

import style from './style.css'

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    label: {
        fontSize: '12px',
        fontWeight: '600',
        color: '#757678',
        lineHeight: 1,
    },
    th: {
        padding: '10px 0 4px 16px',
        borderColor: 'transparent',
        borderBottom: '1px solid #e3e4e5',
    },
    thContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}))

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, meterTypes, isAllBuildings } = props

    const classes = useStyles()

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const headCells = [
        { id: 'flatNumber', numeric: false, disablePadding: false, label: 'Кв.' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Кто сдал' },
        { id: 'date', numeric: false, disablePadding: false, label: 'Когда' },
    ]

    isAllBuildings && headCells.unshift(    { id: 'homeNumber', numeric: false, disablePadding: false, label: 'Дом' })
    _.includes(meterTypes, 'water_cold') && headCells.push({ id: 'water_cold', numeric: true, disablePadding: false, label: 'ХВС', icon: getMeterIcon('water_cold') })
    _.includes(meterTypes, 'water_hot') && headCells.push({ id: 'water_hot', numeric: true, disablePadding: false, label: 'ГВС', icon: getMeterIcon('water_hot') })
    _.includes(meterTypes, 'elec_day') && headCells.push({ id: 'elec_day', numeric: false, disablePadding: false, label: 'Эл. день', icon: getMeterIcon('elec_day') })
    _.includes(meterTypes, 'elec_night') && headCells.push({ id: 'elec_night', numeric: true, disablePadding: false, label: 'Эл. ночь', icon: getMeterIcon('elec_night') })
    _.includes(meterTypes, 'gas') && headCells.push({ id: 'gas', numeric: false, disablePadding: false, label: 'Газ', icon: getMeterIcon('gas') })
    _.includes(meterTypes, 'heating') && headCells.push({ id: 'heating', numeric: false, disablePadding: false, label: 'Отоп.', icon: getMeterIcon('heating') })

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.th}
                        width={headCell.width}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <div className={classes.thContainer}>
                                { _.get(headCell, 'icon') && <div className={classNames(style.icon, style[_.get(headCell, 'id')])}>{_.get(headCell, 'icon')}</div> }
                                <div className={classes.label}>{headCell.label}</div>
                                {orderBy === headCell.id && (
                                    <div className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </div>
                                )}
                            </div>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead
