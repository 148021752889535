import React from 'react'

import style from './style.css'

function Component(props) {
    return (
        <div>
            <input
                {...props.input}
                className={style.field}
                type='checkbox'
                id={props.id}
            />
            <label
                className={style.label}
                htmlFor={props.id}
            >{props.label}</label>
        </div>
    )
}

Component.defaultProps = {
    status: 'default',
}

export default Component
