import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import style from './cancel-action.css'
import {CHECKED_BUILDINGS_STORAGE_NAME} from "../../../../__data__/constants";

function Component(props) {
    const { initialize, buildingsList, setHomeSelect } = props

    const handleClick = () => {
        const storageCheckedBuildingsId = localStorage.getItem(CHECKED_BUILDINGS_STORAGE_NAME) ?
            localStorage.getItem(CHECKED_BUILDINGS_STORAGE_NAME).split(',') : ''

        const fieldsNames = _.reduce(buildingsList, (result, tenant, tenantId) => {
            result[`group-${tenantId}`] = {}
            _.forEach(tenant.items, building => {
                const isChecked = _.includes(storageCheckedBuildingsId, building.value)
                result[`group-${tenantId}`][`building-${building.value}`] = isChecked
            })
            return result
        }, [])
        initialize({
            ...fieldsNames
        })
        setHomeSelect(false)
    }

    return (
        <button
            type='button'
            onClick={handleClick}
            className={style.container}
        >
            Отменить
        </button>
    )
}

export default connect()(Component)
