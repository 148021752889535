import { createSelector } from 'reselect'
import _ from 'lodash'

import { formatPhoneNumber, getBuildingString } from '../../../__data__/utils'

const app = state => _.get(state, 'app', {})

export const makeStaffsObject = () =>
    createSelector(app, slice => {
        const staffsObject = _.get(slice, 'settingsStaff', {})
        const staffsList =  _.map(staffsObject.results, i => ({
            name: `${i['last_name']} ${i['first_name']}`,
            firstName: i['first_name'],
            lastName: i['last_name'],
            phone: formatPhoneNumber(i['phone_number']),
            email: i['email'],
            position: i['position'],
            sections: i['sections'],
            buildings_list: i['buildings'] ?  i['buildings']: [],
            porchesList: i['porches'] ?  i['porches']: [],
            user: i['user'],
            company: i['company'],
            tenant:i['tenant'],
            status:i['status'],
            avatar:i['avatar'],
            userChatId: i['user_chat_id']
        }))
        return {
            list: staffsList,
            count: staffsObject.count,
            previous: staffsObject.previous,
            next: staffsObject.next,
            number: staffsObject.number,
            page_size: staffsObject.page_size
        }
    })

const mergeBuildings = (availableBuildings, checkedBuildings) => {
    const result = []
    _.forEach(checkedBuildings, checkedBuilding => {
        const availableBuilding = _.find(availableBuildings, p => p.id === checkedBuilding.id)
        const isEditable = !!availableBuilding
        result.push({ ...checkedBuilding, isEditable })
    })
    _.forEach(availableBuildings, availableBuilding => {
        if (!_.find(result, p => p.id === availableBuilding.id)) {
            result.push({ ...availableBuilding, isEditable: true })
        }
    })

    return result
}

export const makeEmployeeInitialData = () => createSelector(app, slice => {
    const employee = _.get(slice, 'employee')
    const allBuildings = _.get(slice, 'buildings')
    const allServices = _.get(slice, 'services')

    if (!employee || !allBuildings || !allServices) return {}

    const tasks = _.get(employee, 'sections.tasks')
    const chats = _.get(employee, 'sections.chats')
    const residents = _.get(employee, 'sections.residents')
    const readings = _.get(employee, 'sections.readings')
    const cameras = _.get(employee, 'sections.cameras')
    const employees = _.get(employee, 'sections.employees')
    const buildingsSettings = _.get(employee, 'sections.buildings')
    const employeeBuildings = _.get(employee, 'buildings')

    const services = _.map(_.get(employee, 'services'), i => {
        const service = _.find(allServices, s => s.id === i.id)
        return { label: service.name, id: service.id }
    })

    const mergedBuildings = mergeBuildings(allBuildings, employeeBuildings)
    const groupedBuildings =_.groupBy(mergedBuildings, i => _.get(i, 'complex.name', 'Без жилого комплекса'))

    // подъезды, сортированные по зданиям
    const sortedPorches = {}
    _.forEach(allBuildings, building => {
        const buildingId = building.id
        const buildingName = getBuildingString(building)

        const employeeBuilding = _.find(_.get(employee, 'buildings'), i => i.id === buildingId)
        const employeeBuildingPorches = _.get(employeeBuilding, 'available_porches', [])
        const buildingPorches = _.get(building, 'available_porches', [])

        // Найти хоть одно совпадение подъезда employeeBuildingPorches и buildingPorches
        const hasAccess = !!_.find(buildingPorches, i => _.find(employeeBuildingPorches, p => p.id === i.id))

        // Список недоступных для выбора подъездов
        const disabledPorches = _.filter(employeeBuildingPorches, i => !_.find(buildingPorches, p => p.id === i.id))
        
        // Подъезды здания со значениями
        const porchesValues= {}
        _.forEach(buildingPorches, porch => {
            porchesValues[porch.id] = !!_.find(employeeBuildingPorches, p => p.id === porch.id)
        })
        sortedPorches[buildingId] = {}
        sortedPorches[buildingId]['buildingName'] = buildingName
        sortedPorches[buildingId]['hasAccess'] = hasAccess
        sortedPorches[buildingId]['porches'] = porchesValues
        sortedPorches[buildingId]['porchesData'] = buildingPorches
        sortedPorches[buildingId]['disabledPorches'] = disabledPorches
    })

    return {
        user: _.get(employee, 'user'),
        firstName: _.get(employee, 'first_name'),
        lastName: _.get(employee, 'last_name'),
        gender: _.get(employee, 'gender'),
        position: _.get(employee, 'position'),
        companyId: _.get(employee, 'company.id'),
        phoneNumber: _.get(employee, 'phone_number'),
        news: _.get(employee, 'sections.news'),
        isTasks: !!tasks,
        tasks,
        isChats: !!chats,
        chats,
        isResidents: !!residents,
        residents,
        isReadings: !!readings,
        readings,
        isCameras: !!cameras,
        cameras,
        isSettings: employees || buildingsSettings,
        employees,
        buildingsSettings,
        sortedPorches,
        groupedBuildings,
        services,
    }
})

const getWaterType = (waterCold, waterHot) => {
    if (waterCold && waterHot) return 'water_hot_and_cold'
    if (waterCold || waterHot) return 'water_common'
    return 'water_common'
}

const getElecType = (elecDay, elecNight) => {
    if (elecDay && elecNight) return 'elec_day_and_night'
    if (elecDay || elecNight ) return 'elec_common'
    return 'elec_common'
}

export const makeObjectFormInitialData = () => createSelector(app, slice => {
    const object = _.get(slice, 'settingsObject')

    const address = `${object?.building.street} ${object?.building.number}`
    const buildingType = object?.building.building_type
    const floorCount = object?.building.floor_count
    const porchesCount = object?.building.porches_count
    const flatsCount = object?.building.units_count
    const organisationName = object?.building.tenant.label

    const startDate = _.get(object, 'submit_start_day')
    const endDate = _.get(object, 'submit_end_day')
    const readingTypes = _.get(object, 'reading_types')
    const waterCold = _.includes(readingTypes, 'water_cold')
    const waterHot = _.includes(readingTypes, 'water_hot')
    const elecDay = _.includes(readingTypes, 'elec_day')
    const elecNight = _.includes(readingTypes, 'elec_night')
    const gas = _.includes(readingTypes, 'gas')
    const heating = _.includes(readingTypes, 'heating')

    const isWater = !!waterHot || !!waterCold
    const isElec = !!elecDay || !!elecNight

    const cameras = _.reduce(_.get(object, 'cameras'), (result, i) => {

        result[`id-${i.id}`] = i.is_selected
        return result
    }, {})

    return ({
        address,
        buildingType,
        floorCount,
        porchesCount,
        flatsCount,
        organisationName,
        startDate,
        endDate,
        isWater,
        waterType: getWaterType(waterCold, waterHot),
        isElec: isElec,
        elecType: getElecType(elecDay, elecNight),
        isGas: gas,
        isHeating: heating,
        cameras,
    })
})


export const makeCamerasFields = () => createSelector(app, slice => {
    const cameras = _.get(slice, ['settingsObject', 'cameras'])

    return _.map(cameras, camera => ({
        id: camera.id,
        name: camera.name,
        objects: _.map(camera.buildings, building => `${building.street} ${building.number}`),
    }))
})

export const makeIsEmployeeListChanged = () =>
    createSelector(app, slice => _.get(slice, 'isEmployeeListChanged'))

export const makeBuildingsWithGroups = () => createSelector(app, slice => {
    const buildings = _.get(slice, 'buildings')
    return _.groupBy(buildings, i => _.get(i, 'complex.name', 'Без жилого комплекса'))
})

export const makeServiceList = () =>
    createSelector(app, slice => {
        const services = _.get(slice, 'services')
        if (!services) return null
        return _.map(services, item => ({ id: item.id, label: item.name }))
    })
