import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Field, getFormValues } from 'redux-form'
import _ from 'lodash'

import { Checkbox, RadioGroupMenu } from '../../../../components/form'
import formStyle from '../../../../theme/form.css'
import {
    makeBuildingPorches,
    makeBuildingFloorList,
} from '../../../../__data__/selectors/building'
import {
    TASK_PORCHES_MENU_ITEMS,
    TASK_PORCHES_ITEMS_ID,
} from '../../../../__data__/constants'

function Component(props) {
    const { formValues, buildingPorches } = props

    const porchesToggleValue = _.get(formValues, 'porchesToggle')
    const porches = _.get(formValues, 'porches')

    const isPorchesValid = !_.isEmpty(porches) && _.includes(porches, true)
    const isOneOfPorches = () => isPorchesValid ? undefined : 'Выберите хотя бы один подъезд'

    return (
        <>
            <RadioGroupMenu name='porchesToggle' items={TASK_PORCHES_MENU_ITEMS} />
            {porchesToggleValue === TASK_PORCHES_ITEMS_ID.porches &&
                <div className={formStyle.checkboxGroup}>
                    {_.map(buildingPorches, porch => (
                        <Field
                            name={`porches.${porch.id}`}
                            component={Checkbox}
                            type="checkbox"
                            label={`№ ${porch.number}`}
                            value=''
                            id={`porches.${porch.id}`}
                            validate={[ isOneOfPorches ]}
                        />
                    ))}
                </div>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => createStructuredSelector({
    formValues: getFormValues(ownProps.formName),
    buildingPorches: makeBuildingPorches(),
    buildingFloorList: makeBuildingFloorList(),
})

const withConnect = connect(
    mapStateToProps,
)

export default compose(withConnect)(Component)
