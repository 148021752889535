import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import classNames from 'classnames'

import { getTaskDetails } from '../../../../__data__/actions/tasks'
import { makeActiveTask, makeDetailsLoadingStatus, makeTasksSortedList } from '../../../../__data__/selectors/tasks'

import style from './style.css'
import Actions from './actions'
import History from './history'

const Component = props => {
    const { id, getTaskDetails, data, changeVisibility, isTaskDetailsLoading, isDragging, handleRejectModal, tasksSortedList } = props
    const {
        name,
        street,
        homeNumber,
        floors,
        porches,
        icon,
        serviceId,
        buildingId,
        performer,
        stage,
        resolution,
        history,
        taskName,
    } = data

    useEffect(() => {
        getTaskDetails(id)
    }, [id])

    // Скрываем окно когда драгаем любую таску. Тогда при открытии окно запросит свежие данные и не нужно городить дубляж логики.
    useEffect(() => {
        isDragging && changeVisibility(false)
    }, [isDragging])

    const closeModal = useCallback(e => {
        e.preventDefault()
        changeVisibility(false)
    }, [changeVisibility])

    return (
        <div className={style.container}>
            { isTaskDetailsLoading ? (
                <div className={style.loaderContainer}>
                    <svg className={style.loader} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" height="64" width="64">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.461181,37.4533C3.62035,27.6055,39.7764,1,50,1C60.2232,1,96.3797,27.6055,99.5388,37.4533C102.698,47.3008,88.8874,90.3495,80.6166,96.4357C72.3458,102.521,27.6542,102.521,19.3834,96.4357C11.1123,90.3495,-2.69799,47.3008,0.461181,37.4533ZM75.1101,88.7309C75.2689,88.5464,75.6209,88.1182,76.1462,87.3147C77.0021,86.0056,78.0038,84.1899,79.0957,81.9025C81.2737,77.3398,83.5264,71.5001,85.4772,65.4192C87.4281,59.3382,88.9946,53.273,89.8805,48.2856C90.3246,45.7852,90.5678,43.7206,90.6351,42.1529C90.676,41.1983,90.6406,40.6445,90.6194,40.3996C90.4943,40.1885,90.2011,39.7185,89.6126,38.9688C88.6476,37.7395,87.2518,36.2131,85.4409,34.4541C81.8286,30.9454,77.0402,26.9703,71.932,23.2114C66.8239,19.4526,61.6112,16.0684,57.1996,13.6729C54.9881,12.4719,53.1225,11.5986,51.6686,11.0484C50.7707,10.7086,50.2348,10.5716,50,10.5173C49.7652,10.5716,49.2292,10.7086,48.3312,11.0485C46.8773,11.5987,45.0118,12.4719,42.8002,13.6729C38.3886,16.0684,33.1759,19.4526,28.0678,23.2114C22.9597,26.9703,18.1713,30.9454,14.5591,34.4541C12.7482,36.213,11.3524,37.7395,10.3874,38.9688C9.7989,39.7184,9.50574,40.1885,9.38065,40.3996C9.35939,40.6445,9.32397,41.1983,9.36492,42.1529C9.43218,43.7206,9.67536,45.7852,10.1195,48.2856C11.0053,53.273,12.5719,59.3383,14.5227,65.4192C16.4735,71.5001,18.7261,77.3398,20.9041,81.9025C21.996,84.1899,22.9978,86.0055,23.8537,87.3146C24.379,88.1182,24.7311,88.5464,24.8898,88.7308C25.1123,88.8268,25.6248,89.0344,26.5448,89.2931C28.0406,89.7135,30.0569,90.1177,32.5435,90.4627C37.5035,91.151,43.6854,91.5164,50,91.5164C56.3146,91.5164,62.4965,91.151,67.4565,90.4627C69.9431,90.1177,71.9594,89.7135,73.4552,89.2931C74.3751,89.0345,74.8876,88.8269,75.1101,88.7309Z" fill="#B1BDC8" transform="translate(32,31.6) scale(0.6,0.6) translate(-50,-52)" />
                        <g transform="translate(31.93,32.4435) translate(-17.5234,-17.6043)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M49.8833,74.2655C61.6258,74.2655,71.145,64.7024,71.145,52.9056C71.145,41.1089,61.6258,31.5458,49.8833,31.5458C38.1408,31.5458,28.6217,41.1089,28.6217,52.9056C28.6217,64.7024,38.1408,74.2655,49.8833,74.2655ZM49.8833,82.2461C66.0132,82.2461,79.0889,69.1099,79.0889,52.9056C79.0889,36.7014,66.0132,23.5652,49.8833,23.5652C33.7535,23.5652,20.6777,36.7014,20.6777,52.9056C20.6777,69.1099,33.7535,82.2461,49.8833,82.2461Z" fill="#B1BDC8" transform="translate(17.5234,17.6043) scale(0.6,0.6) translate(-49.8833,-52.9057)" />
                            <path d="M61.5654,52.9057C61.5654,59.3874,56.3351,64.6419,49.8832,64.6419C43.4312,64.6419,38.2009,59.3874,38.2009,52.9057C38.2009,46.424,43.4312,41.1695,49.8832,41.1695C56.3351,41.1695,61.5654,46.424,61.5654,52.9057Z" fill="#B1BDC8" transform="translate(17.5233,17.6043) scale(0.6,0.6) translate(-49.8832,-52.9057)" />
                            <path d="M63.902,44.6903C63.902,47.9312,61.2868,50.5584,58.0609,50.5584C54.8349,50.5584,52.2197,47.9312,52.2197,44.6903C52.2197,41.4494,54.8349,38.8222,58.0609,38.8222C61.2868,38.8222,63.902,41.4494,63.902,44.6903Z" fill="white" transform="translate(22.4299,12.6751) scale(0.6,0.6) translate(-58.0609,-44.6903)" />
                        </g>
                    </svg>
                </div>
            ) : (
                <div>
                    <div className={style.header}>
                        <div className={style.icon}>
                            <img src={icon} alt='img' />
                        </div>
                        <div className={style.headerDetails}>
                            <div className={style.nameContainer}>
                                <div className={style.name}>{name}</div>
                                <svg className={style.arrowIcon} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9702 5.99854C12.9702 5.78271 12.8706 5.56689 12.7046 5.40918L8.45459 1.15918C8.27197 0.993164 8.08105 0.910156 7.88184 0.910156C7.43359 0.910156 7.10156 1.23389 7.10156 1.67383C7.10156 1.89795 7.19287 2.08887 7.33398 2.23828L8.5542 3.4751L10.5713 5.30957L9.03564 5.20996H1.46533C0.983887 5.20996 0.660156 5.53369 0.660156 5.99854C0.660156 6.46338 0.983887 6.79541 1.46533 6.79541H9.03564L10.5796 6.6875L8.5542 8.53027L7.33398 9.75879C7.19287 9.9082 7.10156 10.0991 7.10156 10.3232C7.10156 10.7632 7.43359 11.0869 7.88184 11.0869C8.08105 11.0869 8.27197 11.0039 8.44629 10.8379L12.7046 6.58789C12.8706 6.43018 12.9702 6.21436 12.9702 5.99854Z" fill="#495665"/>
                                </svg>
                                <div className={style.id}>{id}</div>
                            </div>
                            <span className={style.address}>{`${street}, ${homeNumber} `}
                                {(floors || porches) && (
                                    <span>
                                        <span>
                                            {porches && <span>{`п. ${porches}`}</span>}
                                            {porches && floors && <span>, </span>}
                                            {floors && <span>{`эт. ${floors}`}</span>}
                                        </span>
                                    </span>
                                )}
                            </span>
                        </div>
                        <a href='/' onClick={closeModal} className={style.closeButton}>
                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.25831 0.25831C0.602723 -0.0861033 1.16113 -0.0861032 1.50554 0.25831L7.74169 6.49446C8.0861 6.83887 8.0861 7.39728 7.74169 7.74169C7.39728 8.0861 6.83887 8.0861 6.49446 7.74169L0.25831 1.50554C-0.0861035 1.16113 -0.0861031 0.602723 0.25831 0.25831Z" fill="#657B90"/>
                                <path d="M7.74169 0.25831C8.0861 0.602723 8.0861 1.16113 7.74169 1.50554L1.50554 7.74169C1.16113 8.0861 0.602723 8.0861 0.25831 7.74169C-0.0861033 7.39728 -0.0861031 6.83887 0.25831 6.49446L6.49446 0.25831C6.83887 -0.0861031 7.39728 -0.0861033 7.74169 0.25831Z" fill="#657B90"/>
                            </svg>
                        </a>
                    </div>
                    <div className={style.headerUnderlineLight} />
                    <div className={style.progressStatus}>
                        {
                            stage === 'created' && (
                                <div className={style.taskStatus}>Не назначено</div>
                            )
                        }
                        {
                            stage === 'in_progress' && (
                                <div className={style.taskStatus}>Выполняется</div>
                            )
                        }
                        {
                            stage === 'done' && resolution === 'canceled' && (
                                <div className={classNames(style.taskStatus, style.taskStatusCanceled)}>Отменена</div>
                            )
                        }
                        {
                            stage === 'done' && resolution === 'resolved' && (
                                <div className={classNames(style.taskStatus, style.taskStatusResolved)}>Выполнено</div>
                            )
                        }
                        <div className={style.taskName}>{taskName}</div>
                    </div>
                    <div className={style.headerUnderline} />
                    {
                        stage !== 'done' && (
                            <div className={style.actions}>
                                <Actions
                                    handleRejectModal={handleRejectModal}
                                    serviceId={serviceId}
                                    buildingId={buildingId}
                                    taskId={id}
                                    performer={performer}
                                    stage={stage}
                                    tasksSortedList={tasksSortedList}
                                    changeVisibility={changeVisibility}
                                />
                            </div>
                        )
                    }
                    <History history={history} />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    data: makeActiveTask(),
    isTaskDetailsLoading: makeDetailsLoadingStatus(),
    tasksSortedList: makeTasksSortedList(),
})

const mapDispatchToProps = {
    getTaskDetails,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
