import _ from 'lodash'

const meterIcons = {
    water_hot: 'W',
    water_cold: 'W',
    elec_night: 'E',
    elec_day: 'E',
    gas: 'G',
    heating: 'G',
}

export const getMeterIcon = type => _.get(meterIcons, type)
