import React from 'react'
import MonthPickerInput from 'react-month-picker-input'

import './month-picker.css'
import style from './style.css'
import { monthList } from './utils'

function Component(props) {
    const { handleChange, year, month } = props

    return (
        <div className={style.container}>
            <div className={style.clicker}>
                <div>{`за ${monthList[month - 1]} ${year}`}</div>
                <div className={style.arrowIcon}>a</div>
            </div>
            <MonthPickerInput
                year={+year}
                month={month - 1}
                lang='ru'
                closeOnSelect={true}
                onChange={function(maskedValue, selectedYear, selectedMonth) {
                    handleChange({month: ('0' + (selectedMonth + 1)).slice(-2), year: selectedYear})
                }}
            />
        </div>
    )
}

export default Component
