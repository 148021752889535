import axios from 'axios'
import { reset } from 'redux-form'

import * as types from '../../../__data__/actions-types'
import { MODERATOR_STORAGE_NAME, FORM_NAMES } from '../../../__data__/constants'
import { getToken } from '../../../__data__/utils'

import {
    GET_COMPANY_INFORMATION,
    GET_COMPANY_INFORMATION_FAIL,
    GET_COMPANY_INFORMATION_SUCCESS, GET_SETTINGS_BUILDINGS_FAIL_PAGINATION,
    GET_SETTINGS_BUILDINGS_PAGINATION, GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION,
    GET_SETTINGS_EMPLOYEE,
    GET_SETTINGS_EMPLOYEE_FAIL,
    GET_SETTINGS_EMPLOYEE_SUCCESS,
    GET_SETTINGS_STAFF_FAIL_PAGINATION,
    GET_SETTINGS_STAFF_PAGINATION,
    GET_SETTINGS_STAFF_SUCCESS_PAGINATION,
    GET_SETTINGS_OBJECT,
    GET_SETTINGS_OBJECT_SUCCESS,
    GET_SETTINGS_OBJECT_FAIL,
} from './actions-types'

export const fetchGetSettingsStaffPagination = () => ({ type: GET_SETTINGS_STAFF_PAGINATION })
export const fetchGetSettingsStaffSuccessPagination = (data) => ({ type: GET_SETTINGS_STAFF_SUCCESS_PAGINATION, data })
export const fetchGetSettingsStaffFailPagination = (error) => ({ type: GET_SETTINGS_STAFF_FAIL_PAGINATION, error })

export const fetchGetSettingsBuildingsPagination = () => ({ type: GET_SETTINGS_BUILDINGS_PAGINATION })
export const fetchGetSettingsBuildingsSuccessPagination = (data) => ({ type: GET_SETTINGS_BUILDINGS_SUCCESS_PAGINATION, data })
export const fetchGetSettingsBuildingsFailPagination = (error) => ({ type: GET_SETTINGS_BUILDINGS_FAIL_PAGINATION, error })

export const fetchGetSettingsObject = () => ({ type: GET_SETTINGS_OBJECT })
export const fetchGetSettingsObjectSuccess = (data) => ({ type: GET_SETTINGS_OBJECT_SUCCESS, data })
export const fetchGetSettingsObjectFail = (error) => ({ type: GET_SETTINGS_OBJECT_FAIL, error })

export const fetchGetCompanyInformation = () => ({ type: GET_COMPANY_INFORMATION })
export const fetchGetCompanyInformationSuccess = (data) => ({ type: GET_COMPANY_INFORMATION_SUCCESS, data })
export const fetchGetCompanyInformationFail = (error) => ({ type: GET_COMPANY_INFORMATION_FAIL, error })

export const fetchGetSettingsEmployee = () => ({ type: GET_SETTINGS_EMPLOYEE })
export const fetchGetSettingsEmployeeSuccess = (data) => ({ type: GET_SETTINGS_EMPLOYEE_SUCCESS, data })
export const fetchGetSettingsEmployeeFail = (error) => ({ type: GET_SETTINGS_EMPLOYEE_FAIL, error })

export const fetchCreateEmployee = () => ({type: types.CREATE_EMPLOYEE})
export const fetchCreateEmployeeSuccess = (data) => ({type: types.CREATE_EMPLOYEE_SUCCESS, data})
export const fetchCreateEmployeeFail = (error) => ({type: types.CREATE_EMPLOYEE_FAIL, error})

export const fetchUpdateEmployee = () => ({type: types.UPDATE_EMPLOYEE})
export const fetchUpdateEmployeeSuccess = (data) => ({type: types.UPDATE_EMPLOYEE_SUCCESS, data})
export const fetchUpdateEmployeeFail = (error) => ({type: types.UPDATE_EMPLOYEE_FAIL, error})

export const fetchDeleteEmployee = () => ({type: types.DELETE_EMPLOYEE})
export const fetchDeleteEmployeeSuccess = (data) => ({type: types.DELETE_EMPLOYEE_SUCCESS, data})
export const fetchDeleteEmployeeFail = (error) => ({type: types.DELETE_EMPLOYEE_FAIL, error})

export const fetchBlockEmployee = () => ({type: types.BLOCK_EMPLOYEE})
export const fetchBlockEmployeeSuccess = (data) => ({type: types.BLOCK_EMPLOYEE_SUCCESS, data})
export const fetchBlockEmployeeFail = (error) => ({type: types.BLOCK_EMPLOYEE_FAIL, error})

export const fetchGetServices = () => ({ type: types.GET_SERVICES })
export const fetchGetServicesSuccess = (data) => ({ type: types.GET_SERVICES_SUCCESS, data })
export const fetchGetServicesFail = (error) => ({ type: types.GET_SERVICES_FAIL, error })

// SETTINGS
export const getSettingsStaffPagination = (page=1, countPerPage=10) => dispatch => {
    dispatch(fetchGetSettingsStaffPagination())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/employee/?page=${page}&page_size=${countPerPage}`,
    })
        .then(response => {
            dispatch(fetchGetSettingsStaffSuccessPagination(response.data))
        })
        .catch(error => {
            dispatch(fetchGetSettingsStaffFailPagination(error))
        })
}

export const getSettingsBuildingsPagination = (page=1, countPerPage=10) => dispatch => {
    dispatch(fetchGetSettingsBuildingsPagination())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/settings/building/?page=${page}&page_size=${countPerPage}`,
    })
        .then(response => {
            dispatch(fetchGetSettingsBuildingsSuccessPagination(response.data))
        })
        .catch(error => {
            dispatch(fetchGetSettingsBuildingsFailPagination(error))
        })
}

export const getSettingsObject = (id) => dispatch => {
    dispatch(fetchGetSettingsObject())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/settings/building/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetSettingsObjectSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetSettingsObjectFail(error))
        })
}

export const getCompanyInformation = () => dispatch => {
    dispatch(fetchGetCompanyInformation())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: "/api/crm/settings/company-profile/",
    })
        .then(response => {
            dispatch(fetchGetCompanyInformationSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetCompanyInformationFail(error))
        })
}

export const getEmployee = id => dispatch => {
    dispatch(fetchGetSettingsEmployee())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: ` /api/crm/employee/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetSettingsEmployeeSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetSettingsEmployeeFail(error))
        })
}

export const createEmployee = data => dispatch => {
    dispatch(fetchCreateEmployee())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/employee/',
        data,
    })
        .then(response => {
            dispatch(fetchCreateEmployeeSuccess(response.data))
            dispatch(reset(FORM_NAMES.settingsAddEmployee))
        })
        .catch(error => {
            dispatch(fetchCreateEmployeeFail(error))
        })
}

export const editEmployee = (data, id) => dispatch => {
    dispatch(fetchUpdateEmployee())

    return axios({
        method: 'PUT',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/employee/${id}/`,
        data,
    })
        .then(response => {
            dispatch(fetchUpdateEmployeeSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchUpdateEmployeeFail(error))
        })
}

export const deleteEmployee = (userID) => dispatch => {
    dispatch(fetchDeleteEmployee())

    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/employee/${userID}/`
    })
        .then(response => {
            dispatch(fetchDeleteEmployeeSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchDeleteEmployeeFail(error))
        })
}

export const blockEmployee = (userID, block) => dispatch => {
    dispatch(fetchBlockEmployee())

    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/employee/${userID}/suspend/`,
        data:{flag:block}
    })
        .then(response => {
            dispatch(fetchBlockEmployeeSuccess(response.data.params))
        })
        .catch(error => {
            dispatch(fetchBlockEmployeeFail(error))
        })
}

export const getServices = () => dispatch => {
    dispatch(fetchGetServices())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/services/`,
    })
        .then(response => {
            dispatch(fetchGetServicesSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetServicesFail(error))
        })
}
