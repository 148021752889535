import _ from 'lodash'

import { sendMeters }  from '../../__data__/actions/readings'

const getData = (values) => {
    const data = {
        readings: _.get(values, 'data'),
        unit: _.get(values, 'flat_number.id')
    }

    return data
}

function submit(values, dispatch) {
    const data = getData(values)
    dispatch(sendMeters(data))
}

export default submit
