import React, { useCallback } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import { getTasksCount, patchTask, saveTasksToStoreList } from '../../../../../../__data__/actions/tasks'
import { makeTasksFilterDate, makeTasksFilterType, makeTasksSortedList } from '../../../../../../__data__/selectors/tasks'
import { makeCheckedBuildings } from '../../../../../../__data__/selectors/common'

import style from './style.css'
import classNames from "classnames";
import {checkWritePermissionForSection} from "../../../../../../__data__/actions/permissions";

function Component(props) {
    const {
        taskId,
        tasksSortedList,
        checkedBuildings,
        patchTask,
        saveTasksToStoreList,
        getTasksCount,
        tasksFilterDate,
        tasksFilterType,
        changeDetailsVisibility,
    } = props

    const handleClick = useCallback(e => {
        changeDetailsVisibility(false)

        // Сохраняем в базу
        const firstDoneTaskOrder = _.get(tasksSortedList, 'done[0].order')
        const order = firstDoneTaskOrder ? firstDoneTaskOrder - 0.001 : 1
        patchTask(taskId, {stage: 'done', resolution: 'resolved', order})
        getTasksCount(checkedBuildings, 'in_progress', tasksFilterDate, tasksFilterType.value)
        getTasksCount(checkedBuildings, 'done', tasksFilterDate, tasksFilterType.value)

        // Сохраняем в стор
        const currentTask = _.find(_.get(tasksSortedList, 'in_progress'), i => i.id === +taskId)
        currentTask.order = order
        currentTask.stage = 'done'

        const createdTasks = _.get(tasksSortedList, 'created')
        const inProgressTasks = _.filter(_.get(tasksSortedList, 'in_progress'), i => i.id !== +taskId)
        const doneTasks = [currentTask, ..._.get(tasksSortedList, 'done')]

        saveTasksToStoreList({created: createdTasks, in_progress: inProgressTasks, done: doneTasks})
    }, [checkedBuildings, getTasksCount, patchTask, saveTasksToStoreList, taskId, tasksSortedList])

    return (
        <div className={style.wrapper}>
            <button className={classNames(style.container, !checkWritePermissionForSection('Заявки') && style.disabled)} onClick={handleClick}>Завершить</button>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    tasksSortedList: makeTasksSortedList(),
    checkedBuildings: makeCheckedBuildings(),
    tasksFilterDate: makeTasksFilterDate(),
    tasksFilterType: makeTasksFilterType(),
})

const mapDispatchToProps = {
    patchTask,
    saveTasksToStoreList,
    getTasksCount,
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
