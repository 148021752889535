import { SubmissionError } from 'redux-form'

import { sendAccountConfirmationCode }  from '../../../../__data__/actions/login'
import { RecoverValidationMessages } from '../../../../components/form/validation'

function onSubmitForm(values, dispatch) {
    const username = values.username.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')

    return dispatch(sendAccountConfirmationCode({username}))
        .catch((error) => {
        throw new SubmissionError(RecoverValidationMessages[error?.message] || ' ')
    })
}

export default onSubmitForm
