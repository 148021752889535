import React from 'react'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, Field } from 'redux-form'

import { getToken } from '../../__data__/utils'
import { MODERATOR_STORAGE_NAME } from '../../__data__/constants'
import { default as FileViewer } from '../form/file-viewer'

import './uploader-style.css'
import style from './uploader-style.css'

const uploadAttachment = (file) => {
    const formData = new FormData();
    formData.append('attachments', file)
    return axios({
        method: 'POST',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'multipart/form-data'
        },
        url: `/api/authorized/attachments/`,
        data: formData
    })
}

export const deleteAttachment = (id) => {
    return axios({
        method: 'DELETE',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'multipart/form-data'
        },
        url: `/api/authorized/attachments/${id}/`,
    })
}

const Component = (props) => {
    const { formName, input } = props

    const setUploadStatus = async ( data ) => {
        const response = await uploadAttachment(data.file)
        // TODO: use the dispatch instead this (error == infinity loader)
        if (response?.status === 201) {
            let uploaded_file = response.data[0]
            props.changeField(formName, `${input.name}.${data.meta.id}`, {
                id: uploaded_file.id,
                file: uploaded_file.preview_url,
                realName: data.file.name,
                status: "done"
            })
        }
    }

    const handleChangeStatus = (data, status) => {
        if (status === 'preparing') {
            setUploadStatus(data)
            props.changeField(formName, `${input.name}.${data.meta.id}.status`, 'preparing')
        } else if (status === 'done') {
            props.changeField(formName, `${input.name}.${data.meta.id}.status`, 'done')
        } else if (status === 'removed') {
            props.changeField(formName, `${input.name}.${data.meta.id}.status`, 'removed')
        } else if (status === 'error_upload') {
            props.changeField(formName, `${input.name}.${data.meta.id}.status`, 'error')
        }
    }

    return (
        <div className={style['dzu-dropzone-wrapper']}>
            <Field
                name={input.name}
                component={FileViewer}
                value=''
                formName={formName}
            />
            <Dropzone
                onChangeStatus={handleChangeStatus}
                accept='image/*,.doc,.docx,.rtf,.txt,.pdf,.ppt,.pptx,.xls,.xlsx,.csv,.zip,.rar,.7z'
                inputContent='Нажмите и выберите или перетащите файлы в эту область'
                inputWithFilesContent='Прикрепить ещё файлы'
                PreviewComponent={null}
                minSizeBytes={0}
                maxSizeBytes={6292000}
            />
        </div>
    )
}

const mapDispatchToProps = {
    changeField: change,
}

const withConnect = connect(
    null,
    mapDispatchToProps,
)

export default compose(withConnect)(Component)
