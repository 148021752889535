import axios from "axios"

import * as types from '../actions-types'
import { getToken } from '../utils'
import { MODERATOR_STORAGE_NAME } from '../constants'

export const fetchSetGlobalBuilding = (buildingsId) => ({ buildingsId, type: types.SET_GLOBAL_BUILDING })

export const fetchSetHomeSelect = (isHomeSelectActive) => {
    return { isHomeSelectActive, type: types.SET_HOME_SELECT }}

export const fetchGetBuildingList = () => ({ type: types.GET_BUILDING_LIST })
export const fetchGetBuildingListSuccess = (data) => ({ type: types.GET_BUILDING_LIST_SUCCESS, data })
export const fetchGetBuildingListFail = (error) => ({ type: types.GET_BUILDING_LIST_FAIL, error })

export const fetchGetBuildingData = () => ({ type: types.GET_BUILDING_DATA })
export const fetchGetBuildingDataSuccess = (data) => ({ type: types.GET_BUILDING_DATA_SUCCESS, data })
export const fetchGetBuildingDataFail = (error) => ({ type: types.GET_BUILDING_DATA_FAIL, error })

export const fetchGetPorchData = () => ({ type: types.GET_PORCH_DATA })
export const fetchGetPorchDataSuccess = (data) => ({ type: types.GET_PORCH_DATA_SUCCESS, data })
export const fetchGetPorchDataFail = (error) => ({ type: types.GET_PORCH_DATA_FAIL, error })

export const setGlobalBuilding = buildingsId => dispatch => {
    dispatch(fetchSetGlobalBuilding(buildingsId))
    return buildingsId
}

export const getBuildingsList = token => dispatch => {
    dispatch(fetchGetBuildingList())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        },
        url: '/api/crm/buildings/',
    })
        .then(response => {
            dispatch(fetchGetBuildingListSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingListFail(error))
        })
}

export const getBuildingData = id => dispatch => {
    dispatch(fetchGetBuildingData())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/buildings/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetBuildingDataSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetBuildingDataFail(error))
        })
}

export const getPorchData = id => dispatch => {
    dispatch(fetchGetPorchData())

    return axios({
        method: 'GET',
        headers: {
            'authorization': `Token ${getToken(MODERATOR_STORAGE_NAME)}`,
            'Content-Type': 'application/json',
        },
        url: `/api/crm/porches/${id}/`,
    })
        .then(response => {
            dispatch(fetchGetPorchDataSuccess(response.data))
        })
        .catch(error => {
            dispatch(fetchGetPorchDataFail(error))
        })
}
