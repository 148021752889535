import React from "react";
import styles from "./style.css";
import {Avatar} from "../../avatar";
import {formatDate} from "../../../../../__data__/utils";
import classnames from "classnames";

export const Complaint = ({lastName, firstName, description, creationDate, photo}) => (
    <li
        className={classnames(styles.container, styles.containerRight)}
    >
        <Avatar photo={photo} size="m" />
        <div className={styles.content}>
            <b className={styles.title}>{firstName + " " + lastName}</b>
            {description}
            <span className={styles.time}>{formatDate(creationDate)}</span>
        </div>
    </li>
)