import { createSelector } from 'reselect'
import _ from 'lodash'

import { formatPhoneNumber } from '../../../__data__/utils'

const app = state => _.get(state, 'app', {})

export const makeCompanyInformation = () =>
    createSelector(app, slice => {
        const companyInformaton = _.get(slice, 'companyInformaton', {})

        return {
                name_full: companyInformaton.name_full,
                name_short: companyInformaton.name_short,
                avatar: companyInformaton.avatar,
                phones: companyInformaton.phones,
                license_on: companyInformaton.license_on,
               }
    })
